const initialState = {
  msgs: []
}

export default function chat(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_MSG':
      return {
        ...state,
        msgs: state.msgs.concat([action.msg])
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}