import React from 'react';
import icon_cloud_review from '../../../images/meet/cloud-review.png'
import './Loading.scss'

class Loading extends React.Component {
  render() {
    return (
      <div className='loading'>
        <div>
          <img alt='logo' src={icon_cloud_review}></img>
        </div>
        <div className='loading-progress'>
          <div className='loading-progress__inner' style={{width: this.props.progress}}></div>
          
        </div>
        <div className='loading-status'>
            正在接入会议...
          </div>
      </div>
    )
  }
}

export default Loading