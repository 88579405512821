import React from "react";
import './ListComponent.scss';
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import { Modal, Button, message } from 'antd';
import listener from "../../../lib/listener";
import formatDate from "../../../lib/formatDate";
import { connect } from "react-redux";
// import {updateStatus} from "../../../actions/user";
import more_icon from '../../../images/meeting/meeting_list/more(2).svg'
import dayjs from 'dayjs'
import AddFile from '../AddFile/AddFile'

class ListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            visible: false,
            deleteVisible:false,
            showEnterRoom: false,
            showFile:false,
        }
    }

    componentDidMount() {
        // console.log(this.props.user.userInfo.id, '1111')
        listener.register('meeting', 'hideedit', (id) => {
            if (id === this.props.item.id) {
                return
            }
            this.setState({
                showEdit: false
            })
        })
        listener.register('meeting', 'hideenter', (id) => {
            if (id === this.props.item.id) {
                return
            }
            this.setState({
                showEnterRoom: false
            })
        })
    }

    handleEnter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        // console.log(this.props.item.rooms.length)
        if (this.props.item.rooms.length > 1) {
            this.setState({
                showEnterRoom: !this.state.showEnterRoom
            })
        } else {
            let code = this.props.item.rooms[0].code
            this.props.history.push(`/meet/${code}`)
        }
        listener.fire('meeting', 'hideenter', this.props.item.id)
    }
    more = (e) => {
        e.stopPropagation()
        this.setState({
            showEdit: !this.state.showEdit
        })
        listener.fire('meeting', 'hideedit', this.props.item.id)
    }
    showModal = (item) => {
        if (item.status === "进行中") {
            message.warn("会议进行中")
            return
        }
        if (this.props.user.userInfo.id === this.props.item.allow_user_info.responsibilityPersonId) {
            this.setState({
                visible: true,
            });
        } else {
            return message.warn('只有主持人才能取消会议')
        }
    };
    showDelModal = (item) =>{
        if (this.props.user.userInfo.id === this.props.item.allow_user_info.responsibilityPersonId) {
            this.setState({
                deleteVisible: true,
            });
        } else {
            return message.warn('只有主持人才能删除会议')
        }
    } 
    handleOk = (e) => {
        this.$api.post(`/api/meeting/close/${this.props.item.id}`).then(res => {
            this.$api.post("/api/meeting/list", {
                page: 1,
                limit: 20,
                filter: {
                    status__in: ['PENDING', 'MEETING'],
                    end_time__gt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                }
            }).then(response => {
                let data = response.data
                this.props.cancel(data)
            })
        })
        this.setState({
            visible: false,
            showEdit: false
        });
    }
    handleDelete = (e) => {
        this.$api.post(`/api/meeting/delete/${this.props.item.id}`).then(res => {
            if (res.ok !== 1) {
                return message.warn(res.msg)
            }
            let dataObj = {
                page: 1,
                limit: 20,
                filter: {
                    OR: [
                        {
                            status__in: ['FINISHED', 'CANCELED'],
                        },
                        {
                            end_time__lt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        },
                    ]
                }
            }
            this.$api.post("/api/meeting/list", dataObj).then(response => {
                let data = response.data
                this.props.cancel(data)
            })
        })
        this.setState({
            deleteVisible: false,
            showEdit: false
        });
    };
    handleCancel = (e) => {
        this.setState({
            visible: false,
            deleteVisible:false
        });
    };
    handleClick = (item) => {
        // console.log(1, item)
        if (this.props.user.userInfo.id === this.props.item.allow_user_info.responsibilityPersonId) {
            this.props.edit(item)
        } else {
            return message.warn('只有主持人才能修改会议')
        }
        // console.log(this.props.item.allow_user_info.designDirectorId)
    }
    handleBrowseFile =(code)=>{
        this.$api.post('/api/meeting/room/operation/' + code, {'active': 'read_file'})
        .then(res => {
            if (res.ok == 1) {
                this.setState({showFile:true})
            }else if (res.code === 410) {
                message.error(res.msg)
            }
        })
    }
    render() {
        function week(time) {
            if (time) {
                let date = time.slice(0, 10) + " " + time.slice(11, 19)
                let data = time.slice(0, 10).split("-").join("") + time.slice(11, 19).split(":").join("")

                function time2(data) {
                    let date = data.toString();
                    let year = date.substring(0, 4);
                    let month = date.substring(4, 6);
                    let day = date.substring(6, 8);
                    let d1 = new Date(year + '/' + month + '/' + day);

                    let dd = new Date();
                    let y = dd.getFullYear();
                    let m = dd.getMonth() + 1;
                    let d = dd.getDate();

                    let d2 = new Date(y + '/' + m + '/' + d);
                    let iday = parseInt(d2 - d1) / 1000 / 60 / 60 / 24;

                    return iday;
                }

                if (time2(data) === 0) {
                    return "今天"
                } else if (time2(data) === -1) {
                    return "明天"
                } else {
                    return '星期' + '日一二三四五六'.charAt(new Date(date).getDay());
                }
            }
        }

        function handleDate(date) {
            if (date) {
                let dateArr = date.slice(0, 10).split("-")
                return dateArr[1] + "月" + dateArr[2] + "日"
            }
        }

        function handleTime(time) {
            if (time) {
                return time.slice(11, 16)
            }
        }

        function handleHour(time) {
            if (time) {
                let hour = time.slice(11, 13)
                if (hour < 12) {
                    return "上午"
                } else {
                    return "下午"
                }
            }
        }

        function statusStyle(status) {
            if (status === "进行中") {
                return true
            } else {
                return false
            }
        }

        function todayStyle(date) {
            if (date === "今天" || date === "明天") {
                return true
            } else {
                return false
            }
        }

        return (
            <tr>
                {/*formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time)*/}
                <td>{this.props.index + 1}</td>
                {/*<td>*/}
                {/*    <p><span>{handleDate(this.props.item.start_time)}&nbsp;&nbsp;</span><span*/}
                {/*        className={classNames({"week": todayStyle(week(this.props.item.start_time))})}>{week(this.props.item.start_time)}</span>*/}
                {/*    </p>*/}
                {/*    <p>{handleHour(this.props.item.start_time)} {handleTime(this.props.item.start_time)}-{handleTime(this.props.item.end_time)}</p>*/}
                {/*</td>*/}
                <td>
                    <p><span>{formatDate('MM月dd日', this.props.item.start_time)}&nbsp;&nbsp;</span><span
                        className={classNames({ "week": todayStyle(week(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))) })}>{week(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}</span>
                    </p>
                    <p>{handleHour(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))} {formatDate('hh:mm', this.props.item.start_time)}-{formatDate('hh:mm', this.props.item.end_time)}</p>
                </td>
                <td style={{ width: 340 }}>{this.props.item.name}</td>
                <td>{this.props.item.rooms[0].code}</td>
                {[0, 3].indexOf(this.props.showDolist) < 0 ?
                    <td className={classNames({ "doing": statusStyle(this.props.item.status) })}>{this.props.item.status}</td> : ''}
                {[3].indexOf(this.props.showDolist) < 0 ?
                    <td>
                        {/*<Link id="enter-meeting" to={"/meet/" + this.props.item.id}>进入</Link>*/}
                        <a onClick={this.handleEnter} id="enter-meeting">进入</a>
                        {this.state.showEnterRoom &&
                            <div id="enter-room">
                                <ul>
                                    {this.props.item.rooms.map(item => [
                                        <li key={item.code}><a id="enter-meeting"
                                            href={"/web/meeting#/meet/" + item.code} target="_blank">{item.name}</a>
                                        </li>
                                    ])}
                                </ul>
                            </div>
                        }
                        <img alt="" id="more" onClick={this.more.bind(this)}
                            src={more_icon} />
                        <div id="edit" className={classNames({
                            "show": this.state.showEdit,
                            "do-edit": this.props.showDolist,
                            "did-del": !this.props.showDolist
                        })}>
                            {this.props.showDolist ?
                                <ul>
                                    <li onClick={this.handleClick.bind(this, this.props.item)}>
                                        <a>编辑会议</a>
                                    </li>
                                    <li onClick={this.showModal.bind(this, this.props.item)}>
                                        <a>取消会议</a>
                                    </li>
                                </ul> :
                                <ul>
                                    <li onClick={this.showDelModal.bind(this,this.props.item)}>
                                        <a>删除会议</a>
                                    </li>
                                </ul>}
                            <div id="triangle"><span></span></div>
                        </div>
                        {this.props.showDolist ?
                            <Modal
                                title="确定取消该会议吗？"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                okText="确定"
                                cancelText="取消"
                            >
                                <p><span>会议主题：</span>{this.props.item.name}</p>
                                <p>
                                    <span>会议时间：</span>{week(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}&nbsp;
                                    {handleHour(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}&nbsp;
                                    {formatDate('hh:mm', this.props.item.start_time)}-{formatDate('hh:mm', this.props.item.end_time)}
                                </p>
                            </Modal> :
                            <Modal
                                title="确定删除该会议吗？"
                                visible={this.state.deleteVisible}
                                onOk={this.handleDelete}
                                onCancel={this.handleCancel}
                                okText="确定"
                                cancelText="取消"
                            >
                                <p><span>会议主题：</span>{this.props.item.name}</p>
                                <p>
                                    <span>会议时间：</span>{week(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}&nbsp;
                                    {handleHour(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}&nbsp;
                                    {formatDate('hh:mm', this.props.item.start_time)}-{formatDate('hh:mm', this.props.item.end_time)}
                                </p>
                            </Modal>}
                    </td> : 
                    <td className="personal-file-box" >
                        {/*<Link id="enter-meeting" to={"/meet/" + this.props.item.id}>进入</Link>*/}
                        {/* <a onClick={(e)=>(e.preventDefault(),this.setState({showFile:true}))}>查看文档</a> */}
                        <a onClick={(e)=>(e.preventDefault(),this.handleBrowseFile(this.props.item.rooms[0].code))}>查看文档</a>
                        {this.state.showFile && 
                        <AddFile
                        // allow_member_upload={[]}
                        personalStatus = {true}
                        id={this.props.item.rooms[0].code}
                        meet={{isManager:false}}
                        back={()=>this.setState({showFile:false})}
                        upload={[]}
                        />}
                    </td>
                }
            </tr>
        )
    }
}

// export default withRouter(ListComponent)
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(withRouter(ListComponent))