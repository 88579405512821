import clone from  '../lib/clone'
import user from './user'
import { sm2Decrypt } from '@bj-nsc/functions'
const initialState = {
  roomInited: false,
  micId: null,
  camId: null,
  audioId: null,
  devices: [],
  users: [],
  videoMuted: true,
  audioMuted: true,
  showTimer: true,
  showMask: true,
  meetInfo: {},
  allMuted: false, // 全员静音
  joinTime: null, //进入时间
  isManager: false,
  managerId: [],
  connectionQuality: 100,
  jvbRTT: 0,
  bandwidth:{download:NaN, upload:NaN},
  packetLoss:{download:0,upload:0,total:0},
  whiteList: [],
  coChair: null,
  kick_out:[],
  watermark: true,
  unread: false,
  lock: false,
  progress: 0,
  showFaceMatch: false,
  allowAllToShareScreen: true,
  handupList: [] // 举手等待发言
}

const rank_value = {
  "系统":9999,
  "变电一次": 9998,
  "变电二次": 9997,
  "变电土建":9996,
  "线路结构":9995,
  "线路电气":9994,
  "通信":9993,
  "变电技经":9992,
  "线路技经":9991,
}

export async function decodeUserItem(item){
  if(item.userName) {
    item.userName = await sm2Decrypt(item.userName);
  }
  return item;
}

export /* async */ function pushItemToUsers (users, item, role) {
  item.user_id = item.id
  // item.userName =item.userName && await sm2Decrypt(item.userName)
    if (users.map(user => user.id).join(',').indexOf(item.id) > -1) {
      item.id = item.id + '-' + users.length
    }
    users.push(Object.assign({
      role,
      isIn: false,
      vTrack: null,
      aTrack: null,
      isAMuted: true,
      isVMuted: true,
      meet_id: null,
      audioLevel: 0,
      enter_time: null,
      leave_time: null,
      rank_value: rank_value[item.majorName],
      is_kick:false
    }, item))
}

function cloneInitialState(){
  return JSON.parse(JSON.stringify(initialState));
}

export default function meet(state = cloneInitialState(), action) {
  switch (action.type) {
    case 'UPDATE_STATUS':
      return {
        ...state,
        ...action.payload
      }
    // case 'REMOVE_USER':
    //   // debugger;
    //   state.users = state.users.filter(item =>item.id !== action.payload.id)
    //   return {
    //     ...state
    //   }
    case 'UPDATE_USER':
      // if (!action.payload.userName) {
      //   return {
      //     ...state
      //   }
      // }
      // TO-DO
      // let users = clone(state.users)
      // debugger;
      let users = state.users
      let inUsers = false
      users.forEach(item => {
        if (item.id === action.payload.id) {
          inUsers = true
          item = Object.assign(item, action.payload)
        }
      })
      if (!inUsers && action.payload.id) {
        pushItemToUsers(users, action.payload, 'others')
      }

      return {
        ...state,
        users
      }
    case 'UPDATE_SETTING':
      let meetInfo = clone(state.meetInfo)
      if (action.payload.type && action.payload.type === 'room') {
          meetInfo.room = Object.assign(meetInfo.room, action.payload)
      } else if(action.payload.type && action.payload.type === 'rooms') {
          meetInfo.rooms = [...meetInfo.rooms, action.payload.room]
      } else {
          meetInfo.setting = Object.assign(meetInfo.setting, action.payload)
      }
      return {
        ...state,
        meetInfo
      }
    case 'RESET':
      // debugger;
      return cloneInitialState();
    default:
      return state
  }
}