import store from '../store'
//true代表静音，false代表开启
export default function (user_id) {
  const { meet, user } = store.getState()
  // 判断被静音的id 是否为管理员id，
  //不是管理员返回false
  if (user_id === meet.managerId[0]){
    return false
  }
  // 如果被静音，白名单没有操作对象 返回true
  // 如果没被静音，白名单里有操作对象 返回true
  if ( (meet.allMuted && meet.whiteList.join(',').indexOf(user_id) === -1) || ( !meet.allMuted && meet.whiteList.join(',').indexOf(user_id) > -1)
  ) {
    return true
  } 

  return false
}