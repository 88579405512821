import React from "react";
import './Create.scss';
import classNames from 'classnames'
import { Form, DatePicker, InputNumber, TimePicker, message, Input, Checkbox, Modal} from 'antd';
import { Link, withRouter } from "react-router-dom";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import MemListComponent from "../MemListComponent/MemListComponent";
import { connect } from "react-redux";
import listener from "../../../lib/listener";
import moment from 'moment';
import { updateStatus } from "../../../actions/user";
import formatDate from "../../../lib/formatDate";
import AddFile from "../AddFile/AddFile";
import question_mark_icon from '../../../images/meeting/create/question-mark.png'
import exit_icon from '../../../images/meeting/create/exit.svg'
import { sm2Decrypt,sm2Encrypt } from '@bj-nsc/functions'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker
const { Search } = Input;

class Create extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showExplain: false,
            showMember: false,
            showPassword: false,
            useFaceMatch: false,
            checked: true,
            records: [],
            userLists: "",
            designMemberVo: [],
            specialistMemberVo: [],
            defaultProjectId: "",
            defaultProjectName: "",
            uploadFileChecked: true,
            forceWatermarkChecked: true,
            searchMember: "",
            designAllChecked: false,
            specialAllChecked: false,
            ranges: [
                {
                    label: 'Now',
                    value: new Date()
                }
            ],
            date: "",
            start_time: "",
            end_time: "",
            csrfmiddlewaretoken: "",
            name: "",
            project_id: "",
            notice_all_member: 0,
            room_num: 1,
            password: "",
            allow_member_upload: 1,
            allow_enter_before_compere: true,
            force_watermark: 1,
            search: "",
            showFile: false,
            file_ids: [],
            file_lists:null,
        }
    }

    onHideExplain = () => {
        this.setState({
            showExplain: false
        })
    }

    componentWillUnmount(){
        listener.remove('create', 'hideexplain', this.onHideExplain)
    }

    componentDidMount() {
        listener.register('create', 'hideexplain', this.onHideExplain)
        this.$api.get("/api/project/list", {
            params: { current: 1, size: 1000 }
        }).then(response => {
            if (response.code === 200) {
                let records = response.data.records
                if (JSON.stringify(records) === "[]") {
                    return
                }
                let defaultProjectId = records[0].id
                let defaultProjectName = records[0].projectName
                this.setState({
                    records,
                    defaultProjectId,
                    defaultProjectName,
                    project_id: defaultProjectId
                })
                this.$api.get(`/api/project/user_list/${defaultProjectId}`).then(response => {
                    let userLists = response.data
                    let { designMemberVo, specialistMemberVo } = userLists
                    // result.name=await sm2Decrypt(result.name)
                    designMemberVo.map(async item => {
                        item.checked = false
                        item.userName = await sm2Decrypt(item.userName)
                    })
                    specialistMemberVo.map(async item => {
                        item.checked = false
                        item.userName = await sm2Decrypt(item.userName)
                    })
                    this.setState({
                        userLists,
                        designMemberVo,
                        specialistMemberVo
                    })
                })
                this.$api.get("/api/meeting/create").then(res => {
                    this.setState({
                        csrfmiddlewaretoken: res.data.token
                    })
                })
            } else if (data.code === 403) {
                message.warn("登录超时")
                localStorage.removeItem("userInfo")
                this.props.dispatch(updateStatus({
                    isAuthenticated: false,
                    userInfo: ""
                }))
                setTimeout(() => {
                    // this.props.history.push("/auth/login")
                    this.$api.goLoginPage();
                }, 1000)
            }
        })
    }
    handlePassword(e) {
        if (e.target.checked) {
            this.setState({
                showPassword: true
            })
        } else {
            this.setState({
                showPassword: false
            })
        }
    }
    handleFaceMatch(e) {
        this.setState({
            useFaceMatch: e.target.checked
        })
    }
    handleMember = (e) => {
        e.preventDefault()
        // console.log(this.state.userLists,"1111111")
        this.setState({
            showMember: true
        })
    }

    define = () => {
        // console.log(this.state.specialAllChecked,this.state.designAllChecked)
        if(this.state.specialAllChecked){
            if(this.state.designAllChecked){
                this.setState({
                    notice_all_member: 1
                })
            }
        }else{
            this.setState({
                notice_all_member: 0
            })
        }
        this.setState({
            showMember: false
        })
    }

    cancel = () => {
         
        const { designMemberVo,specialistMemberVo} = this.state;
        designMemberVo.map((item, index) => {
            item.checked = false;
        });
        specialistMemberVo.map((item, index) => {
            item.checked = false;
        });
        this.setState({ 
            'specialistMemberVo': specialistMemberVo, 
            'designMemberVo': designMemberVo,
            specialAllChecked: false,
            designAllChecked: false,
        });
        this.setState({
            showMember: false
        })
    }
    handleCancel = () => {
        this.setState({
            showMember: false
        })
    }

    onDate(value, dateString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            date: dateString
        })
    }

    onStart(value, timeString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            start_time: timeString
        })
    }

    onEnd(value, timeString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            end_time: timeString
        })
    }

    onOk(value) {
        // console.log('onOk: ', value);
    }

    handleProject(e) {
        let projectName = e.target.value
        let newRecord = this.state.records.find(item => item.projectName === projectName)
        // console.log(newRecord.id)
        this.setState({
            project_id: newRecord.id,
            name: projectName
        })
        this.$api.get(`/api/project/user_list/${newRecord.id}`).then(response => {
            // console.log(response.data.data,'22')
            let userLists = response.data
            let { designMemberVo, specialistMemberVo } = userLists
            if (designMemberVo == "") {
                return
            }
            if (specialistMemberVo == "") {
                return
            }
            designMemberVo.map(async item => {
                item.checked = false
                item.userName = await sm2Decrypt(item.userName)
            })
            specialistMemberVo.map(async item => {
                item.checked = false
                item.userName = await sm2Decrypt(item.userName)
            })
            this.setState({
                userLists,
                designMemberVo,
                specialistMemberVo
            })
            // console.log(designMemberVo,designMemberVo)
        })
    }

    handleCreate =async (e) => {
        e.preventDefault();
        if (!this.state.date){
            message.info("请输入会议日期");
            return
        }
        if (!this.state.start_time || !this.state.end_time) {
            message.info("请输入开始结束时间")
            return
        }
        let designMemberVo = this.state.designMemberVo
        let specialistMemberVo = this.state.specialistMemberVo
        // let name = await sm2Encrypt(this.props.user.userInfo.name)
        let name = this.props.user.userInfo.name
        let noticeParams =[]
        designMemberVo.map(item=>{
            let obj ={
                "sender_name":name,
                // "receiver_id": "1244537110145273858",
                "title": this.state.defaultProjectName,
                "description": `请于${this.state.date} ${this.state.start_time}参加会议`
            }
            if(item.checked){
                obj.receiver_id = item.id
                noticeParams.push(obj)
            }
        })
        specialistMemberVo.map(item=>{
            let obj ={
                "sender_name":name,
                // "receiver_id": "1244537110145273858",
                "title": this.state.defaultProjectName,
                "description": `请于${this.state.date} ${this.state.start_time}参加会议`
            }
            if(item.checked){
                obj.receiver_id = item.id
                noticeParams.push(obj)
            }
        })
        // console.log(noticeParams)
        // 如果project_id为空，使用默认id发送请求
        const params = {
            "csrfmiddlewaretoken": this.state.csrfmiddlewaretoken,
            "start_time": `${this.state.date} ${this.state.start_time}`,
            "end_time": `${this.state.date} ${this.state.end_time}`,
            "name": this.state.name || this.state.defaultProjectName,
            "project_id": this.state.project_id || this.state.defaultProjectId,
            "notice_all_member": this.state.notice_all_member,
            "room_num": this.state.room_num,
            "allow_member_upload": this.state.allow_member_upload,
            "allow_enter_before_compere": this.state.allow_enter_before_compere,
            "force_watermark": this.state.force_watermark,
            "file_ids":this.state.file_ids,
            "face_recognition": this.state.useFaceMatch,
            'notice_params': noticeParams
        }
        if (this.state.password) {
            params.password = this.state.password
        }
        this.$api.post("/api/meeting/create", params).then(res => {
            if(res.code === 504){
                return message.warn(res.msg)
            }
            // this.$api.post("/api/meeting/send", noticeParams).then(res => {
            //     if (res.data.length > 0) {
            //         message.info(res.data[0].send_result)
            //     }
            // })
            this.props.history.push('/meeting/meeting_status')
        })
    }

    onNoticeMember(e) {
        if (e.target.checked) {
            this.setState({
                notice_all_member: 1
            })
        } else {
            this.setState({
                notice_all_member: 0
            })
        }
        const designMemberVo = this.state.designMemberVo;
        const specialistMemberVo = this.state.specialistMemberVo;
        designMemberVo.map((item, index) => {
            item.checked = e.target.checked;
        });
        specialistMemberVo.map((item, index) => {
            item.checked = e.target.checked;
        });
        this.setState({
            'designMemberVo': designMemberVo, designAllChecked: e.target.checked,
            'specialistMemberVo': specialistMemberVo, specialAllChecked: e.target.checked
        });
    }

    roomNum = (value) => {
        this.setState({
            room_num: value
        })
    }

    onPassword(e) {
        this.setState({
            password: e.target.value
        })
    }

    onUploadFile = (e) => {
        if(this.props.user.userInfo.id === this.state.userLists.responsibilityPersonId){
            if (e.target.checked) {
                this.setState({
                    allow_member_upload: 1
                })
            } else {
                this.setState({
                    allow_member_upload: 0
                })
            }
        }else{
            message.warn('只有主持人才能修改')
            return
        }
    }
    handleUploadFile = (val) => {
        // console.log(val)
        if(val === "仅主持人可上传"){
            this.setState({
                allow_member_upload: 0
            })
            message.warn('仅主持人可上传')
        }else if(val === "全员可上传"){
            this.setState({
                allow_member_upload: 1
            })
            message.warn('全员可上传')
        }
    }
    onEnterBefore(e) {
        // if(this.props.user.userInfo.id === this.state.userLists.responsibilityPersonId || this.props.user.userInfo.id ===this.state.userLists.designDirectorId){
        //     if (e.target.checked) {
        //         this.setState({
        //             allow_enter_before_compere: true
        //         })
        //     } else {
        //         this.setState({
        //             allow_enter_before_compere: false
        //         })
        //     }
        // }else{
        //     message.warn('只有主持人才能修改')
        //     return
        // }
        message.warn('不能修改')
    }

    onWatermarkClick(e) {
        // console.log(e.target.checked)
    }

    onWatermark(e) {
        // console.log(e.target.checked)
        if (e.target.checked) {
            this.setState({
                force_watermark: 1
            })
        } else {
            this.setState({
                force_watermark: 0
            })
        }
    }
    changeDesignAll = (e) => {
        const designMemberVo = this.state.designMemberVo;
        designMemberVo.map((item, index) => {
            item.checked = e.target.checked;
        });
        this.setState({ 'designMemberVo': designMemberVo, designAllChecked: e.target.checked });
    }

    changeSpecialAll = (e) => {
        const specialistMemberVo = this.state.specialistMemberVo;
        specialistMemberVo.map((item, index) => {
            item.checked = e.target.checked;
        });
        this.setState({ 'specialistMemberVo': specialistMemberVo, specialAllChecked: e.target.checked });
    }
    handleExplain = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            showExplain: !this.state.showExplain
        })
    }
    handleCreateClick = () => {
        listener.fire('create', 'hideexplain')
    }
    handleExplainBox = (e) => {
        e.stopPropagation()
    }
    range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }
    disabledDate = (current) => {
        // return current && current < moment().subtract(1, 'days')
        // return current && current < moment(formatDate('yyyy-MM-dd ')+' 00:00:00')
        return current && current < moment(formatDate('yyyy-MM-dd'))
    }
    // disabledDateTime = () =>{
    //     return {
    //         disabledHours: () => this.range(0,24).splice(0,new Date().getHours()),
    //     }
    // }
    disabledEndDate = (current) => {
        if (this.state.start_time) {
            // return current &&  current < moment(this.state.start_time
            return current < moment(this.state.start_time) || current > moment(formatDate('yyyy-MM-dd', this.state.start_time) + ' 24:00:00');
        }
    }

    disabledStartTime = ()=>{
        if(moment(this.state.date).isSame(moment(), "day")){
            return {
                disabledHours: () => this.range(0, 24).splice(0, new Date().getHours()),
            }
        }else{
            return {};
        }
    }

    disabledEndTime = () => {
        const [hour, minute] = this.state.start_time.split(':').map(i=>parseInt(i));
        return {
            disabledHours: () => this.range(0, 24).splice(0, hour),
            disabledMinutes: (selectedHour) => {
                if(selectedHour<hour){
                    return this.range(0, 59);
                }else if(selectedHour==hour){
                    return this.range(0, minute);
                }else{
                    return [];
                }
            }
        }
    }
    handleChange = (e) => {
        this.setState({
            search: e.target.value
        })
    }
    handleShowFile = (e) => {
        e.preventDefault();
        this.setState({ showFile: true })
    }

    isSubmitAvailable = ()=>{
        return this.state.date && this.state.project_id && this.state.room_num > 0 && this.state.start_time && this.state.end_time;
    }

    render() {
        let designMemberVo = this.state.designMemberVo
        let specialistMemberVo = this.state.specialistMemberVo
        let memberArr = []
        designMemberVo.map(item=>{
            let obj = {}
            obj.checked = item.checked
            obj.id = item.id
            obj.majorCode = item.majorCode
            obj.majorName = item.majorName
            obj.portrait = item.portrait
            obj.userName = item.userName
            memberArr.push(obj)
        })
        specialistMemberVo.map(item=>{
            let obj = {}
            obj.checked = item.checked
            obj.id = item.id
            obj.majorCode = item.majorCode
            obj.majorName = item.majorName
            obj.portrait = item.portrait
            obj.userName = item.userName
            memberArr.push(obj)
        })
        if (this.state.search !== '') {
            designMemberVo = designMemberVo.filter(item => item.userName.indexOf(this.state.search) > -1 || (item.majorName && item.majorName.indexOf(this.state.search) > -1))
            specialistMemberVo = specialistMemberVo.filter(item => item.userName.indexOf(this.state.search) > -1 || (item.majorName && item.majorName.indexOf(this.state.search) > -1))
        }
        return (
            <div id="create-box" onClick={this.handleCreateClick}>
                <HeaderComponent history={this.props.history} />
                <div id="title">
                    <p>预定会议</p>
                </div>
                <div id="create-form">
                    <form>
                        {/*<input type="hidden" name="csrfmiddlewaretoken" value="{{ token }}"/>*/}
                        <label className={'required'}>
                            会议日期：
                            <DatePicker className="DatePicker" locale={locale} placeholder="请选择"  onChange={this.onDate.bind(this)}
                                disabledDate={this.disabledDate}
                                format="YYYY-MM-DD"
                            />
                        </label>
                        <br />

                        <label className={'required'}>
                            会议时间：
                            {/*<input name="start_time" type="datetime-local" value="2020-12-12 12:12:12"/>*/}
                            <TimePicker locale={locale} placeholder="请选择"  disabled={this.state.date ? false : true}
                                onChange={this.onStart.bind(this)}  disabledTime={this.disabledStartTime} showNow={moment(this.state.date).isSame(moment(), "day")}
                                format="HH:mm"    minuteStep={15}
                             />
                            &nbsp;~&nbsp;
                            <TimePicker locale={locale} placeholder="请选择"  disabled={this.state.start_time ? false : true}
                                onChange={this.onEnd.bind(this)}  disabledTime={this.disabledEndTime} showNow={false}
                                format="HH:mm"    minuteStep={15}
                             />
                        </label>
                        <br />
                        <label id="form-setting" className={'required'}>
                            关联项目：
                            <select
                                className={classNames({
                                    'border': true
                                })}
                                placeholder="请选择"
                                onChange={this.handleProject.bind(this)}>
                                {this.state.records.map((item,index) => (
                                    <option key={index}
                                        value={item.projectName}>
                                        {item.projectName}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label id="member">
                            <span className="default-checkbox">
                                <input type="checkbox"
                                onChange={this.onNoticeMember.bind(this)}
                                checked={memberArr.every(item => item.checked)}
                                 />
                            </span>
                            通知所有成员（{this.state.designMemberVo.filter(item=>item.checked).length + this.state.specialistMemberVo.filter(item=>item.checked).length}人） <a
                                onClick={this.handleMember}>成员列表</a>
                        </label>
                        <br />
                        <label id="branch-venue">
                            <span>分会场设置</span>
                            <img src={question_mark_icon} alt="" />
                            <a href="" onClick={this.handleExplain}>说明</a>
                            <div className={classNames({ "explain-box": true, show: this.state.showExplain })}
                                onClick={this.handleExplainBox}>
                                <dl>
                                    <dt style={{marginBottom: '0.5em'}}>分会场设置：</dt>
                                    <dd>1.创建会议的同时可自动生成10个以内的分会场。</dd>
                                    <dd>2.分会场会议时间和主会场保持一致</dd>
                                    <dd>3.入会密码属性保持一致</dd>
                                    <dd>4.会议名称及会议号和主会场主体内容保持一致，会议号位数加一。例如：会议主题：湖南永州宁远城西110kv输变工程20200507（分会场01）、
                                    云评审会议号：HNPS 0514 0321（主会场）、HNPS 0514 0322（分会场1）、HNPS 0514 0323（分会场2）
                                    </dd>
                                </dl>
                                <div className="left"></div>
                            </div>
                        </label>
                        <label id="room_num" className={'required'}>
                            分会场数量: &nbsp;
                            {/*<input id="room_num" name="room_num" type="number" value="1"/>*/}
                            <InputNumber min={1} max={10} defaultValue={this.state.room_num} onChange={this.roomNum} />
                        </label>
                        <br />
                        <label id="form-setting">入会身份校验</label>
                        <div className="open-password">
                            <label>
                                <span className="default-checkbox">
                                    <input type="checkbox" onChange={this.handlePassword.bind(this)} />
                                </span>
                                <span>开启会议密码</span>
                            </label>
                            <Input.Password onChange={this.onPassword.bind(this)} className={classNames({ 'show': this.state.showPassword })} placeholder="请输入" />
                        </div>
                        <div className="open-password">
                            <label>
                                <span className="default-checkbox">
                                    <input type="checkbox" onChange={this.handleFaceMatch.bind(this)} />
                                </span>
                                <span>开启人脸识别</span>
                            </label>
                        </div>
                        <br />
                        <label id="form-setting">
                            文档&nbsp;
                            <a onClick={this.handleShowFile}>点击添加</a>
                            {/* <Link to="/meeting/add_file/1">点击添加</Link> */}
                        </label>
                        
                        <label id="allow-upload">
                            {/*<input type="checkbox" onClick={this.onUploadFile.bind(this)}*/}
                            {/*       checked={this.state.uploadFileChecked} name="allow_member_upload"/>*/}
                            <span className="default-checkbox">
                                <input onChange={this.onUploadFile.bind(this)}
                                    // defaultChecked={this.state.allow_member_upload}
                                    checked={this.state.allow_member_upload}
                                    type="checkbox" />
                            </span>
                            允许成员上传文档
                        </label>
                        <div className="file-list-box">
                            <AddFile
                                showTableOnly
                                id='0'
                                allow_member_upload={this.state.allow_member_upload}
                                userLists={this.state.userLists}
                                back={(file_ids,items) => this.setState({ showFile: false,file_ids,file_lists:items})}
                                upload={this.handleUploadFile}
                                file_lists={this.state.file_lists}
                            />
                        </div>
                        <br />
                        <label id="form-setting">会议设置</label>
                        <label id="watermark">
                            {/*<input type="checkbox" onClick={this.onWatermark.bind(this)}*/}
                            {/*       checked={this.state.forceWatermarkChecked} name="force_watermark"/>*/}
                            <span className="default-checkbox">
                                <input
                                    onChange={this.onWatermark.bind(this)}
                                    defaultChecked={this.state.force_watermark}
                                    type="checkbox" disabled />
                            </span>
                            开启屏幕共享水印
                        </label>
                        <br />
                        <div className="footer-buttons">
                            <button type="button" className="cancel" onClick={()=>{history.length ? history.back() : (location.hash='/')}}>取消</button>
                            <button disabled={!this.isSubmitAvailable()} id="submit" onClick={this.handleCreate.bind(this)}>预定</button>
                        </div>
                    </form>
                </div>
                <div id="member-window" className={classNames({
                    'show': this.state.showMember
                })}>
                    <div id="member-list">
                        <div id="member-top">
                            <div
                                id="total">成员列表({this.state.designMemberVo.length + this.state.specialistMemberVo.length}人)
                            </div>
                            <div id="exit">
                                <img onClick={this.handleCancel.bind(this)}
                                    src={exit_icon} alt="" />
                            </div>
                        </div>

                        <div id="member-search">
                            <Search
                                onChange={this.handleChange}
                                placeholder="搜索成员"
                                onSearch={value => console.log(value)}
                                style={{ width: 300 }}
                            />
                        </div>

                        <div id="overflow-list">
                            {this.state.userLists !== "" ?
                                <dl className="member-list">
                                    <dt>
                                        <span><span
                                            className="spot">●</span>设计单位：{this.state.userLists.designUnitShortName}（{this.state.designMemberVo.length}人）</span>
                                        <label className="default-checkbox">
                                            <input onChange={this.changeDesignAll}
                                                checked={this.state.designAllChecked}
                                                type="checkbox" />
                                        </label>
                                    </dt>
                                    {designMemberVo.map((item, index) => [
                                        <MemListComponent
                                            item={item}
                                            key={item.id}
                                            checked={item['checked']}
                                            onChange={(checked) => {
                                                // const designMemberVo = this.state.designMemberVo;
                                                designMemberVo[index].checked = checked;
                                                this.setState({
                                                    'designMemberVo': designMemberVo,
                                                    "designAllChecked": designMemberVo.every(item => item.checked)
                                                });
                                            }}
                                        />
                                    ])}
                                    <hr style={{ background: "#DDDDDD", height: "1px", border: "none" }} />
                                    <dt>
                                        <span><span
                                            className="spot">●</span>评审单位：{this.state.userLists.specialistShortName}（{this.state.specialistMemberVo.length}人）</span>
                                        <label className="default-checkbox">
                                            <input onChange={this.changeSpecialAll}
                                                checked={this.state.specialAllChecked}
                                                type="checkbox" />
                                        </label>
                                    </dt>
                                    {specialistMemberVo.map((item, index) => [
                                        <MemListComponent
                                            item={item}
                                            key={item.id}
                                            checked={item['checked']}
                                            onChange={(checked) => {
                                                // const specialistMemberVo = this.state.specialistMemberVo;
                                                specialistMemberVo[index].checked = checked;
                                                this.setState({
                                                    'specialistMemberVo': specialistMemberVo,
                                                    "specialAllChecked": specialistMemberVo.every(item => item.checked)
                                                });
                                            }}
                                        />
                                    ])}


                                </dl> :
                                <div style={{ textAlign: "center", marginTop: "30px" }}>暂无数据</div>
                            }
                        </div>
                        <div id="member-bottom">
                            <div id="buttons">
                                <button id="cancel" onClick={this.cancel.bind(this)}>取消</button>
                                <button id="define" onClick={this.define.bind(this)}>确定</button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showFile &&
                    <AddFile
                        id='0'
                        allow_member_upload={this.state.allow_member_upload}
                        userLists={this.state.userLists}
                        back={(file_ids,items) => this.setState({ showFile: false,file_ids,file_lists:items})}
                        upload={this.handleUploadFile}
                        file_lists={this.state.file_lists}
                    />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(withRouter(Create))