import {receiveMsg} from '../actions/chat'
import {updateStatus, updateUser, updateSetting} from '../actions/meet'
import {message, Modal} from 'antd';
import store from '../store'
import throttle from '../lib/throttle'
import getUniqueKey from '../lib/getUniqueKey'
import listener from '../lib/listener'
import isMutedByManager from './isMutedByManager'
import React from 'react';

const JitsiMeetJS = window.JitsiMeetJS
// console.log(JitsiMeetJS,'JitsiMeetJS1111111')
const $ = window.$
const SPLIT_WORD = '--u--'

const BASE_DOMAIN = 'bjnsc-meet.usingnet.com';

let has_video_model_show = false;
function __init_video_can_play() {
    window.__can_video_play = true;
    document.removeEventListener('click', __init_video_can_play);
    while(window.__need_to_play_ids && window.__need_to_play_ids.length){
        let statsId = window.__need_to_play_ids.pop();
        $(`#video-${statsId}`)[0].play();
    }    
}
document.addEventListener('click', __init_video_can_play);

class Meetin {
    options = {
        // serviceUrl: 'wss://meet.bjnsc.usingnet.com/xmpp-websocket',
        hosts: {
            // domain: 'alpha.jitsi.net',
            domain: BASE_DOMAIN,
            // muc: 'conference.alpha.jitsi.net' // FIXME: use XEP-0030
            muc: `muc.${BASE_DOMAIN}`
        },
        // bosh: 'https://alpha.jitsi.net/http-bind', // FIXME: use xep-0156 for that
        bosh: `https://${BASE_DOMAIN}/http-bind`,
        websocket: `wss://${BASE_DOMAIN}/xmpp-websocket`,
        openBridgeChannel: 'websocket',
        useIPv6: true,
        enableLipSync: false,
        transcribingEnabled: false,
        enableRemb: true,
        enableTcc: true,
        resolution: 720,
        constraints: {
            video: {
                height: {
                    ideal: 720,
                    max: 720,
                    min: 180
                },
                width: {
                    ideal: 1280,
                    max: 1280,
                    min: 320
                }
            }
        }
        // useStunTurn: truegit push
        // The name of client node advertised in XEP-0115 'c' stanza
        // clientNode: 'http://alpha.jitsi.net'
        // clientNode: 'https://meet.bjnsc.usingnet.com'

    };
    confOptions = {
        openBridgeChannel: true,
        statisticsDisplayName: 'default',
        statisticsId: store.getState().user.userInfo.id,
        siteID: 'default',
        displayName: store.getState().user.userInfo.name
    };
    connection = null;
    isJoined = false;
    room = null;

    localTracks = {
        video: {},
        audio: {}
    };
    screenTrack = null;
    remoteTracks = {};
    params = {}
    statsIds = {}
    isVideo = true
    currentMain = {
        track: null,
        id: null
    }

    networkCheckTimer = null;

    muteLocalVideo = () => {
        if (!this.isVideo) {
            message.warn('正在进行屏幕分享');
            return
        }
        const track = this.localTracks['video']['track']
        if (track) {
            track.isMuted() ? track.unmute() : track.mute()
            track.attach($(`#video-${store.getState().user.userInfo.id}`)[0]);
        } else {
            JitsiMeetJS.createLocalTracks({
                devices: ['video']
            })
                .then(tracks => {
                    this.onLocalTracks(tracks)
                    store.dispatch(updateStatus({
                        videoMuted: false
                    }))
                    store.dispatch(updateUser({
                        id: store.getState().user.userInfo.id,
                        isVMuted: false
                    }))
                })
                .catch(error => {
                    this.isVideo = true
                });
        }
    }
    muteLocalAudio = () => {
        const track = this.localTracks['audio']['track']
        if (track) {
            if (track.isMuted()) {
                if (isMutedByManager(store.getState().user.userInfo.id, store.getState().meet)) {
                    this.handleHandUp()
                    return message.warn('您已被主持人静音，正在举手等待发言')
                }
                track.unmute()
            } else {
                track.mute()
            }

        } else {
            if (isMutedByManager(store.getState().user.userInfo.id, store.getState().meet)) {
                this.handleHandUp()
                return message.warn('您已被主持人静音，正在举手等待发言')
            }
            JitsiMeetJS.createLocalTracks({
                devices: ['audio']
            })
                .then(tracks => {
                    this.onLocalTracks(tracks)
                    store.dispatch(updateStatus({
                        audioMuted: false
                    }))
                    this.onAMuteChange(store.getState().user.userInfo.id, false)
                })
                .catch(error => {

                });
        }
    }
    muteLocalAudioForce = () => {

        const track = this.localTracks['audio']['track']
        if (track && !track.isMuted()) {
            track.mute()
        }
    }
    countDown = () => {
        let secondsToGo = 5;
        const timer = setInterval(() => {
            secondsToGo -= 1;
            if(secondsToGo<0){
                return;
            }
            modal.update({
                content: `您已被主持人移除会议，${secondsToGo}秒后将自动退出本次会议！`,
                okText: `${secondsToGo}s`
            });
        }, 1000);
        const modal = Modal.warning({
            title: '提示',
            content: `您已被主持人移除会议，${secondsToGo}秒后将自动退出本次会议！`,
            okText: `${secondsToGo}s`,
            okButtonProps: {
                disabled: true,
            },
        });

        this.unload();
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
            window.location.href = "#/meeting/meeting_status";
        }, secondsToGo * 1000+500);
    }
    onTextMessage = (id, text, ts) => {
        const myUid = store.getState().user.userInfo.id
        const r = JSON.parse(text)
        if (r.type === 'msg') {
            if (store.getState().chat.msgs.map(item => item.id).join(',').indexOf(r.id) > -1) {
                return
            }
            store.dispatch(receiveMsg(r))
            store.dispatch(updateStatus({
                unread: true
            }))
            listener.fire('meet', 'chat')
        } else if (r.type === 'kickout') {
            if (myUid === r.user_id) {
                this.countDown()
            }else{
                store.getState().meet.startTime < r.time && message.warn(`移除 ${r.userName}`)
            }
        } else if (r.type === 'lock') {
            store.dispatch(updateStatus({
                lock: r.res
            }))
            store.getState().meet.startTime < r.time && message.warn(r.res ? '会议已锁定' : '会议锁定已解除')
        }else if(r.type === 'face-match'){
            store.dispatch(updateStatus({
                showFaceMatch: r.res
            }))
            store.getState().meet.startTime < r.time && message.warn(r.res ? '已开启人脸验证' : '已关闭人脸验证')
        }else if(r.type === 'set-screen-sharing'){
            store.dispatch(updateStatus({
                allowAllToShareScreen: r.res
            }))
        }else if(r.type === "allow_member_upload"){
            store.dispatch(updateStatus({
                allow_member_upload: r.res
            }))
            store.getState().meet.startTime < r.time && message.warn(r.res ===0 ? '仅主持人可上传' : '全员可上传')
        }else if(r.type === 'handup'){
            let handupList = store.getState().meet.handupList
            if (handupList.join(',').indexOf(r.user_id) > -1) {
                return
            }
            store.dispatch(updateStatus({
                handupList: handupList.concat([r.user_id])
            }))
        }else if(r.type === 'cancelhandup'){
            let handupList = store.getState().meet.handupList
            if (handupList.join(',').indexOf(r.user_id) === -1) {
                return
            }
            store.dispatch(updateStatus({
                handupList: handupList.filter(item => item !== r.user_id)
            }))
        } else if(r.type === 'screen') {
            store.dispatch(receiveMsg(r))

        } else {
            if (r.time < store.getState().meet.startTime) {
                return
            }
            if (r.type === 'mute-audio') {
                if (r.user_id === myUid || r.user_id === 'all') {
                    this.muteLocalAudioForce()
                    let payload = {}
                    if (r.user_id === 'all') {
                        payload = {
                            allMuted: true,
                            whiteList: []
                        }
                    } else {
                        let whiteList = store.getState().meet.whiteList
                        if (store.getState().meet.allMuted) {
                            whiteList = whiteList.filter(item => item !== r.user_id)
                        } else {
                            whiteList.push(r.user_id)
                        }
                        payload = {
                            whiteList
                        }
                    }
                    store.dispatch(updateStatus(payload))
                    store.getState().meet.startTime < r.time && message.warn(r.user_id === 'all' ? '全员静音' : '您已被设置为静音');
                }
            } else if (r.type === 'unmute-audio') {
                if (r.user_id === myUid || r.user_id === 'all') {
                    let payload = {}
                    if (r.user_id === 'all') {
                        payload = {
                            allMuted: false,
                            whiteList: []
                        }
                    } else {
                        let whiteList = store.getState().meet.whiteList
                        if (!store.getState().meet.allMuted) {
                            whiteList = whiteList.filter(item => item !== r.user_id)
                        } else {
                            whiteList.push(r.user_id)
                        }
                        payload = {
                            whiteList
                        }
                    }
                    store.dispatch(updateStatus(payload))
                    store.getState().meet.startTime < r.time && message.warn(r.user_id === 'all' ? '全员静音已解除' : '静音已解除');
                }
            } else if (r.type === "set-as-manager"){
                store.dispatch(updateStatus({
                    coChair: r.user_id
                }))
                store.getState().meet.startTime < r.time && message.warn(r && `已设置 ${r.userName} 为联席主持人`)
            } else if(r.type === "join-tips"){
                store.getState().meet.startTime < r.time && message.warn(r && `${r.userName} 加入了会议`)
            }
        }

    }
    sendTextMessage = (msg, to) => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'msg',
            user_id: myUid,
            user_nick: store.getState().user.userInfo.name,
            text: msg,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            to
        }
        store.dispatch(receiveMsg(msgObj))
        this.room.sendTextMessage(JSON.stringify(msgObj))

    }

    //投屏
    handleScreen = () => {
        const msgObj = {
            type: 'screen',
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }

    handleHandUp = () => {
        const myUid = store.getState().user.userInfo.id
        if (store.getState().meet.handupList.join(',').indexOf(myUid) > -1) {
            return
        }
        const msgObj = {
            type: 'handup',
            user_id: myUid,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
        // 20s后自动取消
        setTimeout(() => {
            this.handleCancelHandUp()
        }, 20 * 1000)
    }
    handleCancelHandUp = () => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'cancelhandup',
            user_id: myUid,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    handleMuteAudio = (user_id, res) => {

        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: res ? 'mute-audio' : 'unmute-audio',
            user_id,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    handleSetAsManager = (user_id,userName,res) => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'set-as-manager',
            user_id,
            userName,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    kickout = (user_id, users) => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'kickout',
            user_id,
            userName: users.filter(item=>item.id === user_id)[0].userName,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    lockConference = (res) => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'lock',
            res,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    faceMatch = (res)=>{
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'face-match',
            res,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    setScreenSharing = (res) => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'set-screen-sharing',
            res,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    allowMemberUpload = (res) => {
        const myUid = store.getState().user.userInfo.id
        const msgObj = {
            type: 'allow_member_upload',
            res,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))
    }
    setVideoToMain = (user_id) => {
        if (user_id === store.getState().user.userInfo.id) {
            const track = this.localTracks['video']['track']
            if (track && !track.isMuted()) {
                if (user_id === this.currentMain.id) {
                    this.localTracks['video']['con'] = ''
                    this.currentMain.id = null;
                    track.attach($(`#video-${user_id}`)[0])
                    track.detach($('#videomain')[0])

                    this.currentMain = {
                        track: null,
                        id: null
                    }
                } else {

                    this.localTracks['video']['con'] = 'videomain'
                    if (this.currentMain.id) {
                        let origin_track = this.currentMain.track;
                        origin_track.attach($(`#video-${this.currentMain.id}`)[0]);
                        origin_track.detach($('#videomain')[0]);
                    }
                    track.attach($('#videomain')[0])
                    this.currentMain.id = user_id;
                    track.detach($(`#video-${user_id}`)[0])
                    this.currentMain = {
                        track: this.localTracks['video']['track'],
                        id: user_id
                    }
                }
            }
        }
        if (this.remoteTracks[user_id] && this.remoteTracks[user_id]['video']) {
            const track = this.remoteTracks[user_id]['video']['track']
            if (track && !track.isMuted()) {
                if (user_id === this.currentMain.id) {
                    this.remoteTracks[user_id]['video']['con'] = ''
                    this.currentMain.id = null;
                    track.attach($(`#video-${user_id}`)[0])
                    track.detach($('#videomain')[0])
                    this.currentMain = {
                        track: null,
                        id: null
                    }

                } else {
                    if (this.currentMain.id) {
                        let origin_track = this.currentMain.track;
                        origin_track.attach($(`#video-${this.currentMain.id}`)[0]);
                        origin_track.detach($('#videomain')[0]);
                    }

                    this.remoteTracks[user_id]['video']['con'] = 'videomain'
                    track.attach($('#videomain')[0])
                    track.detach($(`#video-${user_id}`)[0])
                    this.currentMain.id = user_id;
                    
                    this.currentMain = {
                        track: this.remoteTracks[user_id]['video']['track'],
                        id: user_id
                    }
                }
            }

        }
    }

    setCamera = (deviceId) => {
        console.log(deviceId);
        if (this.localTracks['video']['track']) {
            this.localTracks['video']['track'].dispose();
            this.localTracks['video']['track'] = null
        }
        // JitsiMeetJS.createLocalTracks({
        //     devices: ['video'],
        //     micDeviceId: deviceId
        // })
        //     .then(tracks => {
        //         store.dispatch(updateStatus({
        //             audioMuted: false
        //         }))
        //         this.onLocalTracks(tracks)
        //
        //     })
        //     .catch(error => {
        //
        //     });

        JitsiMeetJS.createLocalTracks({
            devices: ['video'],
            cameraDeviceId: deviceId
        })
            .then(tracks => {
                this.onLocalTracks(tracks)
                store.dispatch(updateStatus({
                    videoMuted: false
                }))
                store.dispatch(updateUser({
                    id: store.getState().user.userInfo.id,
                    isVMuted: false
                }))
            })
            .catch(error => {
                this.isVideo = true
            });
    }

    setMic = (deviceId) => {
        if (isMutedByManager(store.getState().user.userInfo.id, store.getState().meet)) {
            return message.warn('您已被主持人静音，正在举手等待发言')
        }
        if (this.localTracks['audio']['track']) {
            this.localTracks['audio']['track'].dispose();
            this.localTracks['audio']['track'] = null
        }
        JitsiMeetJS.createLocalTracks({
            devices: ['audio'],
            micDeviceId: deviceId
        })
            .then(tracks => {
                store.dispatch(updateStatus({
                    audioMuted: false
                }))
                this.onLocalTracks(tracks)

            })
            .catch(error => {

            });
    }
    getActiveDevice = () => {
        return JitsiMeetJS.getActiveAudioDevice()
    }
    getdevices = () => {
        const audioId = JitsiMeetJS.mediaDevices.getAudioOutputDevice()
        JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
            store.dispatch(updateStatus({
                devices,
                audioId
            }))
        });

    }
    setAudioOutputDevice = (deviceId) => {
        JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)
        store.dispatch(updateStatus({
            audioId: deviceId
        }))
    }
    onAMuteChange = (user_id, res) => {
        store.dispatch(updateUser({
            id: user_id,
            isAMuted: res
        }))
    }
    onAudioLevelChanged = throttle((id, audioLevel) => {
        store.dispatch(updateUser({
            id,
            audioLevel
        }))
    }, 500)
    /**
     * Handles local tracks.
     * @param tracks Array with JitsiTrack objects
     */
    onLocalTracks = (tracks) => {
        window.localTracks = tracks;
        for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i]
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                (res) => {
                    if (track.getType() === 'video') {
                        store.dispatch(updateStatus({
                            videoMuted: track.isMuted()
                        }))
                    } else {

                        // this.params.onAudioMuteChange && this.params.onAudioMuteChange(track.isMuted())
                        store.dispatch(updateStatus({
                            audioMuted: track.isMuted()
                        }))
                        this.onAMuteChange(store.getState().user.userInfo.id, track.isMuted())
                        if (track.isMuted()) {
                            track.removeEventListener(
                                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                                this.onAudioLevelChanged,
                            );
                        }
                    }
                });
            track.addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => console.log('local track stoped'));
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId =>
                    console.log(
                        `track audio output device was changed to ${deviceId}`));
            if (track.getType() === 'video') {
                this.localTracks['video']['track'] = track
                // $('body').append(`<video autoplay='1' id='localVideo${i}' />`);
                // track.attach($(`#localVideo${i}`)[0]);
                console.log(this.params, '000')
                track.attach($(`#video-${store.getState().user.userInfo.id}`)[0]);
                store.dispatch(updateStatus({
                    camId: track.getDeviceId()
                }))
                store.dispatch(updateUser({
                    id: store.getState().user.userInfo.id,
                }))

            } else {
                this.localTracks['audio']['track'] = track
                // $('body').append(
                //     `<audio autoplay='1' id='localAudio${i}' />`);
                // track.attach($(`#localAudio${i}`)[0]);
                store.dispatch(updateStatus({
                    micId: track.getDeviceId()
                }))
                // store.dispatch(updateUser({
                //     id: store.getState().user.userInfo.id,

                // }))
                track.addEventListener(
                    JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                    this.onAudioLevelChanged.bind(this, store.getState().user.userInfo.id),
                );
            }
            if (this.isJoined) {
                this.room.addTrack(track);
            }
        }
    }

    /**
     * Handles remote tracks
     * @param track JitsiTrack object
     */
    onRemoteTrack = (track) => {
        const participant = track.getParticipantId();
        const par = this.room.getParticipantById(participant)
        const statsId = par && par.getStatsID().split(SPLIT_WORD)[0]
        console.log(track.isLocal(), statsId, par, '000-onremotetrack')
        //todo
        // track.attach($(`#videomain`)[0])
        // this.currentMain.id = statsId;

        if (track.isLocal() || !par || statsId === store.getState().user.userInfo.id) {
            return;
        }
        if (!this.remoteTracks[statsId]) {
            this.remoteTracks[statsId] = {};
        }
        const type = track.getType()
        this.remoteTracks[statsId][type] = {
            track
        }
        const idx = type;
        track.addEventListener(
            JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
            () => console.log('remote track stoped'));
        track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
            deviceId =>
                console.log(
                    `track audio output device was changed to ${deviceId}`));
        const id = statsId + track.getType() + idx;
        console.log(id)
        if (track.getType() === 'video') {
            console.log($(`#video-${statsId}`), 'video remote track')


            // debugger;
            // if(!this.currentMain.id){
            //     track.attach($(`#videomain`)[0])
            //     this.currentMain.id = statsId;
            // }else{
                track.attach($(`#video-${statsId}`)[0]);
                (function(statsId){
                    if(window.__can_video_play){
                        $(`#video-${statsId}`)[0].play();
                    }else{
                        if(!has_video_model_show){
                            has_video_model_show = true;
                            Modal.info({
                                title:'多媒体',
                                content: <div><p>此页面将自动播放音视频</p></div>,
                                className:'media-auto-play-modal',
                                okText: '知道了',
                                onOk:()=>{}
                            });
                        }
                        if(typeof(window.__need_to_play_ids) === 'undefined'){
                            window.__need_to_play_ids = [];
                        }
                        window.__need_to_play_ids.push(statsId);
                    }
                })(statsId);
            // }


        } else {
            $('body').append(
                `<audio autoplay='1' id='audio-${statsId}' />`);
            track.attach($(`#audio-${statsId}`)[0]);
            store.dispatch(updateUser({
                id: statsId,
                isAMuted: track.isMuted()
            }))
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                this.onAudioLevelChanged.bind(this, statsId));
        }
    }

    /**
     * That function is executed when the conference is joined
     */
    onConferenceJoined = () => {
        console.log('conference joined!');
        this.isJoined = true;
        this.localTracks['audio']['track'] && this.room.addTrack(this.localTracks['audio']['track']);
        this.localTracks['video']['track'] && this.room.addTrack(this.localTracks['video']['track']);
        const myUid = store.getState().user.userInfo.id
        const companyShortName = store.getState().user.userInfo.companyShortName
        const msgObj = {
            type: 'join-tips',
            user_id: myUid,
            userName: store.getState().user.userInfo.name,
            id: myUid + getUniqueKey(),
            time: new Date().getTime(),
            from: myUid,
            companyShortName: companyShortName || null
        }
        this.room.sendTextMessage(JSON.stringify(msgObj))

        store.dispatch(updateStatus({
            roomInited: true,
            startTime: new Date().getTime()
        }))
        // this.statsIds[id] = statsId || store.getState().user.userInfo.id
        // debugger;
        store.dispatch(updateUser({
            id: store.getState().user.userInfo.id,
            userName: store.getState().user.userInfo.name,
            companyShortName: companyShortName || null,
            isIn: true
        }))
        console.log('conference joined')
    }

    /**
     *
     * @param id
     */
    onUserLeft = (id) => {
        const statsId = this.statsIds[id]
        const tracks = this.remoteTracks[statsId];
        // console.log(id, tracks, statsId, '000-user0left', store.getState().meet.users)
        const user = store.getState().meet.users.filter(item => item.user_id === statsId)[0]
        if (user && user.userName) {
            message.warn(`${user.userName} 离开了会议`)
        }
        store.dispatch(updateUser({
            id: statsId,
            isIn: false,
            isAMuted: true,
            isVMuted: true
        }))
        if (!tracks) {
            return
        }
        if (tracks['video']) {
            if (this.currentMain.id === statsId) {
                tracks['video']['track'].detach($(`#videomain`)[0])
                this.currentMain.id = null;
                this.currentMain = {
                    id: null,
                    track: null
                }
            } else {
                tracks['video']['track'].detach($(`#video-${statsId}`)[0])
            }
            tracks['video']['track'].dispose()
        }
        if (tracks['audio']) {
            tracks['audio'] && tracks['audio']['track'].detach($(`#audio-${statsId}`)[0])
            tracks['audio']['track'].dispose()
        }

    }

    onUserJoin = (id) => {
        // debugger;
        console.log('userJoin', id)
        const par = this.room.getParticipantById(id)
        window.par = par
        // console.log(par, 777)
        const statsId = par && par.getStatsID().split(SPLIT_WORD)[0]
        console.log(statsId, 999)
        if (statsId === store.getState().user.userInfo.id) {
            return
        }
        const user = store.getState().meet.users.filter(item => item.user_id === statsId)[0]
        this.statsIds[id] = statsId || store.getState().user.userInfo.id
        // debugger;
        store.dispatch(updateUser({
            id: statsId,
            userName: par.getStatsID().split(SPLIT_WORD)[1],
            companyShortName:  par.getStatsID().split(SPLIT_WORD)[2],
            isIn: true
        }))
        console.log('user join', id, par, statsId, '000');
    }


    onTrackRemove = (track)=>{
        const participant = track.getParticipantId();
        const par = this.room.getParticipantById(participant)
        const statsId = par && par.getStatsID().split(SPLIT_WORD)[0]
        
        if (track.getType() === 'audio') {
            $(`audio-${statsId}`).remove();
        } else if (track.getType() === 'video') {
            if (statsId === store.getState().user.userInfo.id) {

            } else {
                console.log('remote track removed')
                // debugger;
                if (this.currentMain.id === statsId) {
                    track.detach($('#videomain')[0])
                    this.currentMain.id = null;
                } else {
                    track.detach($(`#video-${statsId}`)[0]);
                }
                // if (track.isMuted()) {
                //     if (this.currentMain.id === statsId) {
                //         track.detach('#videomain')
                //     } else {
                //         track.detach($(`#video-${statsId}`)[0]);
                //     }
                // } else {
                //     if (this.currentMain.id === statsId) {
                //         track.attach('#videomain')
                //     } else {
                //         console.log(2333333, $(`#video-${statsId}`)[0])
                //         track.attach($(`#video-${statsId}`)[0]);
                //     }
                // }

            }
        }
    }

    onTrackUpdate = (track)=>{
        
        console.log(`${track.getType()} - ${track.isMuted()} 999`);
        const participant = track.getParticipantId();
        const par = this.room.getParticipantById(participant)
        const statsId = par && par.getStatsID().split(SPLIT_WORD)[0]
        if (track.getType() === 'audio') {
            console.log('audio change')
            this.onAMuteChange(statsId, track.isMuted())
        } else if (track.getType() === 'video') {
            
            if (statsId === store.getState().user.userInfo.id) {

            } else {
                console.log('remote track changed', track.isMuted())
                // setTimeout(()=>{
                    // debugger;
                    if (track.isMuted()) {
                        if (this.currentMain.id === statsId) {
                            // debugger;
                            track.detach($('#videomain')[0])
                            this.currentMain.id = null;
                        } else {
                            track.detach($(`#video-${statsId}`)[0]);
                        }
                    } else {
                        if (this.currentMain.id === statsId) {
                            track.attach($('#videomain')[0])
                        } else {
                            console.log(2333333, $(`#video-${statsId}`)[0])
                            track.attach($(`#video-${statsId}`)[0]);
                        }
                    }
                // }, 1000);
                // debugger;
            }

        }
    }

    /**
     * That function is called when connection is established successfully
     */
    onConnectionSuccess = (res) => {
        // console.log(res, 'onConnectionSuccess')
        store.dispatch(updateStatus({
            progress: '95%'
        }))
        this.confOptions.statisticsId = store.getState().user.userInfo.id + SPLIT_WORD + store.getState().user.userInfo.name + SPLIT_WORD + (store.getState().user.userInfo.companyShortName||' ')

        this.room = this.connection.initJitsiConference(this.params.meet_id, this.confOptions);
        this.room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,this.onLocalConnectionQualityUpdate);
        this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
        this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
        this.room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoin);
        this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
        this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED , this.onTrackRemove);
        this.room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, this.onTrackUpdate);
        this.room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, this.onTextMessage);

        this.room.join();
        window.room = this.room
        this.getdevices()
    }

    /**
     * This function is called when the connection fail.
     */
    onConnectionFailed = () => {
        console.error('Connection Failed!');
    }

    onLocalConnectionQualityUpdate = (r) => {
        console.log(r);
        store.dispatch(updateStatus({
            connectionQuality: r.connectionQuality,
            jvbRTT:r.jvbRTT,
            bandwidth:r.bandwidth,
            packetLoss:r.packetLoss
        }))
    }

    // onRemoteConnectionQualityUpdate = (a, b, c, d) => {
    //     debugger;
    //     console.log('==========================')
    //     console.log(a,b,c,d);
    //     console.log('==========================')
    // }

    /**
     * This function is called when the connection fail.
     */
    onDeviceListChanged = (devices) => {
        console.info('current devices', devices);
        store.dispatch(updateStatus({
            devices
        }))
    }

    /**
     * This function is called when we disconnect.
     */
    disconnect = () => {
        console.log('disconnect!');
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess);
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed);
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect);
        // this.connection.removeEventListener(
        //     JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
        //     this.onLocalConnectionQualityUpdate
        // );
        // this.connection.removeEventListener(
        //     JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
        //     this.onRemoteConnectionQualityUpdate
        // );    
    }

    /**
     * This function is called when we leave the room
     */
    unload = async () => {
        this.localTracks['video']['track'] && this.localTracks['video']['track'].dispose();
        this.localTracks['audio']['track'] && this.localTracks['audio']['track'].dispose();

        this.room.off(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,this.onLocalConnectionQualityUpdate);
        this.room.off(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
        this.room.off(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
        this.room.off(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoin);
        this.room.off(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
        this.room.off(JitsiMeetJS.events.conference.TRACK_REMOVED , this.onTrackRemove);
        this.room.off(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, this.onTrackUpdate);
        this.room.off(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, this.onTextMessage);

        try {
            await this.room.leave();
        }catch(e){
            // console.log(e);
        }
        this.connection.disconnect();
    }
    shareScreen = () => {
        // debugger;
        this.isVideo = !this.isVideo;
        if (this.localTracks['video']['track']) {
            this.localTracks['video']['track'].dispose();
            this.localTracks['video']['track'] = null
            !this.isVideo && store.dispatch(updateStatus({
                videoMuted: true
            }))
        }
        if (this.isVideo && store.getState().meet.videoMuted) {
            return
        }


        JitsiMeetJS.createLocalTracks({
            devices: [this.isVideo ? 'video' : 'desktop']
        })
            .then(tracks => {
                this.onLocalTracks(tracks)
                this.localTracks['video']['track'].addEventListener(
                    JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                    () => {
                        this.localTracks['video']['track'] && this.localTracks['video']['track'].dispose();
                        this.localTracks['video']['track'] = null
                        this.isVideo = true
                    });
            })
            .catch(error => {
                this.isVideo = true
            });
    }
    initLocal = () => {
        JitsiMeetJS.createLocalTracks({devices: ['audio', 'video']})
            .then(this.onLocalTracks)
            .catch(error => {
                throw error;
            });

    }
    init = () => {
        // $(window).bind('beforeunload', this.unload);
        $(window).bind('unload', this.unload);
        store.dispatch(updateStatus({
            progress: '50%'
        }))
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
        const initOptions = {
            disableAudioLevels: false
        };

        JitsiMeetJS.init(initOptions);

        this.connection = new JitsiMeetJS.JitsiConnection(null, null, this.options);

        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect);
        
        // this.connection.addEventListener(
        //     JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
        //     this.onLocalConnectionQualityUpdate
        // );
        // this.connection.addEventListener(
        //     JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
        //     this.onRemoteConnectionQualityUpdate
        // );

        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            this.onDeviceListChanged);

        this.connection.connect();


        if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                const audioOutputDevices
                    = devices.filter(d => d.kind === 'audiooutput');

                if (audioOutputDevices.length > 1) {

                }
            });
        }
    }

    constructor(opts) {
        this.params = opts

        this.init()
    }
}

export default Meetin