import aesjs from "aes-js";
import { Base64 } from 'js-base64';
import JSEncrypt from 'jsencrypt';
import Axios from "axios";
import {message} from "antd";
const publicKey = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAubxg0wvEIq39N821UXN0iTJ4wMp2dpIFPN2iXn3bvei0+rlg5BUMMXSFi1ew2rX8118sLLO5WHuUFlOeCnAW/Y0ae06O7OJ5g5SeAlLC9EeGr1kTv1lYtzkGc3WGAek8hb9nhX1/Jp1ZUHB8WmeOqyRcBXNPsDBBPalg6p9jKuQAaa90K+ughmsCT7uIQ+IvkUKthi4+2bqRPE5toJuDaRP5rWzF/1RQsO4F0ND+qxe9Dlvw9moOjZkebyBOzGVUGNZCI8H70nTF0XHSc6JcGF0rOVUN+cxIh5OwN51xRO4f2p/eLsi+fAbPdg5Anii9Q3/jwzyCUs7XBx3iBQsYvwIDAQAB-----END PUBLIC KEY-----";

//加密
function encrypt(word, aesSecret){
    const key = Buffer.from(aesSecret);
    let aesEcb = new aesjs.ModeOfOperation.ecb(key);
    let text = `${Date.parse(new Date())}|${JSON.stringify(word)}`
    let textBytes = aesjs.utils.utf8.toBytes(text);
    if(textBytes.length%16>0){
        text += '\0'.repeat(16 - textBytes.length%16);
        textBytes  = aesjs.utils.utf8.toBytes(text);
    }
    let encryptedBytes = aesEcb.encrypt(textBytes);
    return Base64.fromUint8Array(encryptedBytes);
}

//解密
function decrypt(data, aesSecret){
    const key  = Buffer.from(aesSecret);
    let aesEcb = new aesjs.ModeOfOperation.ecb(key);
    let encryptedBytes = Base64.toUint8Array(data);
    let decryptedBytes = aesEcb.decrypt(encryptedBytes);
    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    let decryptStr = decryptedText.split('|')[1].replace(/\0/g, '')
    return JSON.parse(decryptStr);
}

const generateSecret = (n) => {
    let alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let res = '';
    for (let i = 0; i < n; i++) {
        res += alphabet[Math.floor(Math.random() * alphabet.length)];
    }
    return res;
};

const secretStoreKey = 'aesSecret';
let secretKey = null;
let secretKeyWaiters = [];

const querySecret = async ()=>{
    if(secretKey){
        return secretKey;
    }
    if(secretKey!==null){
        return new Promise((resolve)=>{secretKeyWaiters.push(resolve);});
    }
    secretKey = 0;
    const newSecret = generateSecret(16);
    let jse = new JSEncrypt();
    jse.setPublicKey(publicKey);
    const formData = new FormData();
    formData.append('AES_secret', jse.encrypt(newSecret));
    const response = await fetch(process.env.PUBLIC_URL + "/api/encrypt/report",{
        method: 'POST',
        body: formData,
    });
    const secret_json = await response.json();
    if (secret_json.ok) {
        secretKey = newSecret;
        secretKeyWaiters.forEach(waiter=>{waiter(secretKey);});
        return newSecret;
    }else{
        throw 'Generate Secret Error';
    }
}

const axiosInstance = Axios.create()


axiosInstance.interceptors.request.use(async (config) => {
    const { headers } = config
    const token = localStorage.getItem('token')
    const serverTime = localStorage.getItem('serverTime')

    if (token) {
        headers.Authorization = token
    }
    if (serverTime) {
        headers.timestamp = Date.now() + parseInt(serverTime ?? '0', 10)
    }
    return config
});

axiosInstance.interceptors.response.use(async (response)=>{
    if(!response.config.url.match(/\/api\/auth\/login$/) && response.status === 200){
        if(response.data.code === 302 || response.data.code === 403){
            goLoginPage();
            throw 'Need loigin';
        }
    }
    return response;
});


const API_DEBUG_KEY = '__API_DEBUG';

export function setDebug(enable){
    if(enable) {
        localStorage.setItem(API_DEBUG_KEY, 'TRUE');
    }else{
        localStorage.removeItem(API_DEBUG_KEY);
    }
}

export function isDebug(){
    return localStorage.getItem(API_DEBUG_KEY) === 'TRUE';
}

export async function post(url, data=null, options={}){
    const queryUrl = process.env.PUBLIC_URL + url;
    if(isDebug()){
        if(!options['headers']){
            options['headers'] = {};
        }
        options['headers']['DEBUG'] = 'TRUE';
        return (await axiosInstance.post(queryUrl, data, options)).data;
    }else{
        const secret = await querySecret();
        const response = await axiosInstance.post(queryUrl, data?{
            'params': encrypt(data, secret),
        }:null, options);
        return decrypt(response.data, secret);
    }
}

export async function put(url, data, options={}){
    const queryUrl = process.env.PUBLIC_URL + url;
    if(isDebug()){
        if(!options['headers']){
            options['headers'] = {};
        }
        options['headers']['DEBUG'] = 'TRUE';
        return (await axiosInstance.put(queryUrl, data, options)).data;
    }else{
        const secret = await querySecret();
        const response = await axiosInstance.put(queryUrl, data?{
            'params': encrypt(data, secret),
        }:null, options);
        return decrypt(response.data, secret);
    }
}

export async function get(url, options={}){
    const queryUrl = process.env.PUBLIC_URL + url;
    if(isDebug()){
        if(!options['headers']){
            options['headers'] = {};
        }
        options['headers']['DEBUG'] = 'TRUE';
        return (await axiosInstance.get(queryUrl, options)).data;
    }else{
        const secret = await querySecret();
        const response = await axiosInstance.get(queryUrl, options);
        return decrypt(response.data, secret);
    }
}


export async function download(url, filename){
    const queryUrl = process.env.PUBLIC_URL + url;
    const options = {}
    options['responseType'] = 'arraybuffer';
    const response = (await axiosInstance.get(queryUrl, options));
    // debugger;
    const blob = new Blob([response.data], {type:response.headers['content-type']})
    const link = document.createElement('a');
    link.download = filename
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
}


export async function upload(url, data, options={}){
    const queryUrl = process.env.PUBLIC_URL + url;
    if(!options['Content-Type']){
        options['Content-Type'] = 'multipart/form-data';
    }
    // debugger;
    // if(isDebug()){
        if(!options['headers']){
            options['headers'] = {};
        }
        // options['headers']['DEBUG'] = 'TRUE';
        return (await axiosInstance.post(queryUrl, data, options)).data;
    // }
    // const secret = await querySecret();
    // let response = await axiosInstance.post(queryUrl, data, options);
    // if(typeof(response.data) === 'object' && typeof(response.data.ok) !== 'undefined'){
    //     return response.data;
    // }
    // return decrypt(response.data, secret);
}

export function goLoginPage(){
    if(location.host.match('sgcc.com.cn')) {
        try{
            window.parent.location.href = '/login';
        }catch (e){
            location.href = '/login';
        }
    }else{
        if(!isLoginPage()){
            location.hash = '/auth/login?redirect=' + encodeURIComponent(location.href);
        }
    }
}

export function isLoginPage(){
    if(location.host.match('sgcc.com.cn')) {
        return false;
    }else{
        return location.hash.startsWith('#/auth/login');
    }
}

setInterval(()=>{
    get('/api/auth/me');
}, 5*60*1000);

export default {setDebug, post, get, download, put, upload, axiosInstance, goLoginPage, isLoginPage};