import React from 'react';
import { Popover, Input, Modal, message, Dropdown, Menu } from 'antd';
import classNames from 'classnames';

import icon_voice_black from '../../../images/meet/voice-black.png'
import icon_kick from '../../../images/meet/kick.png'
import icon_voice_black_disable from '../../../images/meet/voice-black-disable.png'
import icon_video_black from '../../../images/meet/video-black.png'
import icon_video_black_disable from '../../../images/meet/video-black-disable.png'
import './Member.scss'
import store from '../../../store'
import {updateStatus, updateUser, updateSetting, /* removeUser */} from '../../../actions/meet'
import isMutedByManager from '../../../lib/isMutedByManager'
import clone from '../../../lib/clone'
import user from "../../../reducers/user";
const { Search } = Input;
class Member extends React.Component {
  state = {
    search: ''
  }
  handleChange = (e) => {
    this.setState({
      search: e.target.value
    })
  }
  handleMuteAudio = (user_id, res)  => {
    if (!this.props.meet.isManager && !(this.props.user.userInfo.id == this.props.meet.meetInfo.room.room_compere_id)) {
      return message.warn('仅主持人可操作')
    }
    let whiteList = clone(this.props.meet.whiteList)
    //如果被静音
    if (this.props.meet.allMuted) {
      res ? (whiteList = whiteList.filter(item => item !== user_id)) : whiteList.push(user_id)
    } else {
      !res ? (whiteList = whiteList.filter(item => item !== user_id)) : whiteList.push(user_id)
    }
    this.props.saveSetting({
      whiteList
    })
    .then(() => {
      store.dispatch(updateStatus({
        whiteList
      }))
      this.props.handleMuteManage('audio', user_id, res)
    })
    
  }
  handleMuteAudioAll = () => {
    if (!this.props.meet.isManager && !(this.props.user.userInfo.id == this.props.meet.meetInfo.room.room_compere_id)) {
      return message.warn('仅主持人可操作')
    }
    this.props.saveSetting({
      allMuted: !this.props.meet.allMuted
    })
    .then(res => {
      this.props.handleMuteManage('audio', 'all', !this.props.meet.allMuted)
      store.dispatch(updateStatus({
        allMuted: !this.props.meet.allMuted,
        whiteList: [] 
      }))
    })
    
  }
  setAsManager = (user_id,userName) => {
    if (!this.props.meet.isManager) {
      return message.warn('仅主持人可操作')
    }
    this.props.saveSetting({
      co_chair: user_id
    })
    .then(res => {
      //更新在线的其他人的Redux
      this.props.handleSetAsManager(user_id,userName)
      //更新自己的状态
      store.dispatch(updateStatus({
        coChair: user_id
      }))
    })

  }
  
  //设置分会场主持人
  setPeople = (user_id) => {
    const { code } = this.props.meet.meetInfo.room
    if (!this.props.meet.isManager) {
      return message.warn('仅主持人可操作')
    }
    this.$api.post('/api/meeting/room/edit/' + code, {
      room_compere_id: user_id
    }).then(res=> {
      if (res.code === 200) {
        store.dispatch(updateSetting({
          room_compere_id: res.data.room_compere_id,
          type: 'room'
        }))
        message.success('设置成功');
      }
    })
  }


  kickOutMenu =(user_id)=>{
    return (
        <Menu>
            <Menu.Item key='adjustment' onClick={this.kickOut.bind(this, user_id)} >
                <span>仅本次清退</span>
            </Menu.Item>
            <Menu.Item key='redemption' onClick={this.kickOut.bind(this, user_id, true)}>
                <span>禁止再次入会</span>
            </Menu.Item>
        </Menu>
    )
}

  kickOut = (user_id, report_tag=false) => {
    if(!this.props.meet.isManager && !(this.props.user.userInfo.id == this.props.meet.meetInfo.room.room_compere_id)){
      return message.warn('仅主持人可操作')
    }
    let kick_out = clone (this.props.meet.kick_out)
    if (report_tag == true){
      if(kick_out.indexOf(user_id) < 0){
        kick_out.push(user_id)
      }
    }
    // console.log(kick_out,"踢人")
    let users = clone (this.props.meet.users)
    users.filter(item=>{
      if( item.id === user_id){
        item.is_kick = true
      }
    })
    // if (report_tag == true){
      // debugger;
      this.props.saveSetting({
        kick_out,
      }).then(res=>{
        this.props.kickOut(user_id,users)
        store.dispatch(updateStatus({
          kick_out
        }))
  
        store.dispatch(updateUser({
          id: user_id,
          userName: users.filter(item=>item.id === user_id)[0].userName,
          is_kick: true
      }))
  
        // setTimeout(()=>{
        //   store.dispatch(removeUser({id:user_id}));
        // },7000);
      })
    // }else{
    //   this.props.kickOut(user_id,users)
    //   store.dispatch(updateStatus({
    //     kick_out
    //   }))

    //   store.dispatch(updateUser({
    //     id: user_id,
    //     userName: users.filter(item=>item.id === user_id)[0].userName,
    //     is_kick: true
    //   }))
    // }
    
  }
  isMuted = (user_id) => {
    return isMutedByManager(user_id)
  }
  render() {
    let users = this.props.meet.users.filter(item=>!item.is_kick)
    if(users){
      users = users.filter(item=>item.userName)
    }
    if (this.state.search !== '') {
      users = users.filter(item => (item.userName && item.userName.indexOf(this.state.search) > -1) || (item.majorName && item.majorName.indexOf(this.state.search) > -1))
    }
    return (<Modal
    title=""
    centered
    visible={this.props.showManage}
    cancelText="取消"
    okText="复制"
    onOk={() => this.props.handleManage(false)}
    onCancel={() => this.props.handleManage(false)}
    footer={null}
    className='manage-modal'
  >
   
    <div className='manage'>
    <div className='manage-title'>成员列表（{users.length}人）</div>
      <div className='manage-search'>
        <Search
          onChange={this.handleChange}
          placeholder="搜索成员"
          onSearch={value => console.log(value)}
          style={{ width: 300 }}
        />
        </div>
      {/* {this.props.meet.meetInfo.allow_user_info && */}
      <div className='manage-list'>
      {this.props.meet.meetInfo.allow_user_info &&
        <div>
      {users.filter(item => item.role === 'responsibilityPerson').map(item => <div key={item.id} className='manage-member'>
        {/* <img alt='avatar' className='manage-member__icon' src={avatar_default}></img> */}
        {item.userName} （主持人）
        <div className='manage-member__right'>
          <div className='manage-member__right-btn'>
            <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
          </div>
          {/* <div className='manage-member__right-btn'>
          <img alt='button' src={false ? icon_video_black_disable : icon_video_black}></img>
          </div> */}
        </div>
      </div>)}
      <div className='manage-class'>
    设计单位：{this.props.meet.meetInfo.allow_user_info.designUnitShortName}（{users.filter(item => item.role === 'design' || item.role === 'designDirector').length}人）
      </div>
      {/* {users.filter(item => item.role === 'design' || item.role === 'designDirector').map(item => <div key={item.id} className={classNames('manage-member', {
        withcontrol: this.props.user.userInfo.id !== item.user_id
      })}> */}
      {users.filter(item => item.role === 'design' || item.role === 'designDirector').map(item => <div key={item.id} className={classNames('manage-member', {
      })}>
        {/* <img alt='avatar' className='manage-member__icon' src={avatar_default}></img> */}
        {item.userName} （{item.majorName}）
        <div className='manage-member__right'>

          {/* <div className='manage-member__right-btn'>
            <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
          </div> */}

          {/* <div className='manage-member__right-btn'>
          <img alt='button' src={false ? icon_video_black_disable : icon_video_black}></img>
          </div> */}
        </div>
        <div className='manage-member__right'>
          <div className='manage-member__right-btn'  style={{cursor:'pointer'}} onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
            <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
          </div>     

          {/* <div className='manage-member__right-tbtn' onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
            {this.isMuted(item.user_id) ? '解除静音' : '静音'}
          </div> */}
          {this.props.meet.isManager && (<div className='manage-member__right-tbtn' onClick={this.setAsManager.bind(this, item.user_id,item.userName)}>
            设为联席主持人
          </div>) 
          }
          {
            (!this.props.meet.meetInfo.room.is_master && this.props.meet.isManager) && (
              <div className='manage-member__right-tbtn' onClick={this.setPeople.bind(this, item.user_id,item.userName)}>
                设为分会场主持人
              </div>
            )
          }
          {/* <div className='manage-member__right-btn' onClick={this.kickOut.bind(this, item.user_id)}>
            <img className='manage-member__right-kick' alt='button' src={icon_kick}></img>
          </div> */}
          <Dropdown disabled={this.props.user.userInfo.id === item.user_id} overlay={this.kickOutMenu.bind(this, item.user_id)} autoFocus='true' trigger='click' >
            <div className='manage-member__right-btn'>
              <img className='manage-member__right-kick' alt='button' src={icon_kick}></img>
            </div>
          </Dropdown>
        </div>
      </div>)}
      <div className='manage-class'>
    评审单位：{this.props.meet.meetInfo.allow_user_info.specialistShortName}（{users.filter(item => item.role === 'specialist').length}人）
      </div>
      {/* {users.filter(item => item.role === 'specialist').map(item => <div key={item.id} className={classNames('manage-member', {
        withcontrol: this.props.user.userInfo.id !== item.user_id
      })}> */}
      {users.filter(item => item.role === 'specialist').map(item => <div key={item.id} className={classNames('manage-member', {
      })}>
        {/* <img alt='avatar' className='manage-member__icon' src={avatar_default}></img> */}
        {item.userName} （{item.majorName}）
        <div className='manage-member__right'>
          {/* <div className='manage-member__right-btn'>
            <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
          </div> */}
          {/* <div className='manage-member__right-btn'>
            <img alt='button' src={false ? icon_video_black_disable : icon_video_black}></img>
          </div> */}
        </div>
        <div className='manage-member__right'>
          <div className='manage-member__right-btn'  style={{cursor:'pointer'}} onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
              <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
          </div>
          
          {/* <div className='manage-member__right-tbtn' onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
            {this.isMuted(item.user_id) ? '解除静音' : '静音'}
          </div> */}
          { this.props.meet.isManager && (<div className='manage-member__right-tbtn' onClick={this.setAsManager.bind(this, item.user_id,item.userName)}>
            设为联席主持人
          </div>) }
          {
            (!this.props.meet.meetInfo.room.is_master && this.props.meet.isManager) && (
              <div className='manage-member__right-tbtn' onClick={this.setPeople.bind(this, item.user_id,item.userName)}>
                设为分会场主持人
              </div>
            )
          }
          <Dropdown disabled={this.props.user.userInfo.id === item.user_id} overlay={this.kickOutMenu.bind(this, item.user_id)} autoFocus='true' trigger='click' >
            <div className='manage-member__right-btn'>
              <img className='manage-member__right-kick' alt='button' src={icon_kick}></img>
            </div>
          </Dropdown>
          {/* <div className='manage-member__right-btn' onClick={this.kickOut.bind(this, item.user_id)}>
            <img className='manage-member__right-kick' alt='button' src={icon_kick}></img>
          </div> */}
        </div>
      </div>)}
      </div>
}

      <div className='manage-class'>
      {/* this.props.meet.users.filter(item=>!item.is_kick).filter(item => item.role === 'others').map(item => item.userName */}
    业主：（{users.filter(item => item.role === 'others').filter(item=>item.userName).length}人）
      </div>
      {users.filter(item => item.role === 'others').map(item =>item.userName && <div key={item.id} className={classNames('manage-member', {
      })}>
        {/* <img alt='avatar' className='manage-member__icon' src={avatar_default}></img> */}
        {item.userName} { item.majorName ? `（{item.majorName}）` : ''}
        <div className='manage-member__right'>
          {/* <div className='manage-member__right-btn'>
            <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
          </div> */}
          {/* <div className='manage-member__right-btn'>
            <img alt='button' src={false ? icon_video_black_disable : icon_video_black}></img>
          </div> */}
        </div>
        <div className='manage-member__right'>
          {
            this.memberManagerButton(item)
              // (this.props.user.userInfo.id !== item.user_id) && (
              //   <div className='manage-member__right-btn'  style={{cursor:'pointer'}} onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
              //     <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
              //   </div>
              // )
          }
          
          {/* <div className='manage-member__right-tbtn' onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
            {this.isMuted(item.user_id) ? '解除静音' : '静音'}
          </div> */}
          {/* {<div className='manage-member__right-tbtn' onClick={this.setAsManager.bind(this, item.user_id,item.userName)}>
            设为联席主持人
          </div>} */}
          {/* <div className='manage-member__right-btn' onClick={this.kickOut.bind(this, item.user_id)}>
            <img className='manage-member__right-kick' alt='button' src={icon_kick}></img>
          </div> */}

          
          <Dropdown disabled={this.props.user.userInfo.id === item.user_id} overlay={this.kickOutMenu.bind(this, item.user_id)} autoFocus='true' trigger='click' >
            <div className='manage-member__right-btn'>
              <img className='manage-member__right-kick' alt='button' src={icon_kick}></img>
            </div>
          </Dropdown>

        </div>
      </div>)}
      </div>

      <div className='manage-footer'>
        <div className='manage-footer__btn' onClick={this.handleMuteAudioAll}>{this.props.meet.allMuted ? '解除全体静音' : '全体静音'}</div>      
      </div>
    </div> 
  </Modal>)
  }

  memberManagerButton = (item)=>{
    return (this.props.user.userInfo.id !== item.user_id) && (
      <div className='manage-member__right-btn'  style={{cursor:'pointer'}} onClick={this.handleMuteAudio.bind(this, item.user_id, !this.isMuted(item.user_id))}>
        <img alt='button' src={this.isMuted(item.user_id) ? icon_voice_black_disable : icon_voice_black}></img>
      </div>
    )
  }
}

export default Member