import React from 'react'
import '../../../App.css'
import './Login.scss'
import { Link } from 'react-router-dom'
import { message, Form, Input, Button, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { updateStatus } from '../../../actions/user'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { sm2Decrypt } from '@bj-nsc/functions'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      code: {},
      username: '',
      password: '',
      captcha: '',
      checked: '',
      loginInfo: JSON.parse(localStorage.getItem('loginInfo')),
    }
  }

  componentDidMount() {
    if(!this.$api.isLoginPage()){
      this.$api.goLoginPage();
    }
    this.handleRefresh()
  }


  handleRefresh() {
    this.$api
      .get( '/api/auth/login')
      .then((response) => {
        let code = response.data.result
        this.setState({
          code,
        })
      })    
  }

  onFinish = (values) => {
    // console.log('Success:', values)
    if (this.state.checked) {
      localStorage.setItem('loginInfo', JSON.stringify(values))
    } else {
      localStorage.removeItem('loginInfo')
    }
    let dataObj = {
      username: this.state.username,
      password: this.state.password,
      captcha: this.state.captcha,
      captcha_random: this.state.code.captcha_random,
      csrfmiddlewaretoken: this.state.code.token,
    }
    this.$api
      .post('/api/auth/login', dataObj)
      .then(async (data) => {
        if (data.code === 200) {
          setTimeout(() => {
            // message.info('登录成功');
            const key = 'updatable'
            message.info({ content: '登录成功', key })
          }, 1000)
          let result = data.data.result
          result.name = await sm2Decrypt(result.name)

          localStorage.setItem('userInfo', JSON.stringify(result))
          this.props.dispatch(
            updateStatus({
              isAuthenticated: true,
              userInfo: result,
            })
          )
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.from
          ) {
            this.props.history.push(this.props.location.state.from.pathname)
          } else{
            let redirectMatch = this.props.location.search.match('redirect=([^&]*)');
            if(redirectMatch && redirectMatch[1]){
              location.href = decodeURIComponent(redirectMatch[1]);
            }else{
              this.props.history.push('/meeting/meeting_status')
            }
          }
        } else {
          this.handleRefresh()
          message.warn(data.msg)
        }
      })
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  handleChange = (changedValues, allValues) => {
    // console.log(changedValues, allValues)
    localStorage.removeItem('loginInfo')
    this.setState({
      loginInfo: '',
    })
    const { username, password, captcha, remember } = allValues
    this.setState({
      username,
      password,
      captcha,
      checked: remember,
    })
  }

  render() {
    return (
      <div id="box">
        <img
          id="circle"
          src={require('../../../images/auth/login/circle.svg').default}
          alt=""
        />
        <div id="cloud-review">
          <p>Cloud review</p>
        </div>

        <img
          id="cloud"
          src={require('../../../images/auth/login/cloud.png').default}
          alt=""
        />
        <img
          className="video"
          src={require('../../../images/auth/login/video.svg').default}
          alt=""
        />
        <img
          id="voice"
          src={require('../../../images/auth/login/voice.png').default}
          alt=""
        />

        <div id="login-box">
          <div id="login-form">
            <Form
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              onValuesChange={this.handleChange}
              initialValues={{
                username: this.state.loginInfo && this.state.loginInfo.username,
                password: this.state.loginInfo && this.state.loginInfo.password,
                remember: this.state.loginInfo && this.state.loginInfo.remember,
              }}
            >
              <p id="title">账号密码登录</p>

              <div className="label-box">
                <img
                  id="user-icon"
                  src={require('../../../images/auth/login/user.png').default}
                  alt=""
                />
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: '账号为空',
                    },
                  ]}
                >
                  <Input placeholder="请输入账号或手机号" />
                </Form.Item>
              </div>

              <div className="label-box">
                <img
                  id="password-icon"
                  src={
                    require('../../../images/auth/login/password.png').default
                  }
                  alt=""
                />
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: '密码为空',
                    },
                  ]}
                >
                  <Input.Password placeholder="请输入密码" />
                </Form.Item>
              </div>

              <div className={classNames({ 'label-box': true, captcha: true })}>
                <Form.Item
                  name="captcha"
                  rules={[
                    {
                      required: true,
                      message: '验证码为空',
                    },
                  ]}
                >
                  <Input className="captcha" placeholder="请输入验证码" />
                </Form.Item>
                <img
                  onClick={this.handleRefresh.bind(this)}
                  id="identify-code"
                  src={this.state.code.captcha_image}
                  alt="验证码"
                />
              </div>

              <div className="remember">
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>记住登录密码</Checkbox>
                </Form.Item>
              </div>

              <Form.Item className="submit">
                <Button type="primary" htmlType="submit">
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div id="login-image">
            <div id="login-image-text">
              <p>云评审</p>
              <p>Cloud review</p>
            </div>
            <img
              src={require('../../../images/auth/login/group.png').default}
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})
export default connect(mapStateToProps)(withRouter(Login))
