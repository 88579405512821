import React from "react";
import './Me.scss'
import HeaderComponent from "../../Meeting/HeaderComponent/HeaderComponent";

class Me extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }
    render() {
        return (
            <div>
                <HeaderComponent></HeaderComponent>
            </div>
        )
    }


}

export default Me