import React from "react";
import './MemListComponent.scss'
import user_icon from '../../../images/meeting/create/user.png'
class MemListComponent extends React.Component{
    constructor(props) {
        super(props);
    }
    handleChange=()=>{
        if(this.props.onChange){
            this.props.onChange(!this.props.checked);
        }
    }
    render() {
        return <dd key={this.props.item.id}>
            <div id="member-person">
                <a>
                    <img src={user_icon} alt=""/>
                    <span>{this.props.item.userName}（{this.props.item.majorName}）</span>
                </a>
            </div>
            <label className="default-checkbox">
                <input checked={this.props.checked} onChange={this.handleChange} type="checkbox"/>
            </label>
        </dd>
    }
}
export default MemListComponent