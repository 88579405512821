/**
 * 广播通信模块
 * @module {es6}
 * @mixin listener 
 */
const listener = (function(){
	const dispatchList = {};	
	
	const fireTaskList = [];
	let fireTaskTimer;
	
	function runFireTaskList(){
		if (fireTaskList.length == 0) {
			return;
		}
		clearTimeout(fireTaskTimer);
		const curFireTask = fireTaskList.splice(0, 1)[0];
		try{
			curFireTask['func'].apply(curFireTask['func'], [].concat(curFireTask['data']));
		}catch(exp){
			console.log('[error][listener]: One of ' + curFireTask + '-' + curFireTask + ' function execute error.');
		}
		
		
		fireTaskTimer = setTimeout(runFireTaskList, 25);
	}
	
	const dispatch = {
		/**
		 * 注册频道和事件
		 * @function register
		 * @param {string} sChannel 频道名
		 * @param {string} sEventType 事件名
		 * @param {function} fCallBack 回调函数
		 * @example
		 * listener.register('call', 'alert', function(msg){
		 *		alert(msg)
		 * })
		 */
		register: function(sChannel, sEventType, fCallBack){
			dispatchList[sChannel] = dispatchList[sChannel] || {};
			dispatchList[sChannel][sEventType] = dispatchList[sChannel][sEventType] || [];
			dispatchList[sChannel][sEventType].push(fCallBack);
		},
		/**
		 * 广播事件
		 * @function fire
		 * @param {string} sChannel 频道名
		 * @param {string} sEventType 事件名
		 * @param {any} oData 回调函数的参数
		 * @example
		 * listener.fire('call', 'alert', 'hi')
		 */
		fire: function(sChannel, sEventType, oData){
			let funcArray;
			let i, len;
			if (dispatchList[sChannel] && dispatchList[sChannel][sEventType] && dispatchList[sChannel][sEventType].length > 0) {
				funcArray = dispatchList[sChannel][sEventType];
				funcArray.data_cache = oData;
				for (i = 0, len = funcArray.length; i < len; i++) {
					fireTaskList.push({
						channel: sChannel,
						evt:sEventType,
						func: funcArray[i],
						data: oData
					});
				}
				runFireTaskList();
			}
		},
		remove: function(sChannel, sEventType, fCallBack){
			if (dispatchList[sChannel]) {
				if (dispatchList[sChannel][sEventType]) {
					for (let i = 0, len = dispatchList[sChannel][sEventType].length; i < len; i++) {
						if (dispatchList[sChannel][sEventType][i] === fCallBack) {
							dispatchList[sChannel][sEventType].splice(i, 1);
							break;
						}
					}
				}
			}
		},
		list: function(){
			return dispatchList;
		},
		cache: function(sChannel, sEventType){

			if (dispatchList[sChannel] && dispatchList[sChannel][sEventType]) {
				return dispatchList[sChannel][sEventType].data_cache;
			}

		}
	};
	return dispatch;
})();
export default listener 