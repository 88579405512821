import React from 'react';
import { Popover, Input, Modal, message, Button, Form } from 'antd';
import classNames from 'classnames'
import { connect } from 'react-redux'
import Meetin from '../../lib/meetin'
import Chat from './components/Chat'
import Member from './components/Member'
import Set from './components/Set'
import Timer from './components/Timer'
import Loading from './components/Loading'
import './Meet.scss'
import avatar_default from '../../images/meet/avatar.png'
import icon_logo from '../../images/meet/logo.png'
import icon_logo3 from '../../images/meet/logo3.png'
import icon_logo2 from '../../images/meet/logo2.png'
import icon_arrow from '../../images/meet/arrow.png'
import icon_clock from '../../images/meet/clock.png'
import icon_select from '../../images/meet/select.png'
import icon_logo_m from '../../images/meet/logo-m@2x.png'
import icon_arrow_fold from '../../images/meet/arrow-fold.png'
import icon_arrow_unfold from '../../images/meet/arrow-unfold.png'
import icon_voice_black from '../../images/meet/voice-black.png'
import icon_video_black from '../../images/meet/video-black.png'
import icon_status0 from '../../images/meet/status0.png'
import icon_status1 from '../../images/meet/status1.png'
import icon_status2 from '../../images/meet/status2.png'
import icon_status3 from '../../images/meet/status3.png'
import locked_loading from '../../images/meet/locked-loading.png'
import meet_list from '../../images/meet/meet-list.png'
import wait from '../../images/meet/沙漏.svg'
import conferenceData from '../../mock/conference'
import { updateStatus, resetMeeting, updateSetting } from '../../actions/meet'
import formatDate from '../../lib/formatDate'
import {pushItemToUsers, decodeUserItem} from '../../reducers/meet'
import {
  withRouter
} from "react-router-dom";
import ClipboardJS from 'clipboard'
import listener from "../../lib/listener";
import Register from "./components/Register";
import AddFile from '../Meeting/AddFile/AddFile'
import Face_match from "../Auth/FaceMatch/Face_match";
import { sm2Decrypt } from '@bj-nsc/functions'
import isMutedByManager from '../../lib/isMutedByManager'
import clone from '../../lib/clone'
class Meet extends React.Component {
  state = {
    meetInfo: null,
    showChat: false,
    showRegister:false,
    fold: false,
    fold_footer: false,
    // conHeight: document.body.clientHeight - 475,
    listHeight: document.body.clientHeight - 225,
    devices: [],
    showInvite: false,
    showManage: false,
    showSet: false,
    errType: 'NONE',
    needPassword: false,
    needFaceMatch: false,
    showVideoPopover: false,
    showVoicePopover:false,
    showShareScreenPopover:false,
    showMeetPopover: false,
    visible:false,
    showFile:false,
    roomData: [], //房间数据
    roomsArr: [],
    roomAddModalShow: false,
    roomAddName: '',
  }
  errTypes = {
    'NONE': '',
    'LOCKED': '会议已锁定，无法进入！',
    'CANCELED': '会议已取消',
    'FINISHED': '会议已结束',
    'NOMEET': '会议不存在'
  }
  constructor(props) {
    super(props);
    this.videoDom = React.createRef();
    this.mainVideo = React.createRef();
    this.fullScreenDom = React.createRef()
    this.roomEditDom = React.createRef();
    this.meetKeeperInterval = null;
  }

  jitsApi = null
  meetin = null
  btnCopy = null
  initMeet = (id) => {
    this.meetin = new Meetin({
      videoDom: this.videoDom.current,
      mainVideo: this.mainVideo.current,
      meet_id: id.replace(/\-/g, ''),
      storeDispatch: this.props.dispatch
    })
  }
  async parseData(conferenceData) {
    if(conferenceData.allow_user_info){
      conferenceData.allow_user_info.responsibilityPerson = await sm2Decrypt(conferenceData.allow_user_info.responsibilityPerson)
      conferenceData.allow_user_info.designDirectorName = await sm2Decrypt(conferenceData.allow_user_info.designDirectorName)
    }
    this.setState({
      meetInfo: conferenceData
    })
    if(!conferenceData.allow_user_info){
      // console.log(this.props.meet.users,'parseData')
      // let kick_out = conferenceData.setting.kick_out
      // if(kick_out){
      //   users.forEach(item => {
      //     if (conferenceData.setting.kick_out.indexOf(item.id) > 0 ) {
      //       item.is_kick = true
      //     }
      //   })
      // }

      this.props.dispatch(updateStatus({
        managerId: [conferenceData.compere_id],
        //To-Do
        isManager: this.props.user.userInfo.id === conferenceData.compere_id,
        meetInfo: conferenceData,
        allMuted: conferenceData.setting.allMuted,
        whiteList: conferenceData.setting.whiteList || [],
        lock: conferenceData.setting.lock,
        kick_out:conferenceData.setting.kick_out || [],
        watermark: conferenceData.setting.force_watermark === 1
      }))
      return
    }
    let users = []
    pushItemToUsers(users, await decodeUserItem({
      id: conferenceData.allow_user_info.designDirectorId,
      userName:conferenceData.allow_user_info.designDirectorName && await sm2Decrypt(conferenceData.allow_user_info.designDirectorName),
      majorName: '设总'
    }), 'designDirector')
    pushItemToUsers(users,await decodeUserItem({
      id: conferenceData.allow_user_info.responsibilityPersonId,
      userName:conferenceData.allow_user_info.responsibilityPerson && await sm2Decrypt(conferenceData.allow_user_info.responsibilityPerson)
    }), 'responsibilityPerson')
    // conferenceData.allow_user_info.designMemberVo.forEach(item => {
    //   pushItemToUsers(users, item, 'design')
    // })
    for(let i in conferenceData.allow_user_info.designMemberVo){
        let item = await decodeUserItem(conferenceData.allow_user_info.designMemberVo[i]);
        pushItemToUsers(users, item, 'design');
    }
    // conferenceData.allow_user_info.specialistMemberVo.forEach(item => {
    //   pushItemToUsers(users, item, 'specialist')
    // })
    for(let i in conferenceData.allow_user_info.specialistMemberVo){
      let item = await decodeUserItem(conferenceData.allow_user_info.specialistMemberVo[i]);
      pushItemToUsers(users, item, 'specialist');
    }
    pushItemToUsers(users, await decodeUserItem({
      id: conferenceData.allow_user_info.buildPersonId,
      userName:conferenceData.allow_user_info.buildPerson && await sm2Decrypt(conferenceData.allow_user_info.buildPerson),
      _company: conferenceData.allow_user_info.buildUnitName,
      _companyShort: conferenceData.allow_user_info.buildUnitShortName,
    }), 'others');
    let kick_out = conferenceData.setting.kick_out
    if(kick_out){
      users.forEach(item => {
        if (conferenceData.setting.kick_out.indexOf(item.id) > 0 ) {
          item.is_kick = true
        }
      })
    }
    // console.log(users,conferenceData,"kic")
    this.props.dispatch(updateStatus({
      users: users,
      managerId: [conferenceData.allow_user_info.responsibilityPersonId, conferenceData.allow_user_info.designDirectorId],
      //To-Do
      isManager: this.props.user.userInfo.id === conferenceData.allow_user_info.responsibilityPersonId || this.props.user.userInfo.id ===(conferenceData.setting.co_chair || conferenceData.allow_user_info.designDirectorId),
      meetInfo: conferenceData,
      allMuted: conferenceData.setting.allMuted,
      whiteList: conferenceData.setting.whiteList || [],
      lock: conferenceData.setting.lock,
      coChair: conferenceData.setting.co_chair || conferenceData.allow_user_info.designDirectorId,
      kick_out:conferenceData.setting.kick_out || [],
      watermark: conferenceData.setting.force_watermark === 1
    }))
  }
  getData = (id) => {
    return this.$api.get('/api/room/query/' + id,)
      .then(response => {
        if (response.code === 200) {
          this.setState({
            roomData: response.data.rooms,
            roomsArr: response.data
          })
          let cData = response.data.meeting
          cData.rooms = response.data.rooms || []
          cData.room=response.data.room || []
          let setting = response.data.setting
          if (setting.external_status) {
            setting = Object.assign(setting, JSON.parse(response.data.setting.external_status))
            delete setting.external_status
          }
          if (response.data.room.status && response.data.room.status !== 'PENDING' && response.data.room.status !== 'MEETING') {
            this.setState({
              errType: response.data.room.status
            })
            return Promise.reject()
          }
          if (setting.lock) {
            const isManager = this.props.user.userInfo.id === cData.compere_id ||
                this.props.user.userInfo.id === cData.allow_user_info?.responsibilityPersonId ||
                this.props.user.userInfo.id === cData.allow_user_info?.designDirectorId;
            if (!isManager) {
              this.setState({
                errType: 'LOCKED'
              })
              return Promise.reject()
            }
          }
          if(setting.kick_out && setting.kick_out.includes(this.props.user.userInfo.id)){
            message.warn("您已被主持人移除会议，无法再次进入！")
            this.props.history.push("/meeting/meeting_status")
            return Promise.reject()
          }
          this.setState({
            needPassword: setting.need_password,
            needFaceMatch: setting.face_recognition
          })
          //获取密码处理切换会场不需要从新输入密码
          let passWord = this.getPassword(id)
          // 需要密码选择扬声器
          if (setting.need_password && !passWord) {
            this.setState({
              errType: 'NEED_PASSWORD'
            })
            return Promise.reject()
          }
          // 需要人脸识别
          if (setting.face_recognition) {
            this.setState({
              errType: 'NEED_FACE'
            })
            return Promise.reject()
          }
          cData.setting = setting
          this.joinConf(passWord)
              .then(res => {
                if (res.code === 200) {
                  this.parseData(cData)
                  this.init(res.data.room.token || id)
                  this.$api.get('/api/meeting/heartbeat/'+id);
                } else if (res.code === 410) {
                  message.error(res.msg)
                  this.props.history.replace("/meeting/meeting_status?tab=1")
                }
              })
          // debugger;
          // return this.handlePassword(id)
          // return Promise.resolve(res.data.data.room.token)
        } else if (response.code === 404) {
          this.setState({
            errType: 'NOMEET'
          })
          //TODO #39
          setTimeout(() => {
            this.props.history.push("/meeting/meeting_status")
          }, 3000)
        }
        return Promise.reject()
      })
  }
  joinConf = (password) => {
    const { id } = this.props.match.params
    return this.$api.post( '/api/meeting/join/' + id, {password})
  }
  saveSetting = (setting,e) => {
    // e.persist()
    const _setting  = this.props.meet.meetInfo.setting
    const r = Object.assign(_setting, setting)
    return this.$api.put( '/api/room/external/status/' + this.props.meet.meetInfo.setting.id, r)
    .then(res => {
      // TODO: many put data struct error
      // console.log(res)
    })
    .catch(e => {
      
    })

  }
  componentDidMount() {
    const { id } = this.props.match.params
    this.getData(id)
      .then(token => {
        // debugger;
        // console.log(token, 111)
        this.init(token)
      })
      .catch(e => {})
    listener.register('meet','hidechat',()=>{
      this.setState({
        showChat: false
      })
    })
    let mediaInitTimer = setInterval(()=>{
      if(this.props.meet.progress==='95%'){
        clearInterval(mediaInitTimer);
        mediaInitTimer = null;
        setTimeout(()=>{
          this.initMediaStatus();
        }, 2000);
      }
    }, 1000);
    this.meetKeeperInterval = setInterval(()=>{
      this.$api.get('/api/meeting/heartbeat/'+id);
    }, 30*1000);
    // this.$api.get('/api/meeting/heartbeat/'+id);
  }

  initMediaStatus = ()=>{
    if(this.props.location.search.search('init-video=1')!=-1){
      // console.log('video===================');
      this.handleVideo();
    }
    if(this.props.location.search.search('init-audio=1')!=-1){
      // console.log('audio===================');
      this.handleVoice();
    }
  }

  init = (token) => {
    try{
      this.initMeet(token)
    }catch(e){
      message.error("会议初始化失败！请检查浏览器HTTP设置");
      console.log(e);
    }
    this.initCopyBoard()
  }
  initCopyBoard = () => {
     this.btnCopy = new ClipboardJS('.invite-footer__btn');
     this.btnCopy.on('success', function(e) {
      message.success('已复制到剪切板');
  
      e.clearSelection();
  });
  }
  componentWillUnmount() {
    this.props.dispatch(resetMeeting())
    this.meetin && this.meetin.unload()
    this.btnCopy && this.btnCopy.destroy()
    if(this.meetKeeperInterval){
      clearInterval(this.meetKeeperInterval);
      this.meetKeeperInterval = null;
    }
  }
  handleshowShareScreenPopover = () => {
    this.setState({
      showShareScreenPopover: !this.state.showShareScreenPopover
    })
  }
  handleShowVideoPopover = () => {
    if (this.props.meet.devices.filter(item => item.kind === 'videoinput').length  === 0) {
      message.warn('没有可用设备')
      return
    }
    this.setState({
      showVideoPopover: !this.state.showVideoPopover
    })
  }
  handleShowVoicePopover=()=>{
   if(this.props.meet.devices.filter(item => item.kind === 'audioinput').length === 0){
     message.warn('没有可用设备')
     return
   }
    this.setState({
      showVoicePopover: !this.state.showVoicePopover
    })
   }
  handleInvite = (res) => {
    this.setState({
      showInvite: res !== null ? res : !this.state.showInvite
    })
  }

  //投屏
  handleScreen = () => {
    this.meetin.handleScreen()
  }

  handleLock = () => {
    if (!this.props.meet.isManager && !(this.props.user.userInfo.id == this.props.meet.meetInfo.room.room_compere_id)) {
      message.warn('仅主持人可操作！')
      return
    }
    const res = !this.props.meet.lock
    this.saveSetting({
      lock: res
    })
    .then(() => {
      this.props.dispatch(updateStatus({
        lock: res
      }))
      this.meetin.lockConference(res)
    })
  }
  handleManage = (res) => {
    this.setState({
      showManage: res !== null ? res : !this.state.showManage
    })
  }
  handleRegister =(e)=>{
    this.setState({
      showRegister:!this.state.showRegister
    })
  }
  handleSet = (res) => {
    
    this.setState({
      showSet: res !== null ? res : !this.state.showSet
    })
  }
  handleFoldAll = () => {
    const r = this.state.fold && this.state.fold_footer ? false : true
    this.setState({
      fold: r,
      fold_footer: r,
      // conHeight: !r ? document.body.clientHeight - 475 : document.body.clientHeight - 200
    })
  }
  handleFold = () => {
    this.setState({
      fold: !this.state.fold,
      // conHeight: this.state.fold ? document.body.clientHeight - 475 : document.body.clientHeight - 200
    })
  }
  handleFoldFooter = () => {
    this.setState({
      fold_footer: !this.state.fold_footer,
      // conHeight: this.state.fold_footer ? document.body.clientHeight - 475 : document.body.clientHeight - 200
    })
  }
  handleVoice = () => {
    this.meetin.muteLocalAudio()
  }
  handleVideoToMain = (user_id) => {
    this.meetin.setVideoToMain(user_id)
  }
  handleMuteManage = (type, to, res,e) => {
    // this.props.handleMuteManage('audio', 'all', !this.props.meet.allMuted)
    // e.persist()
    if (type === 'audio') {
      this.meetin.handleMuteAudio(to, res)
    }
  }
  handleSetAsManager = (user_id,userName) => {
    this.meetin.handleSetAsManager(user_id,userName)
  }
  handleBrowseFile =()=>{
    const { code } = this.props.meet.meetInfo.room
    this.$api.post('/api/meeting/room/operation/' + code, {'active': 'read_file'})
    .then(res => {
        if (res.ok == 1) {
          this.setState({showFile:true})
        }else if (res.code === 410) {
          message.error(res.msg)
        }
      })
    // e.preventDefault()
    // var allow_access_users = Array()
    // this.props.meet.users.filter(item=>!item.is_kick).filter(item => ['designDirector', 'responsibilityPerson', 'design', 'specialist'].indexOf(item.role) != -1).map(
    //   item => allow_access_users.push(item.id))
      //  console.log(this.props.meet.users)
    // this.props.user
    // console.log(this.props.meet)
    // if (allow_access_users.indexOf(this.props.user.userInfo.id) != 1){
    //   message.error("无访问权限！")
    // }else{
    //   this.setState({showFile:true})
    // }
  }
  handleVoiceSelect = (e) => {
    // e.stopPropagation()
    // console.log(e)
    // this.meetin.getdevices()
    //   .then(res => {
    //     console.log(res, '000')
    //     this.setState({
    //       devices: res
    //     })
    //   })
  }
  handleVideo = () => {
    this.meetin.muteLocalVideo()
  }
  handleHangupManager = () => {
    // console.log(this.props.user.isManager)
    this.setState({
      visible: true,
    });
  }
  handleHangup = () => {
    this.$api.get(`/api/meeting/leave/${this.state.meetInfo.setting.id}/${this.props.user.userInfo.id}`)
    this.props.dispatch(resetMeeting())
    this.props.history.push('/meeting/meeting_status')
  }
  handleOk = e => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };
  handleFinish = () => {
    this.$api.get(`/api/meeting/close/${this.state.meetInfo.setting.id}`)
      .then(res => {
        if (res.ok == 1) {
          message.success('会议结束！')
          this.props.dispatch(resetMeeting())
          this.props.history.push('/meeting/meeting_status?tab=1')
        }
      })
  }
  handleChat = (e) => {
    e.stopPropagation()
    this.setState({
      showChat: !this.state.showChat
    })
    this.props.dispatch(updateStatus({
      unread: false
  }))
  }
  handleCloseChat = () => {
    this.setState({
      showChat: false
    })
  }
  handleShareScreen = () => {
    if(!this.props.meet.allowAllToShareScreen && !this.props.user.isManager) {
      return message.warn('当前仅主持人可共享屏幕')
    }
    this.meetin.shareScreen()
  }
  handleSetAudioOutputDevice = (id) => {
    this.meetin.setAudioOutputDevice(id)
  }
  handleSetMic = (id) => {
    this.meetin.setMic(id)
  }
  handleSetCamera = (id) => {
    this.meetin.setCamera(id);
  }
  handleSendMsg = (msg, to) => {
    this.meetin.sendTextMessage(msg, to)
  }
  handleToDocs = () => {

  }
  handleKickOut =(user_id,users,e) => {
    // debugger;
    // e.persist()
    this.meetin.kickout(user_id,users)
  }
  getStats = () => {
    let statsImg = icon_status0
    if (this.props.meet.connectionQuality > 0) {
      statsImg = icon_status1
    }
    if (this.props.meet.connectionQuality > 60) {
      statsImg = icon_status2
    }
    if (this.props.meet.connectionQuality > 90) {
      statsImg = icon_status3
    }
    return statsImg
  }
  handlePassword = () => {
    this.joinConf(this.password)
      .then(res => {
        if (res.code === 200) {
          if (this.state.needFaceMatch) {
            this.setState({
              errType: 'NEED_FACE'
            })
          } else {
            this.setState({
              errType: 'CONNECTING'
            })
            this.setPassword(res.data) //记录密码
            let cData = res.data.meeting
            cData.rooms = res.data.rooms || []
            cData.room = res.data.room || []
            let setting = res.data.setting
            if (setting.external_status) {
              setting = Object.assign(setting, JSON.parse(res.data.setting.external_status))
              delete setting.external_status
            }
            cData.setting = setting
            this.parseData(cData)
            this.init(res.data.room.token)
          }
        } else if (res.code === 410) {
          message.error(res.msg)
        }
      })
  }
  handlePasswordInput = (e) => {
    this.password = e.target.value
  }
  handleBodyClick = (e) =>{
    listener.fire('meet','hidechat')
  }
  handleShareManager = (e) => {
    const allowAll = e.currentTarget.children[1].innerHTML === '全体成员可共享'
    this.props.dispatch(updateStatus({
      allowAllToShareScreen: allowAll
    }))
    this.meetin.setScreenSharing(allowAll)
    this.setState({
      showShareScreenPopover:false,
    })
  }
  handleUploadFile = (val) => {
    let res=(val === "仅主持人可上传")?0:1
    this.saveSetting({
      allow_member_upload: res
    })
    .then(() => {
      this.props.dispatch(updateStatus({
        allow_member_upload: res
      }))
      this.meetin.allowMemberUpload(res)
    })
  }
  faceToMeet = () => {
    this.joinConf()
    .then(res => {
      // console.log(res, 'ppp')
      if (res.code === 200) {
        this.setState({
          errType: 'CONNECTING'
        })
        let cData = res.data.meeting
        cData.rooms = res.data.rooms || []
        cData.room = res.data.room || []
        let setting = res.data.setting
        if (setting.external_status) {
          setting = Object.assign(setting, JSON.parse(res.data.setting.external_status))
          delete setting.external_status
        }
        cData.setting = setting
        this.parseData(cData)
        
        this.init(res.data.room.token)
      } else if (res.code === 410) {
        message.error(res.msg)
      }
    })
  }
  handleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      this.fullScreenDom.current && this.fullScreenDom.current.requestFullscreen()
    }
    
  }

  //存储密码
  setPassword(data) {
      let passWord = {
        roos: data.meeting.rooms,
        pwd: data.setting.password
      }
      localStorage.setItem('password',JSON.stringify(passWord))
  }

  //添加分会场增加缓存
  addSetRoom(data) {
    // let roomData = JSON.parse(localStorage.getItem('password'))
    // roomData.roos = [...roomData.roos, data]
    // localStorage.setItem('password',JSON.stringify(roomData))
  }
  //缓存密码验证
  getPassword(code) {
    let passWord = JSON.parse(localStorage.getItem('password'))
    let roos = passWord ? passWord.roos.filter(item => {return item.code == code}) : ''
    return roos.length > 0 ? passWord.pwd : ''
  }

  //会议列表弹窗
  handleShowMeetingPopover = (e) => {
    e.stopPropagation()
    let id = this.state.roomsArr.setting.id
    this.$api.get('/api/meeting/'+id).then(res=>{
      this.setState({roomData: res.data.rooms});
    });
    this.setState({
      showMeetPopover: !this.state.showMeetPopover
    })
  }

  //修改分会场
  editMeet = () => {
    return this.$api.post('/api/meeting/room/edit/' + this.state.roomEditCode, {name:this.state.roomEditName}).then(res => {
      if (res.code === 200) {
        let roomData = this.state.roomData;
        for(let i=0;i<roomData.length;i++){
          if(roomData[i].code == this.state.roomEditCode){
            roomData[i].name = this.state.roomEditName;
            break;
          }
        }
        this.setState({
          roomData
        })
        this.addSetRoom(res.data)
        message.success('修改成功');
        this.setState({roomEditModalShow: false});
      }
    })
  }

  //添加分会场
  addMeet = () => {
    let id = this.state.roomsArr.setting.id
    return this.$api.post('/api/meeting/room/create/' + id, {name:this.state.roomAddName}).then(res => {
      if (res.code === 200) {
        this.setState({
          roomData: [...this.state.roomData, res.data]
        })
        this.addSetRoom(res.data)
        message.success('添加成功');
        this.setState({roomAddModalShow: false});
      }
    })
  }

  //处理是否分会场
  handleIsMaster = () => {
    console.log('handleIsMaster')
  }

  //处理是否分会场主持人
  handleHandupClick = (user, muted) => {
    if (user.user_id === this.props.user.userInfo.id) {
      this.meetin.handleCancelHandUp()
    } else {
      // 主持人可点击小手快速解除静音
      if (!this.props.meet.isManager) {
        return message.warn('仅主持人可操作')
      }
      let whiteList = clone(this.props.meet.whiteList)
      //如果被静音
      if (this.props.meet.allMuted) {
        muted ? (whiteList = whiteList.filter(item => item !== user.user_id)) : whiteList.push(user.user_id)
      } else {
        !muted ? (whiteList = whiteList.filter(item => item !== user.user_id)) : whiteList.push(user.user_id)
      }
      this.saveSetting({
        whiteList
      })
      .then(() => {
        this.props.dispatch(updateStatus({
          whiteList
        }))
        this.handleMuteManage('audio', user.user_id, muted)
      })
    }
  }


  networkStatus(){
    if (this.props.meet.connectionQuality > 90) {
      return '网络状态良好';
    }else if (this.props.meet.connectionQuality > 60) {
      return '网络状态一般';
    }else{
      return '网络状态较差';
    } 
    
  }

  networkStatusDetail(){
    return <div>
      <table className="networkStatusDetail">
        <thead>
          <tr>
            <td>
              <a>点击检查网络</a>
            </td>
            <td>
              &nbsp;
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>延迟</td>
            <td>{this.props.meet.jvbRTT}ms</td>
          </tr>
          <tr>
            <td>连接质量</td>
            <td>{this.props.meet.connectionQuality}</td>
          </tr>
          <tr>
            <td>丢包率</td>
            <td><span className="direction">↑</span>{this.props.meet.packetLoss.upload}%</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td><span className="direction">↓</span>{this.props.meet.packetLoss.download}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  render() {
    const { roomData } = this.state
    const notInFrame = location.href.indexOf('inframe=0') > -1
    if (!this.props.meet.roomInited) {
      return <div className='meet-loading'>
        {/* {this.state.errType === 'NONE' || this.state.errType === 'CONNECTING' ? '正在接入会议...' : this.errTypes[this.state.errType]} */}
        {/* {this.state.errType === 'CONNECTING' && <Loading progress={this.props.meet.progress}></Loading>}
        {(this.state.errType === 'LOCKED' || this.state.errType === 'NOMEET') && <div>{this.state.errType === 'LOCKED' && <img src={locked_loading} alt="" />}<div>{this.errTypes[this.state.errType]}</div><Button onClick={()=>this.props.history.push("/meeting/meeting_status")}>返回</Button></div>} */}
        {this.state.errType === 'NONE' || this.state.errType === 'CONNECTING' ? <Loading progress={this.props.meet.progress}></Loading> : <div>{this.errTypes[this.state.errType]==="会议已锁定，无法进入！" && <img src={locked_loading} alt="" />}<div>{this.errTypes[this.state.errType]}</div>{this.errTypes[this.state.errType]==="会议已锁定，无法进入！" && <Button onClick={()=>this.props.history.push("/meeting/meeting_status")}>返回</Button>}</div>}
        {this.state.errType === 'NEED_PASSWORD' && <div>
          <Input.Password onChange={this.handlePasswordInput} autoComplete="off" name="meeting-pwd" placeholder='请输入密码' style={{width: '500px'}}></Input.Password>
          <Button onClick={this.handlePassword} type="primary" style={{background: '#1890ff',margin:10}}>确定</Button>
          <Button onClick={()=>this.props.history.push("/meeting/meeting_status")}>返回</Button>
        </div>}
        {this.state.errType === 'NEED_FACE' && <Face_match backMeet={this.faceToMeet}></Face_match>
        // <Button onClick={()=>this.props.history.push(`/auth/face_match/${this.props.match.params.id}`)}>人脸验证</Button>
        }
      </div>
    }
    const audioPopCon = (
      <div className='pop'>
        <div className='pop-tit'>
          选择扬声器
        </div>
        {
          this.props.meet.devices.filter(item => item.kind === 'audiooutput').map(item => <div onClick={this.handleSetAudioOutputDevice.bind(this, item.deviceId)} key={item.deviceId} className={classNames('pop-item', {
            active: this.props.meet.audioId === item.deviceId
          })}>
            <img className='pop-item__select' alt='selected' src={icon_select}></img>
            {item.label}
          </div>)
        }
        
        <div className='pop-tit'>
          选择麦克风
        </div>
        {
          this.props.meet.devices.filter(item => item.kind === 'audioinput').map(item => <div onClick={this.handleSetMic.bind(this, item.deviceId)} key={item.deviceId} className={classNames('pop-item', {
            active: this.props.meet.micId === item.deviceId
          })}>
            <img className='pop-item__select' alt='selected' src={icon_select}></img>
            {item.label}
          </div>)
        }
      </div>
    )
    const vedioPopCon = (
      <div className='pop'>
        {
          this.props.meet.devices.filter(item => item.kind === 'videoinput').map(item => <div onClick={this.handleSetCamera.bind(this, item.deviceId)} key={item.deviceId} className={classNames('pop-item', {
            active: this.props.meet.camId === item.deviceId
          })}>
            <img className='pop-item__select' alt='selected' src={icon_select}></img>
            {item.label}
          </div>)
        }
      </div>
    )
    const ShareScreenPopCon = (
      <div className='pop'>
        <div className={classNames('pop-item')} onClick={this.handleShareManager}>
            <img
              className="pop-item__select"
              alt='selected'
              src={icon_select}
              style={{display: this.props.meet.allowAllToShareScreen ? 'initial' : 'none'}}
            />
            <span>全体成员可共享</span>
        </div>
        <div className={classNames('pop-item')} onClick={this.handleShareManager}>
            <img
              className="pop-item__select"
              alt='selected'
              src={icon_select}
              style={{display: !this.props.meet.allowAllToShareScreen ? 'initial' : 'none'}}
            />
            <span>仅主持人可共享</span>
        </div>
      </div>
    )
    const meetPopCon = (
      <div className='pop'>
        <div className='pop-tit'>切换会议</div>
        {
         this.state.meetInfo.rooms && (
          roomData.map(item => <div key={item.code}>
            <a href={"/web/meeting#/meet/" + item.code} onClick={() => { this.state.showMeetPopover = false }} target="_blank">{item.name}</a>
            {
              this.props.user.isManager && <span>&nbsp;<a onClick={()=>{
                if(this.roomEditDom.current){
                  this.roomEditDom.current.setFieldsValue({name: item.name});
                }
                this.setState({roomEditModalShow: true, roomEditName:item.name, roomEditCode: item.code})
              }}>修改</a></span>
            }
          </div>)
         ) 
        }
        {
          this.props.user.isManager && (
            <div>
              <Button onClick={()=>{this.setState({roomAddModalShow: true, roomAddName:''})}}>添加分会场</Button>
            </div>

          )
        }
        
      </div>
    )    
    let inviteText = `${this.props.user.userInfo.name} 邀请您参加云评审会议

会议 ID：${this.props.match.params.id}
会议主题：${this.props.meet.meetInfo.name}
会议时间：${formatDate('yyyy-MM-dd hh:mm:ss', this.props.meet.meetInfo.start_time)}-${formatDate('yyyy-MM-dd hh:mm:ss', this.props.meet.meetInfo.end_time)}

`
if(JSON.stringify(this.props.meet.meetInfo) === '{}') {
    inviteText += `点击链接加入主会场，或添加至会议列表：
${window.location.protocol}//${window.location.host}${window.location.pathname}#/meet/${this.props.match.params.id}

`
}else {
inviteText += this.props.meet.meetInfo && this.props.meet.meetInfo.room &&  `点击链接加入主会场，或添加至会议列表：
${window.location.protocol}//${window.location.host}${window.location.pathname}#/meet/${this.props.meet.meetInfo.room.code}

`
  if (this.props.meet.meetInfo && this.props.meet.meetInfo.rooms && this.props.meet.meetInfo.rooms
      .filter(item => !item.is_master).length > 0) {
    inviteText += `
分会场：
`
    inviteText += this.props.meet.meetInfo && this.props.meet.meetInfo.rooms && this.props.meet.meetInfo.rooms
        .filter(item => !item.is_master)
        .map(item => `点击链接加入分会场
${window.location.protocol}//${window.location.host}${window.location.pathname}#/meet/${item.code}

`)
  }
}

if (this.props.meet.meetInfo.setting.password) {
  inviteText += `入会密码：${this.props.meet.meetInfo.setting.password}`
}
   // console.log('---------------------------', this.props.meet.users);
    const me = this.props.meet.users.filter(item => item.user_id === this.props.user.userInfo.id)[0]
    const self = this;
    // console.log(me);
    return <div className='meet' id="meet" onClick={this.handleBodyClick}>
      <div className='meet-main-container'>
        <div className='meet-header'>
          <div className='meet-header__h1'>
            <div className='meet-header__h1-left'>
              {notInFrame && <div className='meet-header__h1-bg'></div>}
              {notInFrame && <img className='meet-header__h1-logo' alt='logo' src={icon_logo}></img>}

              <img className='meet-header__h1-logo2' alt='logo' src={icon_logo2}></img>
              <img className='meet-header__h1-logo3' alt='logo' src={icon_logo3}></img>
              <Popover placement="rightBottom" title={this.networkStatus()} content={this.networkStatusDetail()} trigger="click">
                <img className='meet-header__h1-status' alt='status' src={this.getStats()}></img>
              </Popover>
            </div>
            <div className='meet-header__h1-center'>
            云评审会议号：{this.props.match.params.id}
            </div>
            <div className='meet-header__h1-right'>
              成员数：{this.props.meet.users.filter(item=>item.isIn).length}/{this.props.meet.users.filter(item=>item.userName && !item.is_kick).length}
              <div className='meet-header__h1-fold' onClick={this.handleFoldAll}>一键收起各成员 <img alt='fold' src={icon_arrow}></img></div>
              {this.props.meet.showTimer && <Timer meet={this.props.meet} ></Timer>}
            </div>
          </div>
          <div className='meet-header__h2'>
            <div className='meet-header__h2-center'>
              <div className='meet-header__h2-center__middle'>
                会议主题：{this.state.meetInfo && this.props.meet.meetInfo.room.name}
                <div className={classNames('meet-header__h2-lock', {
                  locked: this.props.meet.lock
                })} onClick={this.handleLock}></div>
              </div>
            
              <div className='meet-header__bg meet-header__bg-left'></div>
              <div className='meet-header__bg meet-header__bg-right'></div>
            </div>
            
          </div>
          
        </div>

        <div className='meet-main'>

          <div className='meet-main__top'>
            {this.props.meet && this.props.meet.users.filter(item => !item.isAMuted && item.audioLevel > 0.1).length > 0 &&
                <div>
                  <div className='meet-main__speak'></div>
                  正在讲话：{this.props.meet && this.props.meet.users.filter(item => !item.isAMuted && item.audioLevel > 0.1).map(item => `${item.userName} ${item.majorName ? '（' + item.majorName + '）' : ''}`).join('、')}
                </div>
            }
          </div>
          <div className={classNames('meet-main__con', {
            full: this.state.fold
          })} /* style={{height: this.state.conHeight}} */>
            <div className='meet-main__place'>
              <img alt='logo' src={icon_logo_m}></img>
            </div>
            <div id='sceen' className='meet-main__screen' ref={this.fullScreenDom}>
              <video style={{width: '100%', height: '100%'}} autoPlay='1' id='videomain' ref={this.videoDom}></video>
              
              {this.props.meet.watermark && <div className='meet-main__screen-mask'>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) =>
                <div key={item} className='meet-main__screen-mask__item'>
                  {new Array(12).fill(0).map((item, index) => <span key={index}>{me && `${me._company || ''} ${me.majorName || ''} ${me.userName} ${formatDate('yyyy-MM-dd')}`}</span>)}
                </div>)}
              </div>}
              <div className='meet-main__fullscreen' onClick={this.handleFullscreen}></div>
            </div>
          </div>


          </div>

      
          <div className={classNames('meet-middle', {
        fold: this.state.fold_footer
      })}>
        
        {/* <div onClick={this.handleFoldFooter} className={classNames('meet-middle__zoom', {
          hide: !this.state.fold_footer
        })}>
          <div className='meet-middle__zoom-con'>
          业主代表席
          </div>
          <img alt='unfold' className='meet-left__zoom-arrow' src={icon_arrow_fold}></img>
        </div> */}
        <div className='meet-middle__con'>
        
          <div className={classNames('meet-main__footer')}>
            <img alt='unfold' onClick={this.handleFoldFooter} className={classNames('meet-middle__unfold', {
              hide: false
            })} src={this.state.fold_footer ? icon_arrow_fold : icon_arrow_unfold}></img>
            <div className='meet-left__tit'>
              业主代表席
            </div>
            <div className='meet-main__footer-list'>
              <div className='meet-main__footer-list__con'>
                {this.props.meet.users.filter(item=> !item.is_kick && item.role === 'others').map(item => item.userName && <div onClick={this.handleVideoToMain.bind(this, item.id)} key={item.id} className='meet-main__footer-item'>
                  <div className='meet-left__con-list__item main'>
                  <video style={{width: '100%', height: '100%'}} autoPlay='1' id={`video-${item.id}`} key={`video-${item.id}`}></video>
                    {item.isIn ? "" : <div className='meet-left__con-center'><img src={wait} alt=""/>等待参会人加入...</div>}
                    <div className='meet-left__con-footer'>
                      <span className={classNames('meet-left__con-voice', {
                        disable: item.isAMuted,
                        active: item.audioLevel > 0,
                      }, `audio_level_${parseInt(item.audioLevel*10)}`)}></span>
                      {item.userName}
                    </div>
                    <div className='meet-left__con-corner'></div>
                    { this.props.meet.handupList.join(',').indexOf(item.user_id) > -1 
                      && <div className={classNames('meet-handup', {
                        active: !isMutedByManager(item.user_id, this.props.meet)
                      })} onClick={this.handleHandupClick.bind(this, item, !isMutedByManager(item.user_id, this.props.meet))}></div>
                    }
                  </div>
                  { console.log(item) }
                  <div className='meet-main__footer-item__desc'>{item && (item._companyShort || item.companyShortName)}</div>
                </div>)
                }
                
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className={classNames('meet-footer', {
        fold: this.state.fold_footer
      })}>
        <div className='meet-footer__item'>
          <div className='meet-footer__item-icon'>
              <div onClick={this.handleVoice} className={classNames('meet-footer__item-icont voice', {
                muted: this.props.meet.audioMuted
              })}></div>
              <Popover trigger="" visible={this.state.showVoicePopover} onClick={this.handleShowVoicePopover}  content={audioPopCon} title="">
              <div onClick={this.handleVoiceSelect} className='meet-footer__item-icont arrow'></div>
              </Popover>
          </div>
          <div>静音</div>
        </div>
        <div className='meet-footer__item' >
          <div className='meet-footer__item-icon'>
            <div onClick={this.handleVideo} className={classNames('meet-footer__item-icont videoi', {
              muted: this.props.meet.videoMuted
            })}></div>
            <Popover trigger="" visible={this.state.showVideoPopover} onClick={this.handleShowVideoPopover} content={vedioPopCon} title="">
            <div className='meet-footer__item-icont arrow'></div>
            </Popover>
          </div>
          <div>视频</div>
        </div>
        <div className='meet-footer__item'>
          <div className='meet-footer__item-icon'>
            <div onClick={this.handleShareScreen} className='meet-footer__item-icont share'></div>
            {this.props.user.isManager &&
            <Popover trigger="" visible={this.state.showShareScreenPopover} onClick={this.handleshowShareScreenPopover} content={ShareScreenPopCon} title="">
              <div className='meet-footer__item-icont arrow'></div>
            </Popover>
            }
          </div>
          <div>共享屏幕</div>
        </div>
        <div className='meet-footer__item' onClick={this.handleInvite}>
          <div className='meet-footer__item-icon'>
            <div className='meet-footer__item-icont invite'></div>
          </div>
          <div>邀请</div>
        </div>
        {(this.props.meet.isManager || !this.props.meet.meetInfo.room.is_master && this.props.user.userInfo.id == this.props.meet.meetInfo.room.room_compere_id) && <div className='meet-footer__item' onClick={this.handleManage}>
          <div className='meet-footer__item-icon'>
            <div className='meet-footer__item-icont member'></div>
          </div>
          <div>管理成员</div>
        </div>}
        <div className='meet-footer__item' onClick={this.handleChat}>
          <div className='meet-footer__item-icon'>
            <div className='meet-footer__item-icont chat'></div>
            {this.props.meet.unread && <div className='meet-footer__item-msg'></div>}
          </div>
          <div>聊天</div>
        </div>
        {this.props.meet.meetInfo.allow_user_info && <div className='meet-footer__item' onClick={this.handleRegister}>
          <div className='meet-footer__item-icon'>
            <div className='meet-footer__item-icont register'></div>
          </div>
          <div>会签情况</div>
        </div>}
        <a target='blank' onClick={(e)=>(e.preventDefault(), this.handleBrowseFile())} className='meet-footer__item'>
        {/* <a target='blank' onClick={(e)=>(e.preventDefault(),this.setState({showFile:true}))} className='meet-footer__item'> */}
          <div className='meet-footer__item-icon'>
            <div className='meet-footer__item-icont doc'></div>
          </div>
          <div>文档</div>
        </a>
        <div className='meet-footer__item' onClick={this.handleSet}>
          <div className='meet-footer__item-icon'>
            <div className='meet-footer__item-icont set'></div>
          </div>
          <div>设置</div>
        </div>
        <div className='meet-footer__item'>
          <div className='meet-footer__item-icon' onClick={this.handleShowMeetingPopover}>
              <div className='meet-footer__item-icont meet-list'></div>
              <Popover trigger="" visible={this.state.showMeetPopover}  content={meetPopCon} title="">
                  <div className='meet-footer__item-icont arrow'></div>
              </Popover>
          </div>
          <div onClick={this.handleShowMeetingPopover}>分会场</div>
        </div>        
        
        {this.props.meet.meetInfo.allow_user_info && (this.props.user.userInfo.id === this.props.meet.meetInfo.allow_user_info.responsibilityPersonId) ?
            <div className='meet-footer__quit' onClick={this.handleHangupManager}>
              离开会议
            </div>:
            <div className='meet-footer__quit' onClick={this.handleHangup}>
              离开会议
            </div>
        }
       
      </div>
      

      </div>

    
      <div className={classNames('meet-left', {
        fold: this.state.fold
      })}>
        <div onClick={this.handleFold} className={classNames('meet-left__zoom', {
          hide: !this.state.fold
        })}>
          <div className='meet-left__zoom-con'>
          设计席
          </div>
          <img alt='unfold' className='meet-left__zoom-arrow' src={icon_arrow_fold}></img>
        </div>
        <img alt='unfold' onClick={this.handleFold} className={classNames('meet-left__unfold', {
          hide: this.state.fold
        })} src={icon_arrow_unfold}></img>
        <div className='meet-left__tit'>
          设计席
        </div>
        <div className='meet-left__con'>
          <div className='meet-left__con-tit'>
            {this.state.meetInfo.allow_user_info && this.state.meetInfo.allow_user_info.designUnitShortName}
          </div>
          {this.state.meetInfo.allow_user_info &&
          <div className='meet-left__con-list' style={{height: this.state.listHeight}}>
            <div className='meet-left__con-list__header'>
              设总
            </div>
            {this.props.meet.users.filter(item => item.role === 'designDirector').map(item => <div key={item.id}
                                                                                                   className='meet-left__con-list__item'
                                                                                                   onClick={this.handleVideoToMain.bind(this, item.id)}>
              <video style={{width: '100%', height: '100%'}} autoPlay='1' id={`video-${item.id}`} key={`video-${item.id}`}></video>
              {this.props.meet.coChair === item.user_id && <div className='meet-left__con-tag'>
                联席主持人
              </div>}
              {item.isIn ? "" : <div className='meet-left__con-center'><img src={wait} alt=""/>等待参会人加入...</div>}
              <div className='meet-left__con-footer'>
                <span className={classNames('meet-left__con-voice', {
                  disable: item.isAMuted,
                  active: item.audioLevel > 0
                }, `audio_level_${parseInt(item.audioLevel*10)}`)}></span>
                {item.userName}
              </div>
              <div className='meet-left__con-corner'></div>
              { this.props.meet.handupList.join(',').indexOf(item.user_id) > -1 
                && <div className={classNames('meet-handup', {
                  active: !isMutedByManager(item.user_id, this.props.meet)
                })} onClick={this.handleHandupClick.bind(this, item, !isMutedByManager(item.user_id, this.props.meet))}></div>
              }
            </div>)}
            <div className='meet-left__con-list__header sec'>
              设计人员
            </div>

            {this.props.meet.users.filter(item=>!item.is_kick).filter(item => item.role === 'design')
            .sort((a, b) =>b.rank_value - a.rank_value )
            .map(item => <div key={item.id} onClick={this.handleVideoToMain.bind(this, item.id)} className='meet-left__con-list__item'>

              <video style={{width: '100%', height: '100%'}} autoPlay='1' id={`video-${item.id}`} key={`video-${item.id}`}></video>
              {
                !this.props.meet.meetInfo.room.is_master && this.props.meet.meetInfo.room.room_compere_id && item.user_id == this.props.meet.meetInfo.room.room_compere_id && (
                  <div className='meet-left__con-tag red'>会议主持人</div>
                )
              }
              {/* <div className='meet-left__con-avatar'>
                <img alt='avatar' src={avatar_default}></img>
              </div> */}
              {this.props.meet.coChair === item.user_id && <div className='meet-left__con-tag'>
                联席主持人
              </div>}
              {item.isIn ? "" : <div className='meet-left__con-center'><img src={wait} alt=""/>等待参会人加入...</div>}
              <div className='meet-left__con-footer'>
              <span className={classNames('meet-left__con-voice', {
                disable: item.isAMuted,
                active: item.audioLevel > 0
              }, `audio_level_${parseInt(item.audioLevel*10)}`)}></span>
                {item.majorName + ': ' + item.userName}
              </div>
              <div className='meet-left__con-corner'></div>
              { this.props.meet.handupList.join(',').indexOf(item.user_id) > -1 
                && <div className={classNames('meet-handup', {
                  active: !isMutedByManager(item.user_id, this.props.meet)
                })} onClick={this.handleHandupClick.bind(this, item, !isMutedByManager(item.user_id, this.props.meet))}></div>
              }
            </div>)}
          </div>
          }
        </div>
      </div>

      <div className={classNames('meet-right', {
        fold: this.state.fold
      })}>
        <img alt='unfold' onClick={this.handleFold} className={classNames('meet-left__unfold', {
          hide: this.state.fold
        })} src={icon_arrow_unfold}></img>
        <div onClick={this.handleFold} className={classNames('meet-left__zoom', {
          hide: !this.state.fold
        })}>
          <div className='meet-left__zoom-con'>
          评审席
          </div>
          <img alt='unfold' className='meet-left__zoom-arrow' src={icon_arrow_fold}></img>
        </div>
        <div className='meet-left__tit'>
          评审席
        </div>
        <div className='meet-left__con'>
          <div className='meet-left__con-tit'>
            {this.state.meetInfo.allow_user_info && this.state.meetInfo.allow_user_info.specialistShortName}
          </div>
          {this.state.meetInfo.allow_user_info &&
          <div className='meet-left__con-list' style={{height: this.state.listHeight}}>
            <div className='meet-left__con-list__header'>
              评审负责人
            </div>
            {this.props.meet.users.filter(item => item.role === 'responsibilityPerson').map(item => <div
              key={item.id}  className='meet-left__con-list__item' onClick={this.handleVideoToMain.bind(this, item.id)}>
              <video style={{width: '100%', height: '100%'}} autoPlay='1'
                     id={`video-${this.state.meetInfo.allow_user_info.responsibilityPersonId}`}
                     key={`video-${this.state.meetInfo.allow_user_info.responsibilityPersonId}`}
                     ></video>
              <div className='meet-left__con-tag red'>
                主持人
              </div>
              {item.isIn ? "" : <div className='meet-left__con-center'><img src={wait} alt=""/>等待参会人加入...</div>}
              <div className='meet-left__con-footer'>
                <span className={classNames('meet-left__con-voice', {
                  disable: item.isAMuted,
                  active: item.audioLevel > 0
                }, `audio_level_${parseInt(item.audioLevel*10)}`)}></span>
                {this.state.meetInfo.allow_user_info.responsibilityPerson}
              </div>
              <div className='meet-left__con-corner'></div>
            </div>)}
            <div className='meet-left__con-list__header sec'>
              评审人员
            </div>
            {this.props.meet.users.filter(item=>!item.is_kick).filter(item => item.role === 'specialist')
            .sort((a, b) =>b.rank_value - a.rank_value )
            .map(item =>
                <div onClick={this.handleVideoToMain.bind(this, item.id)} key={item.id}
                     className='meet-left__con-list__item'>
                  <video style={{width: '100%', height: '100%'}} autoPlay='1' id={`video-${item.id}`} key={`video-${item.id}`}></video>
                  {
                    !this.props.meet.meetInfo.room.is_master && this.props.meet.meetInfo.room.room_compere_id && item.user_id == this.props.meet.meetInfo.room.room_compere_id && (
                      <div className='meet-left__con-tag red'>主持人</div>
                    )
                  }
                  <div className='meet-left__con-corner'></div>
                  {item.isIn ? "" : <div className='meet-left__con-center'><img src={wait} alt=""/>等待参会人加入...</div>}

                  { this.props.meet.handupList.join(',').indexOf(item.user_id) > -1 
                    && <div className={classNames('meet-handup', {
                      active: !isMutedByManager(item.user_id, this.props.meet)
                    })} onClick={this.handleHandupClick.bind(this, item, !isMutedByManager(item.user_id, this.props.meet))}></div>
                  }
                  <div className='meet-left__con-footer'>
                <span className={classNames('meet-left__con-voice', {
                  disable: item.isAMuted,
                  active: item.audioLevel > 0
                }, `audio_level_${parseInt(item.audioLevel*10)}`)}></span>
                    {item.majorName + ': ' + item.userName}
                  </div>
                </div>)}

          </div>
          }
        </div>
      </div>
      {this.props.user.isManager &&
      <Modal
          title=""
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          style={{textAlign: "center"}}
        >
          <p style={{fontWeight:"bolder",fontSize:"1rem"}}>结束会议</p>
          <p style={{"color":"#999"}}>如果您不想结束会议，请在离开会议前指定新的主持人。</p>
          <div className="leave-button">
            <Button onClick={this.handleCancel}>取消</Button>
            <Button type="primary" onClick={this.handleFinish}>结束会议</Button>
            <Button type="primary" onClick={this.handleHangup}>离开会议</Button>
          </div>
        </Modal>
      }
      {this.props.user.isManager &&
      <Modal
          className="rommAddModal"
          autoComplete="false"
          title="修改分会场"
          visible={this.state.roomEditModalShow}
          onCancel={()=>{this.setState({roomEditModalShow: false})}}
          footer={null}
          style={{textAlign: "center"}}
        >
          <Form onFinish={this.editMeet} layout="horizontal" ref={this.roomEditDom}>
            <Form.Item initialValue={this.state.roomEditName} label="分会场名称" name="name" rules={[{required: true, message: "请输入分会场名称"}]}>
              <Input onChange={(e)=>{this.setState({roomEditName: e.target.value})}} />
            </Form.Item>
            <div className="add-room-button">
              <Button onClick={()=>{this.setState({roomEditModalShow: false})}}>取消</Button>&nbsp;
              <Button type="primary" htmlType="submit">保存</Button>
            </div>
          </Form>
        </Modal>
      }
      {this.props.user.isManager &&
      <Modal
          className="rommAddModal"
          autoComplete="false"
          title="添加分会场"
          visible={this.state.roomAddModalShow}
          onCancel={()=>{this.setState({roomAddModalShow: false})}}
          footer={null}
          style={{textAlign: "center"}}
        >
          <Form onFinish={this.addMeet} layout="horizontal">
            <Form.Item label="分会场名称" name="name" rules={[{required: true, message: "请输入分会场名称"}]}>
              <Input onChange={(e)=>{this.setState({roomAddName: e.target.value})}} />
            </Form.Item>
            <div className="add-room-button">
              <Button onClick={()=>{this.setState({roomAddModalShow: false})}}>取消</Button>&nbsp;
              <Button type="primary" htmlType="submit">添加</Button>
            </div>
          </Form>
        </Modal>
      }
      {this.state.showChat && <Chat user={this.props.user} meet={this.props.meet} userInfo={this.props.user.userInfo} onClose={this.handleCloseChat} msgs={this.props.chat.msgs} onSend={this.handleSendMsg}></Chat>}
      <Modal
          title=""
          centered
          visible={this.state.showInvite}
          cancelText="取消"
          okText="复制"
          onOk={() => this.handleInvite(false)}
          onCancel={() => this.handleInvite(false)}
          footer={null}
        >
          <div className='invite'>
            <div className='invite-header'>云评审会议号：{this.props.match.params.id}</div>
            <div className='invite-header2'>会议主题：{this.state.meetInfo.name} </div>
            <div className='invite-con'>
              <textarea id="invite-text" className='invite-con__text' readOnly defaultValue={inviteText}>
              </textarea>
            </div>
            <div className='invite-footer'>
              <div data-clipboard-target="#invite-text" id='copy-invite' className='invite-footer__btn'>
                复制
              </div>
            </div>
          </div>
        </Modal>
        <Member 
          // handleSetAsManager={this.handleSetAsManager} 
          // handleSetAsManager={this.handleSetAsManager.apply(this, arguments)} 
          handleSetAsManager={self.handleSetAsManager.bind(self)} 
          // kickOut={this.handleKickOut} 
          // kickOut={this.handleKickOut.apply(this, arguments)} 
          kickOut={self.handleKickOut.bind(self)}
          saveSetting={this.saveSetting}
          handleMuteManage={this.handleMuteManage} 
          handleManage={this.handleManage} 
          onCancel={this.handleManage} 
          meet={this.props.meet} 
          user={this.props.user} 
          showManage={this.state.showManage}
        ></Member>
        {this.props.meet.meetInfo.allow_user_info && <Register
          meet={this.props.meet}
          user={this.props.user}
          dispatch={this.props.dispatch}
          handleRegister={this.handleRegister}
          onCancel={this.handleRegister}
          showRegister={this.state.showRegister}></Register>}
          <Modal
           title=""
           centered
           className='Meet-Add-File-Modal'
           visible={this.state.showFile}
           width="80%"
           height={parseInt(window.innerHeight*0.8).toString()+'px'}
           onOk={() => {this.setState({showFile:false})}}
           onCancel={() => {this.setState({showFile:false})}}
           footer={null}
          >
            <AddFile
            allow_member_upload={this.props.meet.meetInfo.setting.allow_member_upload}
            id={this.props.match.params.id}
            meet={this.props.meet}
            back={()=>this.setState({showFile:false})}
            upload={this.handleUploadFile}
            />  
          </Modal>
         {/* {this.state.showFile && 
          <AddFile
          allow_member_upload={this.props.meet.meetInfo.setting.allow_member_upload}
          id={this.props.match.params.id}
          meet={this.props.meet}
          back={()=>this.setState({showFile:false})}
          upload={this.handleUploadFile}
          />} */}
        <Set handleVoice={this.handleVoice} handleVideo={this.handleVideo} me={me} meet={this.props.meet} user={this.props.user} handleSet={this.handleSet} show={this.state.showSet}></Set>
    </div>
  }
}

const mapStateToProps = state => ({
  chat: state.chat,
  meet: state.meet,
  user: state.user
})
export default connect(mapStateToProps)(withRouter(Meet))