import React from 'react';
import { Input, Select, Button } from 'antd';
import classNames from 'classnames';
import './Chat.scss'
import icon_close from '../../../images/meet/close.png'
import listener from '../../../lib/listener'
const { TextArea } = Input;
const { Option } = Select;

class Chat extends React.Component{
  state = {
    val: '',
    sendTo: 0
  }
  constructor(props) {
    super(props);
    this.msgContainer = React.createRef();
    window.msgCon = this.msgContainer
  }
  handleSend = (e) => {
    e.preventDefault()
    if (this.state.val === '') {
      return
    }
    this.props.onSend(this.state.val, this.state.sendTo)
    this.setState({
      val: ''
    })
    this.scrollToBottom()
  }
  handleChange = (r) => {
    
    const { value } = r.target;
    this.setState({
      val: value
    })
  }
  handleSendTo = (r) => {
    // console.log(r)
    this.setState({
      sendTo: r
    })
  }
  handleClose = (e) => {
    e.preventDefault()
    this.props.onClose()
  }
  scrollToBottom = () => {
    setTimeout(() => {
      this.msgContainer.current.scrollTop = 99999
    },300)
  }
  componentDidMount() {
    listener.register('meet', 'chat',this.scrollToBottom)
  }
  componentWillUnmount() {
    listener.remove('meet', 'chat',this.scrollToBottom)
  }
  getNick = (user_id) => {
    const user = this.props.meet.users.filter(item => item.user_id === user_id)[0]
    if (user) {
      return user.userName
    }
    return null
  }
  handleChatClick(e){
    e.stopPropagation()
  }
  render() {
    return <div className='meet-chat' onClick={this.handleChatClick}>
      <div className='meet-chat__header'>
        聊天
        <a onClick={this.handleClose} className='meet-chat__header-close' href=':;'><img alt='close' src={icon_close}></img></a>
      </div>
      <div className='meet-chat__msgs'>
        <div className='meet-chat__msgs-list' ref={this.msgContainer}>
          
          {
            this.props.msgs.filter(item => !(item.to != 0 && item.to !== this.props.user.userInfo.id) || item.user_id === this.props.user.userInfo.id).map((item, index) => <div key={index} className={classNames('meet-chat__msgs-item', {
              me: item.user_id === this.props.userInfo.id
            })}>
              <div className='meet-chat__msgs-nick'>
          {item.user_nick}{item.to !=0 && <span className='meet-chat__bar-desc'>（私聊{item.user_id === this.props.userInfo.id ? ': ' + this.getNick(item.to) : ''}）</span>}
              </div>
              <div className='meet-chat__msgs-msg'>
                {item.text}
              </div>
            </div>)
          }
        </div>
      </div>
      
      <div className='meet-chat__input'>
        <div className='meet-chat__bar'>
          发送至 <Select className='meet-chat__select' defaultValue="0" style={{ }} onChange={this.handleSendTo}>
                <Option value="0">全部</Option>
        {this.props.meet.users.filter(item => item.isIn && item.user_id !== this.props.user.userInfo.id).map(item => <Option value={item.user_id}>{item.userName}</Option>)}
              
              </Select>
            {this.state.sendTo != 0 && <span className='meet-chat__bar-desc'>（私聊）</span>}
        </div>
        <TextArea onChange={this.handleChange} value={this.state.val} onPressEnter={this.handleSend} className='meet-chat__input-ta' placeholder='请输入消息...' rows={4} ></TextArea>
        <Button onClick={this.handleSend} type="primary">发送</Button>
      </div>
    </div>
  }
}

export default Chat