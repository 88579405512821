export const receiveMsg = (msg) => (dispatch, getState) => {
  dispatch({
    type: 'RECEIVE_MSG',
    msg
  })
}
export const resetChat = () => (dispatch, getState) => {
  dispatch({
    type: 'RESET'
  })
}