import React from "react";
import './Face_match.scss';
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import {message} from "antd";

class Face_match extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCapture: true,
            hideCapture: false,
            showUnload: false,
            token: ""
        }
    }

    componentDidMount() {
        console.log(this.props.match.params.id)
        //访问用户媒体设备的兼容方法
        function getUserMedia(constraints, success, error) {
            if (navigator.mediaDevices.getUserMedia) {
                //最新的标准API
                navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
            } else if (navigator.webkitGetUserMedia) {
                //webkit核心浏览器
                navigator.webkitGetUserMedia(constraints, success, error)
            } else if (navigator.mozGetUserMedia) {
                //firfox浏览器
                navigator.mozGetUserMedia(constraints, success, error);
            } else if (navigator.getUserMedia) {
                //旧版API
                navigator.getUserMedia(constraints, success, error);
            }
        }

        let video = this.video

        function success(stream) {
            video.srcObject = stream;
            video.play();
        }

        function error(error) {
            console.log(`访问用户媒体设备失败${error.name}, ${error.message}`);
        }

        if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
            //调用用户媒体设备, 访问摄像头
            getUserMedia({video: {width: 480, height: 320}}, success, error);
        } else {
            alert('不支持访问用户媒体');
        }

        this.$api.get("/api/auth/face_match").then(response => {
            this.setState({
                token: response.data.token
            })
        })
    }

    handleCapture = () => {
        let code = this.props.match.params.id
        let token = this.state.token
        let video = this.video
        let canvas = this.canvas
        let context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, 480, 320);
        canvas.toBlob( (blob) => {
            blob.lastModifiedDate = new Date();
            blob.name = 'file.jpg';
            let formData = new FormData();  
            formData.append('file', blob, "file.jpg");
            formData.append("csrfmiddlewaretoken", token);
            this.$api.upload("/api/auth/face_match",formData).then(response => {
                if(response.data.result){
                    this.props.backMeet()
                }else{
                    message.warn('头像认证失败');
                }
            })
        }, 'image/jpeg', 0.7);

        this.setState({
            showCapture: false,
            hideCapture: true,
            showUnload: true,
        })
    }

    redo() {
        this.setState({
            showCapture: true,
            hideCapture: false,
            showUnload: false,
        })
    }

    render() {
        return (
            <div id="box">
                <div id="face_header">
                    <div id="header-text">
                        <p id="title">人脸识别</p>
                        <Link to="/meeting/meeting_status" style={{color:'#37B39F'}}>返回</Link>
                        {/* <p id="header-login">
                            <Link to="/auth/login">使用账号密码登录</Link>
                            <img alt="" src={require("../../../images/auth/face_match/next.svg")}/>
                        </p> */}
                    </div>
                </div>

                <div id="face-form">
                    <div id="form-left">
                        <video className={classNames({
                            'show': this.state.showCapture,
                        })} ref={video => this.video = video} id="video"></video>
                        <canvas className={classNames({
                            'show': this.state.showUnload,
                        })} ref={canvas => this.canvas = canvas} id="canvas" width="480" height="320">画布
                        </canvas>
                    </div>
                    <div id="form-right">
                        <dl id="check">
                            <dt style={{marginBottom: '0.5em'}}>检测步骤：</dt>
                            <dd>1.检查是否开启摄像头</dd>
                            <dd>2.把脸部对准摄像头校准区域</dd>
                            <dd>3.点击拍照按钮开始识别</dd>
                            {/* <dd>3.点击开始按钮</dd>
                            <dd>4.倒计时结束后开始拍照检测人像</dd> */}
                        </dl>
                        {/*<form id="form" method="post" action="/auth/face_match" encType="multipart/form-data"*/}
                        {/*      style="display: none">*/}
                        {/*    <input type="hidden" name="csrfmiddlewaretoken" value="{{ token }}"/>*/}
                        {/*    <input id="file" type="file" name="file"/>*/}
                        {/*    <input id="submit" type="submit" value="提交"/>*/}
                        {/*</form>*/}

                        <div id="button-group">
                            <div id="capture-group">
                                <button ref={capture => this.capture = capture} onClick={this.handleCapture.bind(this)}
                                        id="capture" className={classNames({
                                    'show': this.state.showCapture,
                                    'hide': this.state.hideCapture
                                })}>拍照
                                </button>
                            </div>
                            <div id="upload-group" className={classNames({
                                'show': this.state.showUnload,
                            })}>
                                {/*<button id="upload" >上传</button>*/}
                                <button id="redo" onClick={this.redo.bind(this)}>重拍</button>
                            </div>
                            <div id="loading">
                                验证中...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(Face_match)