import React from "react";
import './HeaderComponent.scss'
import {Link, withRouter} from "react-router-dom";
import {message} from "antd";
import {connect} from "react-redux";
import {updateStatus} from "../../../actions/user";
import logo_icon from '../../../images/meeting/create/logo.png'
import join_icon from '../../../images/meeting/create/join.svg'
import fast_icon from '../../../images/meeting/create/fast.png'
import schedule_icon from '../../../images/meeting/create/schedule.svg'
import user_icon from '../../../images/meeting/create/user.png'
import drop_down_icon from '../../../images/meeting/create/drop-down.svg'
import auth_exit_icon from '../../../images/meeting/create/auth_exit.svg'
import auth_set_icon from '../../../images/meeting/create/auth_set.svg'
import formatDate from "../../../lib/formatDate";
class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUserSetting: false,
            // timer: null
        }
    }

    componentDidMount() {
    }

    // componentWillUnmount() {
    //     clearInterval(this.state.timer)
    // }

    handleMe(e) {
        e.preventDefault()
        this.setState({
            showUserSetting: !this.state.showUserSetting
        })
    }
    signout() {
        localStorage.clear()
        this.props.dispatch(updateStatus({
            isAuthenticated: false,
            userInfo: ""
        }))
        // this.props.history.push('/auth/login')
        this.$api.goLoginPage();
    }
    handleFast=(e)=>{
        e.preventDefault()
        let dataObj = {
            name: this.props.user.userInfo.name+'的会议' + formatDate('[yyyy-MM-dd hh:mm:ss]', new Date()),
            allow_member_upload:true,
            allow_enter_before_compere:true,
            force_watermark:true
        }
        this.$api.post( "/api/meeting/create",dataObj).then(res=>{
            // console.log(res.data)
            if(res.code === 504){
                return message.warn(res.msg)
            }
            let code=res.data.rooms[0].code
            if(res.ok===1){
                setTimeout(() => {
                    // message.info('登录成功');
                    const key = 'updatable';
                    message.info({content: '创建成功', key});
                }, 1000)
                this.props.history.push(`/meet/${code}`)
            }
        })
    }
    render() {
        // ?inframe=1 加入参数隐藏logo和登录
        const notInFrame = location.href.indexOf('inframe=0') > -1
        return (
            <div id="header">
                <div id="header-logo">
                    {notInFrame && <img id="logo" src={logo_icon} alt="" 
                      onClick={()=>this.props.history.push("/meeting/meeting_status")}
                    />}
                    <span>云评审</span>
                    { location.protocol == 'http:' && <div>&nbsp;<a href={`${process.env.PUBLIC_URL}/readme.pdf`} target="_blank">HTTP环境使用指南</a></div>}
                </div>
                <div id="header-meeting">
                    <button onClick={()=>{this.props.history.push("/meeting/join")}}>
                        <img src={join_icon} alt=""/>
                        {/*<Link to="/meeting/join">加入会议</Link>*/}
                        <a onClick={(e)=>{e.preventDefault()}}>加入会议</a>
                    </button>
                    <button onClick={this.handleFast}>
                        <img src={fast_icon} alt=""/>
                        {/*<Link to="">快速会议</Link>*/}
                        <a  onClick={(e)=>{e.preventDefault()}}>快速会议</a>
                    </button>
                    <button onClick={()=>{this.props.history.push("/meeting/create")}}>
                        <img src={schedule_icon} alt=""/>
                        {/*<Link to="/meeting/create">预定会议</Link>*/}
                        <a onClick={(e)=>{e.preventDefault()}}>预定会议</a>
                    </button>
                </div>
                {notInFrame && <a onClick={this.handleMe.bind(this)} id="header-user">
                    <img id="user" src={user_icon} alt=""/>
                    <span>{this.props.user.userInfo.name}</span>
                    <img id="drop-down" src={drop_down_icon} alt=""/>
                </a>}
                {this.state.showUserSetting && <div id="me">
                    <ul>
                        <li onClick={this.signout.bind(this)}>
                            <img src={auth_exit_icon} alt=""/>
                            <a>退出登录</a>
                        </li>
                        {/* <li>
                            <img src={auth_set_icon} alt=""/>
                            <a>设置</a>
                        </li> */}
                    </ul>
                    <div id="triangle"><span></span></div>
                </div>}

            </div>
        )
    }
}


const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(withRouter(HeaderComponent))