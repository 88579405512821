import React from "react";
import './MeetingStatus.scss'
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import ListComponent from "../ListComponent/ListComponent";
import EditComponent from "../EditComponent/EditComponent";
import classNames from 'classnames'
import {Spin, Pagination, Modal, Button} from 'antd'
import listener from "../../../lib/listener";
import dayjs from 'dayjs'
const packageVal = require('../../../../package.json');

class MeetingStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: "",
            doLists: "",
            didLists: "",
            doTotal: "",
            didTotal: "",
            personalLists: "",
            page: 1,
            didPage:1,
            showListbox: true,
            showDolist: 1,
            doActive: true,
            item: {},
            visible: false,
            editContainer: false,
            isVersionCheckModalShow: false,
        }
    }

    componentDidMount() {
        let dataObj = {
            page: 1,
            limit: 20,
            filter: {
                status__in: ['PENDING', 'MEETING'],
                end_time__gt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            }
        }
        this.$api.post("/api/meeting/list", dataObj).then(response => {
            let doLists = response.data.items
            let doTotal = response.data.total
            doLists.map(item => {
                if (dayjs().isBefore(dayjs(item.start_time))) {
                    return item.status = '待开始'
                } else if (dayjs().isAfter(dayjs(item.end_time))) {
                    return item.status = '已结束'
                } else {
                    return item.status = '进行中'
                }
            })
            this.setState({
                doLists,
                doTotal
            })
        })
        let dataObj2 = {
            page: 1,
            limit: 20,
            filter: {
                OR: [
                    {
                        status__in: ['FINISHED', 'CANCELED'],
                    },
                    {
                        end_time__lt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    },
                ]
            }
        }
        this.$api.post("/api/meeting/list",dataObj2).then(res=>{
            let didLists = res.data.items
            let didTotal = res.data.total
            // didLists.map(item=>item.status="已完成")
            this.setState({
                didLists,
                didTotal
            })
        })
        let dataObj3 = {
            page: 1,
            limit: 20,
            // is_personal: true,
            filter: {
                OR: [
                    {
                        status__in: ['FINISHED', 'CANCELED'],
                    },
                    {
                        end_time__lt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    },
                ]
            }
        }
        this.$api.post("/api/meeting/list",dataObj3).then(res=>{
            let personalLists = res.data.items
            let personalTotal = res.data.total
            this.setState({
                personalLists,
                personalTotal
            })
        })

        let tabIndex = 0;
        try{
            tabIndex = parseInt(location.hash[location.hash.search('tab=')+4])
            const calltype = [this.todo, this.did, this.personal, this.versionCheck];
            if(tabIndex<0 || tabIndex >= calltype.length){
                tabIndex = 0;
            }
            calltype[tabIndex].bind(this)();
        }catch(e){}
    }

    date = (date) => {
        if (date)
            return date.slice(0, 10) + " " + date.slice(11, 19)
    }

    todo = (e) => {
        e && e.preventDefault()
        this.setState({
            showDolist: 1,
            doActive: true,
            showListbox: true,
            editContainer: false
        })
        location.hash = '/meeting/meeting_status?tab=0';
    }

    did = (e) => {
        e && e.preventDefault()
        this.setState({
            showDolist: 0,
            doActive: false,
            showListbox: true,
            editContainer: false
        })
        location.hash = '/meeting/meeting_status?tab=1';
    }

    personal = (e) => {
        e && e.preventDefault()
        this.setState({
            showDolist: 3,
            doActive: false,
            showListbox: true,
            editContainer: false
        })
        location.hash = '/meeting/meeting_status?tab=2';
    }

    versionCheck = (e)=>{
        e && e.preventDefault()
        this.setState({
            showDolist: 2,
        })
        location.hash = '/meeting/meeting_status?tab=3';
    }

    handleEdit(item) {
        // console.log(item)
        this.setState({
            item
        })
        this.setState({
            showListbox: false,
            editContainer: true
        })
    }

    handleCancel(data) {
        let doLists = data.items
        // console.log(doLists)
        let doTotal = data.total
        doLists.map(item => {
            if (dayjs().isBefore(dayjs(item.start_time))) {
                return item.status = '待开始'
            } else if (dayjs().isAfter(dayjs(item.end_time))) {
                return item.status = '已结束'
            } else {
                return item.staus = '进行中'
            }
        })
        this.setState({
            doLists,
            doTotal
        })
    }
    handleDelCancel(data) {
        let didLists=data.items
        // console.log(didLists)
        this.setState({
            didLists
        })
    }

    handleSave(data) {
        this.setState({
            showListbox: true,
            editContainer: false
        })
        if (data) {
            let doLists = data.items
            let doTotal = data.total
            doLists.map(item => {
                if (dayjs().isBefore(dayjs(item.start_time))) {
                    return item.status = '待开始'
                } else if (dayjs().isAfter(dayjs(item.end_time))) {
                    return item.status = '已结束'
                } else {
                    return item.status = '进行中'
                }
            })
            this.setState({
                doLists,
                doTotal
            })
        }
    }

    handleBack = () => {
        this.setState({
            showListbox: true,
            editContainer: false
        })
    }

    handleEditCancel = () => {
        let dataObj = {
            limit: 20,
            filter: {
                status__in: ['PENDING', 'MEETING'],
                end_time__gt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            }
        }
        this.$api.post("/api/meeting/list", dataObj).then(res => {
            // debugger;
            let doLists = res =>res.data.items
            // console.log(doLists)
            doLists.map(item => {
                if (dayjs().isBefore(dayjs(item.start_time))) {
                    return item.status = '待开始'
                } else if (dayjs().isAfter(dayjs(item.end_time))) {
                    return item.status = '已结束'
                } else {
                    return item.status = '进行中'
                }
            })
            this.setState({
                doLists
            })
        })
        this.setState({
            showListbox: true,
            editContainer: false
        })
    }
    onPage = (page) => {
        // console.log(page)
        this.setState({
            page
        })
        let dataObj = {
            page,
            limit: 20,
            filter: {
                status__in: ['PENDING', 'MEETING'],
                end_time__gt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            }
        }
        this.$api.post("/api/meeting/list", dataObj).then(res => {
            let doLists = res.data.items
            doLists.map(item => {
                if (dayjs().isBefore(dayjs(item.start_time))) {
                    return item.status = '待开始'
                } else if (dayjs().isAfter(dayjs(item.end_time))) {
                    return item.status = '已结束'
                } else {
                    return item.status = '进行中'
                }
            })
            this.setState({
                doLists
            })
        })
    }
    ondidPage = (page) => {
        // console.log(page)
        this.setState({
            didPage:page
        })
        let dataObj = {
            page,
            limit:20,
            filter: {
                OR: [
                    {
                        status__in: ['FINISHED', 'CANCELED'],
                    },
                    {
                        end_time__lt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    },
                ]
            }
        }
        this.$api.post("/api/meeting/list",dataObj).then(res=>{
            let didLists = res.data.items
            // console.log(didLists)
            this.setState({
                didLists
            })
        })
    }
    handleBodyClick = () => {
        listener.fire('meeting', 'hideedit')
        listener.fire('meeting','hideenter')
    }

    toDoListDom(){
        return <div id="do-list">
            {/*<img alt="" src={require("../../../images/meeting/meeting_list/组 7.png")} />*/}
            <div id="list-title">
                <span></span>
                <p>即将召开的会议</p>
            </div>
            {this.state.doLists ?
                <div>
                    <table>
                        <thead>
                        <tr>
                            <td>序号</td>
                            <td>开始时间</td>
                            <td style={{width: '45%'}}>会议主题</td>
                            <td>会议号</td>
                            <td>状态</td>
                            <td>操作</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.doLists && this.state.doLists.map((item, index) => [
                            <ListComponent item={item} index={index} key={index}
                                        edit={this.handleEdit.bind(this)}
                                        cancel={this.handleCancel.bind(this)}
                                        showDolist={this.state.showDolist === 1}
                            />
                        ])}
                        {
                            (!this.state.doLists || this.state.doLists.length == 0) && 
                            <tr>
                                <th colSpan={6}>暂无会议</th>
                            </tr>
                        }
                        </tbody>
                    </table>
                    <div className="page">
                        <Pagination size="small" showSizeChanger={false} defaultPageSize={20}
                                    defaultCurrent={this.state.page} onChange={this.onPage}
                                    total={this.state.doTotal}/>
                    </div>
                </div> :
                <Spin className="spin" size="large"/>
            }
        </div>
    }

    didListDom(){
        return <div id="did-list">
            <div id="list-title">
                <span></span>
                <p>已结束的会议</p>
            </div>
            <table>
                <thead>
                <tr>
                    <td>序号</td>
                    <td>开始时间</td>
                    <td style={{width: '50%'}}>会议主题</td>
                    <td>会议号</td>
                    <td>操作</td>
                </tr>
                </thead>
                <tbody>
                {this.state.didLists && this.state.didLists.map((item, index) => [
                    <ListComponent item={item} index={index} key={index}
                                edit={this.handleEdit.bind(this)}
                                cancel={this.handleDelCancel.bind(this)}
                                showDolist={this.state.showDolist}
                    ></ListComponent>
                ])}
                {
                    (!this.state.didLists || this.state.didLists.length == 0) && 
                    <tr>
                        <th colSpan={5}>暂无会议</th>
                    </tr>
                }
                </tbody>
            </table>
            <div className="page">
                <Pagination size="small" showSizeChanger={false} defaultPageSize={20}
                            defaultCurrent={1} onChange={this.ondidPage}
                            total={this.state.didTotal}/>
            </div>
        </div>;
    }

    PersonalListDom(){
        return <div id="did-list">
            <div id="list-title">
                <span></span>
                <p>个人中心</p>
            </div>
            <table>
                <thead>
                <tr>
                    <td>序号</td>
                    <td>开始时间</td>
                    <td style={{width: '50%'}}>会议主题</td>
                    <td>会议号</td>
                    <td>操作</td>
                </tr>
                </thead>
                <tbody>
                {this.state.personalLists && this.state.personalLists.map((item, index) => [
                    <ListComponent item={item} index={index} key={index}
                                edit={this.handleEdit.bind(this)}
                                cancel={this.handleDelCancel.bind(this)}
                                showDolist={this.state.showDolist}
                    ></ListComponent>
                ])}
                {
                    (!this.state.personalLists || this.state.personalLists.length == 0) && 
                    <tr>
                        <th colSpan={5}>暂无会议</th>
                    </tr>
                }
                </tbody>
            </table>
            <div className="page">
                <Pagination size="small" showSizeChanger={false} defaultPageSize={20}
                            defaultCurrent={1} onChange={this.ondidPage}
                            total={this.state.personalTotal}/>
            </div>
        </div>
    }


    showVersionCheckModal = ()=>{
        this.setState({
            isVersionCheckModalShow: true
        });
    }

    checkVersionDom(){
        return <div id="check-update-list">
            <h4>云评审视频中心</h4>
            <p className="version">V{packageVal.version} Build {packageVal.build_version}</p>
            <button onClick={this.showVersionCheckModal}>检查更新</button>
        </div>;
    }

    closeVersionCheckDom = ()=>{
        this.setState({
            isVersionCheckModalShow: false
        });
    }

    versionCheckModalDom(){
        return <Modal
            title=""
            className="version-check-modal"
            visible={this.state.isVersionCheckModalShow}
            onCancel={this.closeVersionCheckDom}
            footer={null}
            style={{textAlign: "center"}}
        >
            <h4>云评审视频中心</h4>
            <p>当前版本<span className="version">V{packageVal.version}</span> Build<span className="version">{packageVal.build_version}</span> 已是最新版本</p>
            <div className="leave-button">
            <Button type="primary" size="small" onClick={this.closeVersionCheckDom}>确定</Button>
            </div>
        </Modal>;
    }

    render() {
        return (
            <div id="status-box" onClick={this.handleBodyClick}>
                <HeaderComponent  history={this.props.history}/>

                <div id="container" className={classNames({"edit-container": this.state.editContainer})}>
                    <div id="meeting-status">
                        <a onClick={this.todo}
                           className={classNames({'active': this.state.showDolist===1})}>即将召开的会议</a>
                        <a onClick={this.did}
                           className={classNames({'active': this.state.showDolist===0})}>已结束的会议</a>
                        <a onClick={this.personal}
                           className={classNames({'active': this.state.showDolist===3})}>个人中心</a>
                        <a onClick={this.versionCheck}
                           className={classNames({'active': this.state.showDolist===2})}>检查更新</a>
                    </div>
                    {this.state.showListbox ? <div id="list-box">
                            {this.state.showDolist === 1 && this.toDoListDom() }
                            {this.state.showDolist === 0 && this.didListDom() }
                            {this.state.showDolist === 2 && this.checkVersionDom() }
                            {this.state.showDolist === 3 && this.PersonalListDom() }
                        </div> :
                        <EditComponent item={this.state.item} save={this.handleSave.bind(this)}
                                       editCancel={this.handleEditCancel} back={this.handleBack} />}
                </div>
                { this.versionCheckModalDom() }
            </div>
        )
    }
}

export default MeetingStatus