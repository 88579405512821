import React from 'react';
import {Popover, Input, Modal, message, Tabs, Button, List, Avatar, Spin} from 'antd';
import {updateUser} from "../../../actions/meet";
import classNames from 'classnames';
import './Register.scss'
import formatDate from "../../../lib/formatDate";
import user from '../../../reducers/user';

const {Search} = Input;
const {TabPane} = Tabs;

class Register extends React.Component {
    state = {
        search: '',
        users:"",
    }
    componentDidMount() {
        this.refresh();
    }

    componentWillReceiveProps(nextProps, nextContext){
        if(!this.props.showRegister && nextProps.showRegister){
            this.refresh();
        }
    }

    refresh = ()=>{
        let meet_id=this.props.meet.meetInfo.room.meeting
        let {start_time, end_time}=this.props.meet.meetInfo
        let startMs=new Date(formatDate('yyyy/MM/dd hh:mm:ss', start_time)).getTime()
        let endMs=new Date(formatDate('yyyy/MM/dd hh:mm:ss', end_time)).getTime()
        this.$api.get(`/api/meeting/logs/${meet_id}`).then(res=>{
            let data = res.data;
            let userTimeMap = {};
            data.forEach(item => {
                // console.log('dispatch')
                this.props.dispatch(updateUser({
                    id: item.user_id,
                    enter_time: item.enter_time,
                    leave_time: item.level_time
                }))
                userTimeMap[item.user_id] = item;
            })
            let users=this.props.meet.users
            // console.log(users,"迟到")
            users.map(item=>{
                if(!userTimeMap[item.user_id]){
                    item.status = '缺席'
                    return
                }
                let enterMs=new Date(formatDate('yyyy/MM/dd hh:mm:ss', userTimeMap[item.user_id].enter_time)).getTime()
                // console.log(item.userName,item.enter_time,start_time,end_time,'001')
                if(enterMs<(startMs+600000)){
                    item.status='正常'
                }else if(enterMs>(startMs+600000) && enterMs<(startMs+1200000)){
                    item.status='迟到'
                }else if(enterMs>(startMs+1200000) && enterMs<endMs){
                    item.status='严重迟到'
                }else{
                    item.status = '缺席'
                }
            })
            console.log(users);
            this.setState({
                users,
            })
        });
    }
    date=(time)=>{
        return time.slice(0,10).split("-").join("/") +" "+time.slice(11,19)
    }
    handleTabs = (key) => {
        console.log(key)
    }
    handleChange=(e)=>{
        this.setState({
            search:e.target.value
        })
    }
    base64 =(s)=> { return window.btoa(unescape(encodeURIComponent(s))) }
    handleExport = () => {
        const users=this.state.users
        let jsonData=[]
        users.map((item,index)=>{
                let obj={}
                obj.index=index+1
                obj.name=item.userName
                obj.status=item.status
                if(item.role==='design' || item.role==='designDirector'){
                    obj.company='设计单位'
                }else if(item.role==='specialist' || item.role==='responsibilityPerson'){
                    obj.company='评审单位'
                }else if(item.role==='others'){
                    obj.company='业主'
                }
                if(item.majorName){
                    obj.majorName=item.majorName
                }else{
                    obj.majorName="无"
                }
                obj.phone='17671436821'
                obj.enter_time=item.enter_time ? formatDate('yyyy/MM/dd hh:mm',item.enter_time):"无"
                obj.autograph='无'
                jsonData.push(obj)
        })
        let str=`<tr><td colspan="5">会议主题：${this.props.meet.meetInfo.name}</td><td>会议号：${this.props.meet.meetInfo.room.code}</td><td colspan="2">会议时间：${formatDate('yyyy/MM/dd hh:mm',this.props.meet.meetInfo.start_time)} - ${formatDate('yyyy/MM/dd hh:mm',this.props.meet.meetInfo.end_time)}</td></tr>` +
            `<tr><td colspan="3">建管单位：${this.props.meet.meetInfo.allow_user_info.buildUnitName}</td><td>评审单位名称：${this.props.meet.meetInfo.allow_user_info.specialistShortName}</td><td>评审负责人：${this.props.meet.meetInfo.allow_user_info.responsibilityPerson}</td><td>设计单位名称：${this.props.meet.meetInfo.allow_user_info.designUnitShortName}</td><td colspan="2">设计总监名称：${this.props.meet.meetInfo.allow_user_info.designDirectorName}</td></tr>` +
            '<tr><td>序号</td><td>姓名</td><td>会签状态</td><td>单位</td><td>职务或专业</td><td>电话</td><td>入会时间</td><td>签名</td></tr>'
        for(let i=0;i<jsonData.length;i++){
            str+='<tr>';
            for(let item in jsonData[i]){
                str+=`<td>${jsonData[i][item]+'\t'}</td>`;
            }
            str+='</tr>'
        }
        let worksheet = 'Sheet1';
        let uri = 'data:application/vnd.ms-excel;base64,';
        let template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
      xmlns:x="urn:schemas-microsoft-com:office:excel"
      xmlns="http://www.w3.org/TR/REC-html40">
      <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table border="1">${str}</table></body></html>`;
      // 下载模板
      const a = document.createElement('a')
      a.href = uri + this.base64(template)
      a.download = `${this.props.meet.meetInfo.name}+会签情况表.xls`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    render() {
        let users=this.state.users
        if(users){
            users = users.filter(item=>item.userName)
        }
        if (this.state.search !== '') {
            users = users.filter(item => (item.userName && item.userName.indexOf(this.state.search) > -1) || (item.majorName && item.majorName.indexOf(this.state.search) > -1))
        }
        return (<Modal
            className='register-modal'
            title=""
            centered
            visible={this.props.showRegister}
            cancelText="取消"
            okText="复制"
            onOk={() => this.props.handleRegister(false)}
            onCancel={() => this.props.handleRegister(false)}
            footer={null}
        >
            <div className="register">
                <div className="register-title">会签情况</div>
                <Search
                    onChange={this.handleChange}
                    placeholder="搜索成员"
                    onSearch={value => console.log(value)}
                    style={{width: 300}}
                />
                <Tabs size="large" defaultActiveKey="1" onChange={this.handleTabs}>
                    <TabPane tab={"正常（"+(this.state.users && this.state.users.filter(item=>item.status==='正常').filter(item=>item.userName).length)+"人）"} key="1">
                        <div className="register-list">
                            <dl>
                                <dt className="register-list__design"><span className="spot">●</span>设计单位: {this.props.meet.meetInfo.allow_user_info.designUnitShortName}
                                （{this.state.users && (users.filter(item=>item.status==='正常').filter(item=>item.role==="designDirector" || item.role==="design")).length}人）</dt>
                                {this.state.users && (users.filter(item=>item.status==='正常').filter(item=>item.role==="designDirector" || item.role==="design")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>评审单位: {this.props.meet.meetInfo.allow_user_info.specialistShortName}
                                （{this.state.users && (this.state.users.filter(item=>item.status==='正常').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='正常').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>业主:
                                （{this.state.users && (this.state.users.filter(item=>item.status==='正常').filter(item=>item.role === "others").filter(item=>item.userName)).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='正常').filter(item=>item.role === "others").filter(item=>item.userName)).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                            </dl>
                        </div>
                    </TabPane>

                    <TabPane tab={"迟到（"+(this.state.users && this.state.users.filter(item=>item.status==='迟到').filter(item=>item.userName).length)+"人）"} key="2">
                        <div className="register-list">
                            <dl>
                                <dt className="register-list__design"><span className="spot">●</span>设计单位: {this.props.meet.meetInfo.allow_user_info.designUnitShortName}
                                （{this.state.users && (users.filter(item=>item.status==='迟到').filter(item=>item.role==="designDirector" || item.role==="design")).length}人）</dt>
                                {this.state.users && (users.filter(item=>item.status==='迟到').filter(item=>item.role==="designDirector" || item.role==="design")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>评审单位: {this.props.meet.meetInfo.allow_user_info.specialistShortName}
                                （{this.state.users && (this.state.users.filter(item=>item.status==='迟到').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='迟到').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>业主:
                                （{this.state.users && (this.state.users.filter(item=>item.status==='迟到').filter(item=>item.role === "others").filter(item=>item.userName)).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='迟到').filter(item=>item.role === "others").filter(item=>item.userName)).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                            </dl>
                        </div>
                    </TabPane>

                    <TabPane tab={"严重迟到（"+(this.state.users && this.state.users.filter(item=>item.status==='严重迟到').filter(item=>item.userName).length)+"人）"} key="3">
                        <div className="register-list">
                            <dl>
                                <dt className="register-list__design"><span className="spot">●</span>设计单位: {this.props.meet.meetInfo.allow_user_info.designUnitShortName}
                                （{this.state.users && (users.filter(item=>item.status==='严重迟到').filter(item=>item.role==="designDirector" || item.role==="design")).length}人）</dt>
                                {this.state.users && (users.filter(item=>item.status==='严重迟到').filter(item=>item.role==="designDirector" || item.role==="design")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>评审单位: {this.props.meet.meetInfo.allow_user_info.specialistShortName}
                                （{this.state.users && (this.state.users.filter(item=>item.status==='严重迟到').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='严重迟到').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>业主:
                                （{this.state.users && (this.state.users.filter(item=>item.status==='严重迟到').filter(item=>item.role === "others").filter(item=>item.userName)).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='严重迟到').filter(item=>item.role === "others").filter(item=>item.userName)).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">{formatDate('yyyy/MM/dd hh:mm', item.enter_time)}</p>
                                </dd>
                                })}
                            </dl>
                        </div>
                    </TabPane>


                    <TabPane tab={"缺席（"+(this.state.users && this.state.users.filter(item=>item.status==='缺席').filter(item=>item.userName).length)+"人）"} key="4">
                        <div className="register-list">
                            <dl>
                                <dt className="register-list__design"><span className="spot">●</span>设计单位: {this.props.meet.meetInfo.allow_user_info.designUnitShortName}
                                （{this.state.users && (users.filter(item=>item.status==='缺席').filter(item=>item.role==="designDirector" || item.role==="design")).length}人）</dt>
                                {this.state.users && (users.filter(item=>item.status==='缺席').filter(item=>item.role==="designDirector" || item.role==="design")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">无参会记录</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>评审单位: {this.props.meet.meetInfo.allow_user_info.specialistShortName}
                                （{this.state.users && (this.state.users.filter(item=>item.status==='缺席').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).length}人）
                                </dt>
                                {this.state.users && (users.filter(item=>item.status==='缺席').filter(item=>item.role === "specialist" || item.role==="responsibilityPerson")).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">无参会记录</p>
                                </dd>
                                })}
                                <hr style={{background: "#DDDDDD", height: "1px", border: "none"}}/>
                                <dt className="register-list__special"><span className="spot">●</span>业主:
                                （{this.state.users && (this.state.users.filter(item=>item.status==='缺席').filter(item=>item.role === "others").filter(item=>item.userName)).length}人）
                                </dt>
                                {this.state.users && users.filter(item=>item.status==='缺席' && item.role === "others" && item.userName).map(item=>{
                                    return  <dd key={item.id}>
                                    <p>{item.userName} {item.majorName && <span>（{item.majorName}）</span>}</p>
                                    <p className="register-list__time">无参会记录</p>
                                </dd>
                                })}
                            </dl>
                        </div>
                    </TabPane>
                </Tabs>
                <div className="register-footer">
                    <button onClick={this.handleExport}>导出表格</button>
                </div>
            </div>
        </Modal>)
    }
}

export default Register