import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
// import Axios from 'axios'
import { sm2Decrypt } from '@bj-nsc/functions'
import $api from './lib/api';
import Cookies from 'js-cookie'
// $api.setDebug(true);
// import { updateStatus } from './actions/user'

(async ()=>{

// const axiosInstance = Axios.create()
const params = new URLSearchParams(window.location.hash.split('?')[1])
const token = (()=>{
  const params_token = params.get('token');
  if(params_token){
    return params_token;
  }
  // const cookie_dict = new URLSearchParams(document.cookie)
  const cookie_token = Cookies.get('t');
  if(cookie_token){
    return cookie_token;
  }
  return null;
})();
const serverTime = params.get('serverTime')

if (token) localStorage.setItem('token', token)
if (serverTime) localStorage.setItem('serverTime', serverTime)

// Component.prototype.$encrypt = encrypt;
// Component.prototype.$decrypt = decrypt;
// Component.prototype.$rsaEncrypt = rsaEncrypt;
Component.prototype.$api = $api;

// if(!localStorage.getItem('aesSecret')) {
//   const formData = new FormData();
//   formData.append('AES_secret', rsaEncrypt());
//   const response = await fetch(process.env.PUBLIC_URL + "/api/encrypt/report",{
//     method: 'post',
//     body: formData,
//   });
//   const secret_json = await response.json();
//   if (secret_json.code == '200') {
//     localStorage.setItem('aesSecret', secret_json.data)
//   }
// }
// Component.prototype.$axios = $api.axiosInstance;
  if(!localStorage.getItem('userInfo')){
    if(token){
      try{
        const me = await $api.get("/api/auth/me",{
          headers:{
            Authorization: token,
            timestamp: Date.now() + parseInt(serverTime ?? '0', 10)
          }
        });
        localStorage.setItem('userInfo', JSON.stringify(me.data.result));
        localStorage.setItem('token', me.data.result.token)
      }catch(e){
        if(!$api.isLoginPage()) {
          $api.goLoginPage();
        }
      }
    }else{
      if(!$api.isLoginPage()) {
        $api.goLoginPage();
      }
    }
  }

  setTimeout(()=>{
    const App = require('./App').default;
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    )
      
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();  
  }, 100);

})();

