const initialState = {
  isAuthenticated: !!localStorage.getItem('userInfo'),
  userInfo: (()=>{
    if(localStorage.getItem('userInfo')){
      return JSON.parse(localStorage.getItem('userInfo'));
    }
    return {
      id: null,
      name: null,
      companyShortName: null,
    };
  })()
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_STATUS':
      return {
        ...state,
        ...action.payload
      }
      
    default:
      return state
  }
}