import React from "react";
import './EditComponent.scss'
import {DatePicker, Modal, message,Input, TimePicker} from "antd";
import moment from 'moment';
import {Link,withRouter} from "react-router-dom";
import {connect} from "react-redux";
import classNames from 'classnames'
import formatDate from "../../../lib/formatDate";
import file_icon from '../../../images/meeting/create/file.png'
import { sm2Decrypt } from '@bj-nsc/functions'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs'

class EditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allow_enter_before_compere: true,
            force_watermark: 0,
            allow_member_upload: 1,
            visible: false,
            todoEdit: true,
            token: "",
            name: this.props.item.name,
            date_time: formatDate('yyyy-MM-dd', this.props.item.start_time),
            start_time: formatDate('hh:mm', this.props.item.start_time),
            end_time: formatDate('hh:mm', this.props.item.end_time),
            password: "",
            items: "",
            isManager:false,
            useFaceMatch: false
        }
        
        this.extTypes = {
            "pdf": require("../../../images/meeting/pdf.svg"),
            "ppt": require("../../../images/meeting/ppt.svg"),
            "doc": require("../../../images/meeting/word.svg"),
            "docx": require("../../../images/meeting/word.svg"),
            "xls": require("../../../images/meeting/exel.svg"),
            "xlsx": require("../../../images/meeting/exel.svg")
        }
    }

    componentDidMount() {
        // let code=this.props.item.rooms[0].code
        this.$api.get(`/api/room/query/${this.props.item.rooms[0].code}`).then(res=>{
            let setting=res.data.setting
            let isManager = this.props.user.userInfo.id === res.data.meeting.compere_id
            this.setState({
                allow_enter_before_compere: setting.allow_enter_before_compere,
                force_watermark: setting.force_watermark,
                allow_member_upload: setting.allow_member_upload,
                isManager,
                useFaceMatch: !!setting.face_recognition
            })
        })
        this.$api.post(`/api/file/list/${this.props.item.id}`).then(async res => {
            let items = res.data.items
            for (const item of items) {
                item.uploader_name = await sm2Decrypt(item.uploader_name)
            }
            this.setState({
                items
            })
        })
        if (this.props.item.status === "待开始") {
            this.setState({
                todoEdit: true
            })
        } else if (this.props.item.status === "进行中") {
            this.setState({
                todoEdit: false
            })
        }
        this.$api.get('/api/meeting/create').then(response => {
            this.setState({
                token: response.data.token
            })
        })
    }

    date = (date) => {
        return date.slice(0, 10) + " " + date.slice(11, 19)
    }

    onEnterBefore(e) {
        // this.setState({
        //     allow_enter_before_compere: e.target.checked
        // })
        message.warn('不能修改')
    }

    onWatermark(e) {
        // console.log(e.target.checked)
        if (e.target.checked) {
            this.setState({
                force_watermark: 1
            })
        } else {
            this.setState({
                force_watermark: 0
            })
        }
    }
    handleFaceMatch(e) {
        this.setState({
            useFaceMatch: e.target.checked
        })
    }
    onUploadFile(e) {
        if (e.target.checked) {
            this.setState({
                allow_member_upload: 1
            })
        } else {
            this.setState({
                allow_member_upload: 0
            })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = (e) => {
        this.$api.post(`/api/meeting/close/${this.props.item.id}`).then(response => {
            // console.log(response)
            this.props.editCancel()
        })
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            delVisible: false
        });
    };

    handleSave() {
        const params = {
            "csrfmiddlewaretoken": this.state.token,
            "start_time": `${this.state.date_time} ${this.state.start_time}`,
            "end_time": `${this.state.date_time} ${this.state.end_time}`,
            "name": this.state.name,
            "notice_all_member": 1,
            "room_num": this.props.item.rooms.length,
            "allow_member_upload": this.state.allow_member_upload,
            "allow_enter_before_compere": this.state.allow_enter_before_compere,
            "force_watermark": this.state.force_watermark,
            "face_recognition": this.state.useFaceMatch
        }
        if (this.state.password) {
            params.password = this.state.password
        }
        this.$api.post(`/api/meeting/edit/${this.props.item.id}`, params).then(response => {
            if (response.ok === 1) {
                this.$api.post("/api/meeting/list", {
                    page: 1,
                    limit: 20,
                    filter: {
                        status__in: ['PENDING', 'MEETING'],
                        end_time__gt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    }
                }).then(res => {
                    const data = res.data
                    this.props.save(data)
                })
            } else if (response.ok === 0) {
                Modal.error({
                    title: '只有主持人才能修改会议',
                });
                this.props.save()
            }
        })
    }

    changeName = (e) => {
        // console.log(e.target.value)
        this.setState({
            name: e.target.value
        })
    }

    onDate = (value, dateString) => {
        this.setState({
            date: dateString
        })
    }

    onStart = (value, timeString) => {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            start_time: timeString
        })
    }
    disabledDate = (current) => {
        // return current && current < moment().subtract(1, 'days')
        // return current && current < moment(formatDate('yyyy-MM-dd ')+' 00:00:00')
        return current && current < moment(formatDate('yyyy-MM-dd hh:mm'))
    }
    disabledEndDate = (current) => {
        if (this.state.start_time) {
            // return current &&  current < moment(this.state.start_time
            return current < moment(this.state.start_time) || current > moment(formatDate('yyyy-MM-dd', this.state.start_time) + ' 24:00:00');
        }
    }
    onEnd = (value, timeString) => {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            end_time: timeString
        })
    }

    range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    disabledStartTime = ()=>{
        if(moment(this.state.date).isSame(moment(), "day")){
            return {
                disabledHours: () => this.range(0, 24).splice(0, new Date().getHours()),
            }
        }else{
            return {};
        }
    }

    disabledEndTime = () => {
        const [hour, minute] = this.state.start_time.split(':').map(i=>parseInt(i));
        return {
            disabledHours: () => this.range(0, 24).splice(0, hour),
            disabledMinutes: (selectedHour) => {
                if(selectedHour<hour){
                    return this.range(0, 59);
                }else if(selectedHour==hour){
                    return this.range(0, minute);
                }else{
                    return [];
                }
            }
        }
    }

    onOk(value) {
        // console.log('onOk: ', value);
    }

    changePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    handleFile = (e) => {
        const file = e.target.files[0]
        if (!/\.(doc|docx|xls|xlsx|pdf|txt)$/.test(file.name)) {
            message.error('仅支持上传 word、excel、pdf、txt 文档')
            return
        }
        if (file.size / 1000 / 1024 > 50) {
            message.error('仅支持上传小于 50M 的文档')
            return
        }
        let params = new FormData()
        params.append("file", e.target.files[0])
        this.$api.upload(`/api/file/upload/${this.props.item.id}`, params).then(res => {
            // console.log(res)
            this.$api.post(`/api/file/list/${this.props.item.id}`).then(async res => {
                let items = res.data.items
                for (const item of items) {
                    item.uploader_name = await sm2Decrypt(item.uploader_name)
                }
                this.setState({
                    items
                })
            })
        })
    }

    handleDel(item) {
        if (this.state.isManager) {
            this.setState({
                delVisible: true,
                item
            })
        } else {
            if (item.uploader_id) {
                if (this.props.user.userInfo.id !== item.uploader_id) {
                    message.warn("仅主持人可操作")
                    return
                } else {
                    this.setState({
                        delVisible: true,
                        item
                    })
                }
            } else {
                this.setState({
                    delVisible: true,
                    item
                })
            }
        }
    }
    handlePreviewFile = (id) => {
        const w = window.open('about:blank');
        w.location.href = process.env.PUBLIC_URL + `/api/file/preview/${id}`
    }
    handleDelFile = e => {
        // console.log(e);
        this.$api.post(`/api/file/delete/${this.state.item.id}`).then(res => {
            // console.log(res)
            this.$api.post(`/api/file/list/${this.props.item.id}`).then(async res => {
                let items = res.data.items
                for (const item of items) {
                    item.uploader_name = await sm2Decrypt(item.uploader_name)
                }
                this.setState({
                    items
                })
            })
        })
        let input = document.getElementsByClassName('file-ipt')
        input[0].value = ''
        this.setState({
            delVisible: false
        })
    };

    render() {
        function handleHour(time) {
            let hour = time.slice(11, 13)
            if (hour < 12) {
                return "上午"
            } else {
                return "下午"
            }
        }
        function week(time) {
            if (time) {
                let date = time.slice(0, 10) + " " + time.slice(11, 19)
                let data = time.slice(0, 10).split("-").join("") + time.slice(11, 19).split(":").join("")

                function time2(data) {
                    let date = data.toString();
                    let year = date.substring(0, 4);
                    let month = date.substring(4, 6);
                    let day = date.substring(6, 8);
                    let d1 = new Date(year + '/' + month + '/' + day);

                    let dd = new Date();
                    let y = dd.getFullYear();
                    let m = dd.getMonth() + 1;
                    let d = dd.getDate();

                    let d2 = new Date(y + '/' + m + '/' + d);
                    let iday = parseInt(d2 - d1) / 1000 / 60 / 60 / 24;

                    return iday;
                }

                if (time2(data) === 0) {
                    return "今天"
                } else if (time2(data) === -1) {
                    return "明天"
                } else {
                    return '星期' + '日一二三四五六'.charAt(new Date(date).getDay());
                }
            }
        }

        return (
            <div id="edit-do">
                <div id="title">编辑“{this.props.item.name}” <span
                    className={classNames({"doing": !this.state.todoEdit})}>{this.props.item.status}</span></div>
                <div id="edit-content">
                    <ul>
                        <li>
                            <div id="edit-left">
                                <p>基本信息</p>
                                {this.state.todoEdit && <p>请填写会议基本信息</p>}
                            </div>
                            <div id="edit-right">
                                <label id="meeting-name">
                                    <span>会议名称：</span>
                                    {this.state.todoEdit ?
                                        <input onChange={this.changeName} defaultValue={this.props.item.name}/> :
                                        <span>{this.props.item.name}</span>}
                                </label>
                                <br/>
                                <label className="meet-date">
                                    <span>会议日期：</span>
                                    {this.state.todoEdit ?
                                        <DatePicker locale={locale} placeholder="请选择"
                                                    defaultValue={moment(formatDate('yyyy-MM-dd', this.props.item.date))}
                                                    disabledDate={this.disabledDate}
                                                    format="YYYY-MM-DD"
                                                    onChange={this.onDate}/> :
                                        // <span>{this.props.item.start_time.slice(5, 7) + "月" + this.props.item.start_time.slice(8, 10) + "日"} {handleHour(this.props.item.start_time)} {handleTime(this.props.item.start_time)}</span>
                                    <span>
                                        {/* {formatDate('yyyy-MM-dd hh:mm:ss',new Date(this.props.item.start_time))} */}
                                        {formatDate('MM月dd日',new Date(this.props.item.date))}&nbsp;&nbsp;
                                        {week(formatDate('yyyy-MM-dd',new Date(this.props.item.date)))}
                                    </span>
                                    }
                                </label>
                                <label className="meet-time">
                                    <span>会议时间：</span>
                                    {
                                    this.state.todoEdit ?
                                        <TimePicker locale={locale} placeholder="请选择"
                                                    defaultValue={moment(this.props.item.start_time)}
                                                    disabledTime={this.disabledStartTime}
                                                    format="HH:mm"
                                                    minuteStep={15}
                                                    onChange={this.onStart} /> :
                                    <span>{this.props.item.start_time}</span>
                                    }
                                    &nbsp; ~ &nbsp;
                                    {
                                        this.state.todoEdit ?
                                        <TimePicker locale={locale} placeholder="请选择"
                                                    defaultValue={moment(this.props.item.end_time)}
                                                    disabledTime={this.disabledEndTime}
                                                    format="HH:mm"
                                                    onChange={this.onEnd}
                                                    minuteStep={15}/> :
                                        <span>{this.props.item.end_time}</span>
                                    }

                                </label>
                            </div>
                        </li>
                        <li>
                            <div id="edit-left">
                                <p>安全</p>
                                <p>成员将需要验证身份入会</p>
                            </div>
                            <div id="edit-right">
                                <label id="password">
                                    <span>入会密码：</span>
                                    {/*<input type="password" onChange={this.changePassword} placeholder="选填"/>*/}
                                    <Input.Password placeholder="选填" onChange={this.changePassword}/>
                                </label>
                                <br/>
                                <label id="setting">
                                            <span className="default-checkbox">
                                               <input onChange={this.handleFaceMatch.bind(this)}
                                                    checked={this.state.useFaceMatch}
                                                    type="checkbox" />
                                            </span>
                                    <span>开启人脸识别</span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <div id="edit-left">
                                <p>设置</p>
                                {this.state.todoEdit && <p>请勾选相关的会议设置</p>}
                            </div>
                            <div id="edit-right">
                                {/* <label id="setting">
                                            <span className="default-checkbox">
                                               <input onClick={this.onEnterBefore.bind(this)}
                                                    //   defaultChecked={this.state.allow_enter_before_compere}
                                                    checked={this.state.allow_enter_before_compere}
                                                      type="checkbox"/>
                                            </span>
                                    <span>允许成员在主持人进会前加入会议</span>
                                </label> */}
                                {/* <br/> */}
                                <label id="setting">
                                            <span className="default-checkbox">
                                               <input onChange={this.onWatermark.bind(this)}
                                               checked={this.state.force_watermark}
                                                      type="checkbox" disabled/>
                                            </span>
                                    <span>开启屏幕共享水印</span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <div id="edit-left">
                                <p>文档</p>
                                <p>对本次会议相关的文档进行操作</p>
                            </div>
                            <div id="edit-right">
                                <label id="upload-file">
                                            <span className="default-checkbox">
                                               <input onChange={this.onUploadFile.bind(this)}
                                                   checked={this.state.allow_member_upload}
                                                      type="checkbox"/>
                                            </span>
                                    <span>允许成员上传文档</span>
                                </label>
                                <br/>
                                <div className='file-iptc'>
                                    <button id="up-button">上传文档</button>
                                    <input onChange={this.handleFile} className='file-ipt' type='file'/>
                                </div>
                                {/*<input type="file" placeholder="上传文档" />*/}
                                {this.state.items.length>0 ?
                                    <table style={{width:700}}>
                                        <thead>
                                        <tr>
                                            <td>名称</td>
                                            <td>创建者</td>
                                            <td>修改时间</td>
                                            {this.state.todoEdit && <td>操作</td>}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.items && this.state.items.map(item => [
                                        <tr key={item.id}>
                                            <td>
                                                <a onClick={this.handlePreviewFile.bind(this, item.id)}>
                                                    {<img alt="" src={this.extTypes[item.ext_name]} />}
                                                    {item.name}
                                                </a>
                                            </td>
                                            <td>{item.uploader_name}</td>
                                            <td>{formatDate('yyyy-MM-dd hh:mm:ss', item.created_at)}</td>
                                            {this.state.todoEdit && <td><a onClick={this.handleDel.bind(this, item)}>删除</a></td>}
                                        </tr>
                                    ])}
                                        </tbody>
                                    </table> :
                                    <div id="no-file">
                                        <img alt="" src={file_icon}/>
                                        <div>
                                            <p>支持word、excel、ppt、txt文档</p>
                                            <p>单个word、excel文件最大支持20M，pdf最大支持50M</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </li>
                    </ul>
                </div>
                <div id="edit-bottom">
                    <div>
                        <button onClick={this.props.back}>返回</button>
                        {this.state.todoEdit &&
                        <button id="cancel-meeting" onClick={this.showModal.bind(this)}>取消会议</button>}
                        <button id="enter-meeting"><Link to={"/meet/" + this.props.item.rooms[0].code}>进入会议</Link></button>
                        <button onClick={this.handleSave.bind(this)}>保存</button>
                    </div>
                </div>
                <Modal
                    title="确定取消该会议吗？"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="确定"
                    cancelText="取消"
                >
                    <p><span>会议主题：</span>{this.props.item.name}</p>
                    <p>
                        <span>会议时间：</span>{week(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}&nbsp;
                        {handleHour(formatDate('yyyy-MM-dd hh:mm:ss', this.props.item.start_time))}&nbsp;
                        {formatDate('hh:mm', this.props.item.start_time)}-{formatDate('hh:mm', this.props.item.end_time)}
                    </p>
                </Modal>
                <Modal
                    title="确定删除该文件？"
                    visible={this.state.delVisible}
                    onOk={this.handleDelFile}
                    onCancel={this.handleCancel}
                    okText="确定"
                    cancelText="取消"
                >
                    <p style={{ color: "red" }}>{this.state.item && this.state.item.name}</p>
                </Modal>
            </div>
        )
    }
}

// export default EditComponent
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(withRouter(EditComponent))