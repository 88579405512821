import React from 'react'
import { withRouter } from 'react-router-dom'

class Share extends React.Component {
  componentDidMount() {
    var system = {
      win: false,
      mac: false,
      xll: false,
      ipad: false,
    }
    // 检测平台
    var p = navigator.platform
    system.win = p.indexOf('Win') == 0
    system.mac = p.indexOf('Mac') == 0
    system.x11 = p == 'X11' || p.indexOf('Linux') == 0
    system.ipad = navigator.userAgent.match(/iPad/i) != null ? true : false
    if (system.win || system.mac || system.xll || system.ipad) {
      this.props.history.push('/meeting/meeting_status')
    } else {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        alert('点击右上角···' + '\n' + '选择在浏览器中打开')
      } else {
        window.location.href = 'xxx://meet.meet'
      }
    }
  }
  render() {
    return <div></div>
  }
}

export default withRouter(Share)
