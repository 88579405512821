import React from 'react';
import { Checkbox, Input, Modal, message } from 'antd';
import './Set.scss'
import store from '../../../store'
import { updateStatus } from '../../../actions/meet';

class Set extends React.Component {
  state = {

  }
  handleShowTimer = () => {
    store.dispatch(updateStatus({
      showTimer: !this.props.meet.showTimer
    }))
  }
  render() {
    const me = this.props.me
    return (
      <Modal
        title=""
        centered
        visible={this.props.show}
        cancelText="取消"
        okText="复制"
        onOk={() => this.props.handleSet(false)}
        onCancel={() => this.props.handleSet(false)}
        footer={null}
        className='set-modal'
      >
      
        <div className='set'>
          <div className='set-title'>设置</div>
          <div className='set-con'>
            <div className='set-con__left'>
              <div className='set-con__left-item'>
                <span className='set-icon__set'></span>常规设置
              </div>
            </div>
            <div className='set-con__right'>
              <label className='set-con__right-item'>
                <span className="default-checkbox">
                    <input checked={!this.props.meet.videoMuted}
                      onChange={this.props.handleVideo}
                      type="checkbox"/>
                </span>
                <span>开启摄像头</span>
              </label>
              <label className='set-con__right-item'>
                <span className="default-checkbox">
                    <input checked={!this.props.meet.audioMuted}
                      onChange={this.props.handleVoice}
                      type="checkbox"/>
                </span>
                <span>开启麦克风</span>
              </label>
              <label className='set-con__right-item'>
                <span className="default-checkbox">
                    <input checked={this.props.meet.showTimer}
                      onChange={this.handleShowTimer}
                      type="checkbox"/>
                </span>
                <span>显示参会时长</span>
              </label>
              <label className='set-con__right-item'>
                <span className="default-checkbox">
                    <input checked={this.props.meet.showMask}
                      disabled={true}
                      type="checkbox"/>
                </span>
                <span>开启屏幕共享水印</span>
              </label>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
export default Set