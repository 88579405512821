import React from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import './Join.scss'
import {Link, withRouter} from "react-router-dom";
// import { updateStatus } from "../../../actions/user";
import {Form, Input, message, Checkbox} from 'antd';
import listener from "../../../lib/listener";
import user_icon from '../../../images/meeting/join/user.png'
import major_icon from '../../../images/meeting/join/major.png'
import gongsi_icon from '../../../images/meeting/join/gongsi.png'
import authentication_icon from '../../../images/meeting/join/authentication.png'
import no_authentication_icon from '../../../images/meeting/join/no_authentication.png'
import { sm2Decrypt } from '@bj-nsc/functions'

class Join extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            me: "",
            showEnterRoom: false,
            rooms:null,
            roomCodeCorrect: false,
            roomName: null,
            errorMsg: null,
            autoVideo: false,
            autoAudio: false
        }
    }

    onHideEnter = () => {
        this.setState({
            showEnterRoom: false
        })
    };

    componentDidMount() {
        listener.register('meeting', 'hideenter', this.onHideEnter);
        this.$api.get("/api/auth/me").then(async res => {
            if (!res.data) {
                return
            }
            let me = res.data.result
            me.name = (await sm2Decrypt(me.realName)) || (await sm2Decrypt(me.name))
            this.setState({
                me
            })
        })
    }

    componentWillUnmount() {
        listener.remove('meeting', 'hideenter', this.onHideEnter);
    }

    onFinish = (values) => {
        // let name = values.name
        // if (name) {
        //     let dataObj = {
        //         page:1,
        //         limit:20,
        //         filter:{
        //             start_time__gt: '2000-01-01 00:00:00',
        //             name__contains: name
        //         }
        //     }
        //     this.$api.post("/api/meeting/list",dataObj).then(res=>{
        //         const data = res.data.items
        //         if (data.length === 0) {
        //             message.error('会议不存在')
        //             return
        //         }
        //         let rooms = data[0].rooms
        //         this.setState({
        //             rooms
        //         })
        //         if(rooms.length>1){
        //             this.setState({
        //                 showEnterRoom: true
        //             })
        //         }else if(rooms.length === 1){
        //             this.props.history.push(`/meet/${rooms[0].code}`)
        //         }
        //     })
        //     // listener.fire('meeting', 'hideenter')
        // }
        if (values.code) {
            this.props.history.push(`/meet/${values.code}?init-video=${this.state.autoVideo?1:0}&init-audio=${this.state.autoAudio?1:0}`)
        }
    }

    onCodeChange = (value)=>{
        const code = value.target.value;
        if(code.length < 9){
            this.setState({
                roomCodeCorrect:false,
                roomName: null,
                errorMsg: null,
            });
            return;
        }
        this.$api.get(`/api/room/query/${code}`).then((res)=>{
            if(res.ok){
                this.setState({
                    roomCodeCorrect:true,
                    roomName: res.data.room.name,
                });
            }else{
                this.setState({
                    errorMsg: '该会议不存在'
                })
            }
        })
    }

    onMediaChange = (e)=>{
        let values = {};
        values[e.target.name] = e.target.checked;
        this.setState(values);
    }

    render() {
        return (
            <div id="join-box" onClick={()=>listener.fire('meeting', 'hideenter')}>
                <HeaderComponent history={this.props.history}/>

                <div id="title">
                    <p>加入会议</p>
                </div>
                <div id="join-form">
                    <Form onFinish={this.onFinish}>
                        <Form.Item
                            label="会议号"
                            name="code"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: '会议号为空',
                            //     },
                            // ]}
                        >
                            <Input maxLength={9} placeholder="请输入会议号" onChange={this.onCodeChange}/>
                        </Form.Item>
                        <Form.Item
                            label="会议名称"
                            name="name"
                        >
                            { (()=>{
                                if(this.state.roomName){
                                    return <label>{this.state.roomName}</label>;
                                }
                                if(this.state.errorMsg){
                                    return <label style={{color:'red'}}>{this.state.errorMsg}</label>;
                                }
                                return <label>请输入会议号</label>;
                            })() }
                        </Form.Item>
                        {/*<label id="meeting-number">*/}
                        {/*    <span>会议号：</span>*/}
                        {/*    <input placeholder="请输入会议号"/>*/}
                        {/*</label>*/}
                        {/*<label id="meeting-name">*/}
                        {/*    <span>会议名称：</span>*/}
                        {/*    <input placeholder="请输入会议名称"/>*/}
                        {/*</label>*/}
                        <label>
                            名片卡
                            <div id="business-card">
                                <ul>
                                    <li>
                                        <img src={user_icon} alt=""/>
                                        <span>真实姓名：{this.state.me.name}</span>
                                    </li>
                                    <li>
                                        <img src={major_icon} alt=""/>
                                        <span>负责专业：{this.state.me.majorName}</span>
                                    </li>
                                    <li>
                                        <img src={gongsi_icon} alt=""/>
                                        <span>所属单位：{this.state.me.unitName}</span>
                                    </li>
                                </ul>
                                {this.state.me?
                                <div id="authentication">
                                    <img src={authentication_icon} alt=""/>
                                    <span>已认证</span>
                                </div>:
                                <div id="authentication">
                                    <img src={no_authentication_icon} alt=""/>
                                    <span style={{color:'#FF3F3F'}}>未认证</span>
                                </div>
                                }
                            </div>
                        </label>
                        <Checkbox defaultChecked={false} name='autoAudio' onChange={this.onMediaChange}>自动连接音频</Checkbox>
                        <Checkbox defaultChecked={false} name='autoVideo' onChange={this.onMediaChange}>入会开启摄像头</Checkbox>
                        <div className="footer-buttons">
                            <button type="button"  className="cancel" onClick={()=>{history.length ? history.back() : (location.hash='/')}}>取消</button>
                            <button disabled={!this.state.roomCodeCorrect}>加入会议</button>
                        </div>
                        {this.state.showEnterRoom &&
                        <div className="enter_room">
                            <ul>
                                {this.state.rooms.map(item => [
                                    <li key={item.code}><a id="enter-meeting"
                                        href={"/web/meeting#/meet/" + item.code} target="_blank">{item.name}</a>
                                    </li>
                                ])}
                            </ul>
                        </div>
                        }
                    </Form>
                </div>
            </div>
        )
    }
}

export default (withRouter(Join))