import {decodeUserItem} from '../reducers/meet'

export const updateStatus = (payload) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_STATUS',
    payload
  })
}

export const updateUser = (payload) => (dispatch, getState) => {
  // debugger;
  decodeUserItem(payload).then((user)=>{
    dispatch({
      type: 'UPDATE_USER',
      payload: user
    });
  })
}

// export const removeUser = (payload) => (dispatch, getState) => {
//   decodeUserItem(payload).then((user)=>{
//     dispatch({
//       type: 'REMOVE_USER',
//       payload: user
//     });
//   })
// }

export const updateSetting = (payload) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_SETTING',
    payload
  })
}

export const resetMeeting = (payload) => (dispatch, getState) => {
  dispatch({
    type: 'RESET',
    payload
  })
}