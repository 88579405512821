import React from 'react'
import { Provider } from 'react-redux'
import store from './store'

import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css'
import Meet from './pages/meet/Meet'
import Login from './pages/Auth/Login/Login'
import Face_match from './pages/Auth/FaceMatch/Face_match'
import Create from './pages/Meeting/Create/Create'
import Join from './pages/Meeting/Join/Join'
import MeetingStatus from './pages/Meeting/MeetingStatus/MeetingStatus'
import Me from './pages/Auth/Me/Me'
import Share from './pages/share/Share'

function RouteNeedAuth({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('token') ||
        store.getState().user.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login}></Route>
          <Route path="/auth/face_match/:id" component={Face_match}></Route>
          <Route path="/auth/me" component={Me}></Route>

          <RouteNeedAuth path="/meet/:id">
            <Meet></Meet>
          </RouteNeedAuth>
          <RouteNeedAuth path="/meeting/create">
            <Create />
          </RouteNeedAuth>
          <RouteNeedAuth path="/meeting/join">
            <Join />
          </RouteNeedAuth>
          <RouteNeedAuth path="/meeting/meeting_status">
            <MeetingStatus />
          </RouteNeedAuth>

          <Route exact path="/" component={Share}></Route>
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
