import React from 'react';
import icon_clock from '../../../images/meet/clock.png'


class Timer extends React.Component {
  state = {
    delay: '00:00'
  }
  timer = null
  parseTime = (from) => {
    if (from < 10) {
      return `0${from}`
    }
    return from
  }
  componentDidMount() {
    this.timer = setInterval(() => {
      const delay = Math.floor((new Date().getTime() - this.props.meet.startTime) / 1000)
      const second = delay % 60
      let min = Math.floor(delay / 60)
      let hour = 0
      if (min > 60) {
        hour = Math.floor(min / 60)
        min = min % 60
      }

      this.setState({
        delay: `${hour > 0 ? this.parseTime(hour) + ':' : ''}${min > 0 ? this.parseTime(min) : '00'}:${this.parseTime(second)}`
      })

    }, 1000)
  }
  componentWillUnmount() {
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  }
  render() {
    return (
      <div className='meet-header__h1-time'>
      <img alt='time' src={icon_clock}></img> {this.state.delay}
      </div>
    )
  }
}

export default Timer