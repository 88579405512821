import React from "react";
import './AddFile.scss'
import { Pagination, message, Button, Modal } from "antd";
import listener from "../../../lib/listener";
import { updateStatus } from "../../../actions/user";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import formatDate from "../../../lib/formatDate";
import logo_icon from '../../../images/meeting/create/logo.png'
import down_icon from '../../../images/meeting/create/下拉.svg'
import file_icon from '../../../images/meeting/create/file.png'
import { sm2Decrypt } from '@bj-nsc/functions'
class AddFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.file_lists || [],
            total: "",
            meeting_id: "",
            name: "",
            id: "",
            showManageUpload: false,
            // uploadable: this.props.allow_member_upload === 1 ? "全员可上传" : "仅主持人可上传",
            extType: "",
            visible: false,
            item: "",
            file_ids: [],
        }
        this.extTypes = {
            "pdf": require("../../../images/meeting/pdf.svg"),
            "ppt": require("../../../images/meeting/ppt.svg"),
            "doc": require("../../../images/meeting/word.svg"),
            "docx": require("../../../images/meeting/word.svg"),
            "xls": require("../../../images/meeting/exel.svg"),
            "xlsx": require("../../../images/meeting/exel.svg"),
            "txt": require("../../../images/meeting/txt.svg")
        }
    }
    fileList() {
        let id = this.props.id
        if (id === "0") {
            this.setState({
                meeting_id: id
            })
        } else {
            this.$api.get(`/api/room/query/${id}`).then(res => {
                let meeting_id = res.data.room.meeting
                let name = res.data.meeting.name
                this.$api.post(`/api/file/list/${meeting_id}`, {
                    page: 1,
                    limit: 10
                }).then(async res => {
                    let items = res.data.items
                    for (const item of items) {
                        item.uploader_name = await sm2Decrypt(item.uploader_name)
                    }
                    this.setState({
                        items,
                        total: res.data.total,
                        meeting_id,
                        name,
                        id
                    })
                })
            })
        }
    }
    componentDidMount() {
        // console.log(this.props)
        listener.register("add_file", "hideManageUpload", () => {
            this.setState({
                showManageUpload: false
            })
        })
        this.fileList()
    }

    date = (date) => {
        return date.slice(0, 10) + " " + date.slice(11, 19)
    }
    handleFile = (e) => {
        if (this.props.allow_member_upload === 0) {
            if (this.props.meet.isManager) {
                let params = new FormData()
                params.append("file", e.target.files[0], e.target.files[0].name)
                this.$api.upload(`/api/file/upload/${this.state.meeting_id}`, params).then(res => {
                    if (res.code === 200) {
                        if (this.state.meeting_id === "0") {
                            let file_ids = this.state.file_ids
                            let items = this.state.items
                            this.setState({
                                file_ids: [...file_ids, res.data.id],
                                items: [...items, res.data]
                            })
                        } else {
                            this.$api.post(`/api/file/list/${this.state.meeting_id}`).then(async res => {
                                let items = res.data.items
                                for (const item of items) {
                                    item.uploader_name = await sm2Decrypt(item.uploader_name)
                                }
                                this.setState({
                                    items,
                                    total: res.data.total
                                })
                            })
                        }
                    } else if (res.code === 403) {
                        message.warn("登录超时")
                        localStorage.removeItem("userInfo")
                        this.props.dispatch(updateStatus({
                            isAuthenticated: false,
                            userInfo: ""
                        }))
                        setTimeout(() => {
                            this.$api.goLoginPage();
                            // this.props.history.push("/auth/login")
                        }, 2000)
                    } else if (res.code === 503) {
                        message.warn(res.msg)
                    } else if (res.code === 404) {
                        message.warn("空文件")
                    } else {
                        message.warn(res.msg);
                    }
                }).catch((e)=>{
                    console.log(e);
                    debugger;
                });
            } else {
                return message.warn("仅主持人可上传")
            }
        } else {
            let params = new FormData()
            params.append("file", e.target.files[0], e.target.files[0].name)
            this.$api.upload(`/api/file/upload/${this.state.meeting_id}`, params, ).then(res => {
                if (res.code === 200) {
                    if (this.state.meeting_id === "0") {
                        let file_ids = this.state.file_ids
                        let items = this.state.items
                        let data = res.data
                        data.uploader_name = this.props.user.userInfo.name
                        this.setState({
                            file_ids: [...file_ids, res.data.id],
                            items: [...items, data]
                        })
                    } else {
                        this.$api.post(`/api/file/list/${this.state.meeting_id}`).then(async res => {
                            let items = res.data.items
                            for (const item of items) {
                                item.uploader_name = await sm2Decrypt(item.uploader_name)
                            }
                            this.setState({
                                items,
                                total: res.data.total
                            })
                        })
                    }
                } else if (res.code === 403) {
                    message.warn("登录超时")
                    localStorage.removeItem("userInfo")
                    this.props.dispatch(updateStatus({
                        isAuthenticated: false,
                        userInfo: ""
                    }))
                    setTimeout(() => {
                        // this.props.history.push("/auth/login")
                        this.$api.goLoginPage();
                    }, 2000)
                } else if (res.code === 503) {
                    message.warn(res.msg)
                } else if (res.code === 404) {
                    message.warn("空文件")
                }else{
                    message.warn(res.msg)
                }
            })
        }
    }

    handleDownload(item){
        this.$api.download(`/api/file/download/${item.id}`, item.name);
    }

    exportFiles = ()=>{
        const ids = this.state.items.map(item=>item.id).join(',');
        this.$api.download(`/api/file/dump?ids=${ids}`, `${this.state.id}.zip`);
    }

    handleDel(item) {
        // if(this.props.allow_member_upload === 0){
        //     if (this.props.user.userInfo.id === this.props.userLists.responsibilityPersonId || this.props.user.userInfo.id === this.props.userLists.designDirectorId){
        //         this.setState({
        //             visible: true,
        //             item
        //         })
        //     }
        //     console.log(item.uploader_id)
        //     message.warn("仅主持人可删除")
        //     return
        // }
        if (this.props.meet.isManager) {
            this.setState({
                visible: true,
                item
            })
        } else {
            if (item.uploader_id) {
                if (this.props.user.userInfo.id !== item.uploader_id) {
                    message.warn("仅主持人可操作")
                    return
                } else {
                    this.setState({
                        visible: true,
                        item
                    })
                }
            } else {
                this.setState({
                    visible: true,
                    item
                })
            }
        }
    }

    handleManage = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            showManageUpload: !this.state.showManageUpload
        })
    }
    handleChange = (e) => {
        // console.log(e.currentTarget.children[0].innerHTML)
        if (this.props.meet.isManager) {
            // this.setState({
            //     uploadable: e.currentTarget.children[0].innerHTML,
            //     showManageUpload: false
            // })
            this.props.upload(e.currentTarget.children[0].innerHTML)
        } else {
            message.warn('只有主持人才能修改')
            return
        }
    }
    onPage = (page) => {
        let dataObj = {
            page,
            limit: 10
        }
        this.$api.post(`/api/file/list/${this.state.meeting_id}`, dataObj).then(res => {
            // console.log(res.data.items)
            this.setState({
                items: res.data.items
            })
        })
    }
    handleFileClick = () => {
        listener.fire("add_file", 'hideManageUpload')
    }
    handlePreviewFile = (item) => {
        if (item.ext_name === 'xls' || item.ext_name === 'xlsx') {
            message.warn("不支持预览文件类型xls，xlsx")
            return
        }
        const w = window.open('about:blank');
        w.location.href = process.env.PUBLIC_URL + `/api/file/preview/${item.id}`
    }
    handleOk = e => {
        this.$api.post(`/api/file/delete/${this.state.item.id}`).then(res => {
            if (this.state.meeting_id === "0") {
                let file_ids = this.state.file_ids
                let items = this.state.items
                this.setState({
                    file_ids: file_ids.filter(item => item !== this.state.item.id),
                    items: items.filter(item => item.id !== this.state.item.id)
                })
            } else {
                this.$api.post(`/api/file/list/${this.state.meeting_id}`).then(async res => {
                    let items = res.data.items
                    for (const item of items) {
                        item.uploader_name = await sm2Decrypt(item.uploader_name)
                    }
                    this.setState({
                        items,
                        total: res.data.total
                    })
                })
            }
            let input = document.getElementsByClassName('file-ipt')
            input[0].value = ''
            this.setState({
                visible: false
            })
        })
    };

    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false,
        });
    };
    backFile = (e) => {
        if (this.props.id === '0') {
            this.props.back(this.state.file_ids, this.state.items)
        } else {
            this.props.back()
        }
    }
    renderTable = () => {
        return this.props.file_lists && this.props.file_lists.length > 0 ?
            <div className="file-table">
                <table>
                    <thead>
                        <tr>
                            <td>名称</td>
                            <td>创建者</td>
                            {this.props.id !== "0" && <td>修改时间</td>}
                            {/* <td>操作</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.file_lists && this.props.file_lists.map(item => [
                            <tr key={item.id}>
                                <td>
                                    <a onClick={this.handlePreviewFile.bind(this, item)}>
                                        {<img alt="" src={this.extTypes[item.ext_name]} />}
                                        {item.name}
                                    </a>
                                </td>
                                <td>{item.uploader_name}</td>
                                {this.props.id !== "0" &&
                                    <td>{formatDate('yyyy-MM-dd hh:mm:ss', item.created_at)}</td>}
                                {/* <td><a onClick={this.handleDel.bind(this, item)}>删除</a></td> */}
                            </tr>
                        ])}
                    </tbody>
                </table>
                {/* <div className="page">
                    <Pagination size="small" showSizeChanger={false} defaultPageSize={10}
                        defaultCurrent={this.state.page} onChange={this.onPage}
                        total={this.state.total} />
                </div> */}
            </div> :
            <div id="no-file">
                
            </div>
        
    }
    render() {
        // 只显示文件列表
        if (this.props.showTableOnly) {
            return this.renderTable()
        }
        return (
            <div id="file-box" onClick={this.handleFileClick}>
                <div id="file-header">
                    <div id="file-header-logo"><img alt="" src={logo_icon} />
                    </div>
                    {this.props.id !== "0" ?
                        <div id="file-meeting">
                            <p id="meeting-code">云评审会议号：{this.props.id}</p>
                            <p id="meeting-theme">会议主题：{this.state.name}</p>
                        </div> :
                        <div id="file-meeting">
                            <p style={{ fontSize: 20, marginBottom: 0 }}>预定会议</p>
                        </div>
                    }
                    <button onClick={this.backFile}>完成</button>
                </div>
                <div id="file-content">
                    <div id="file-content-title">
                        <span></span>
                        <p>文档</p>
                    </div>
                    <div id="file-content-upload">
                        <div>
                            {/* <span>预定会议</span> */}
                            { !this.props.personalStatus &&
                                <a onClick={this.handleManage}>
                                    <span>{this.props.allow_member_upload === 1 ? "全员可上传" : "仅主持人可上传"}</span>
                                    <img id="drop-down" src={down_icon} alt="" />
                                </a>
                            }  
                            {this.state.showManageUpload &&
                                <div className="manage-upload">
                                    <ul>
                                        <li onClick={this.handleChange}>
                                            <a>仅主持人可上传</a>
                                        </li>
                                        <li onClick={this.handleChange}>
                                            <a>全员可上传</a>
                                        </li>
                                    </ul>
                                    <div id="triangle"><span></span></div>
                                </div>}
                        </div>
                        <div className="file-opt">
                            { (this.state.items && this.state.items.length > 0) ? <div className="file-export">
                                <button onClick={this.exportFiles}>全部下载</button>
                            </div> : ''}
                            { !this.props.personalStatus ? <div className='file-iptc'>
                                <button>上传文档</button>
                                <input onChange={this.handleFile} className='file-ipt' type='file' />
                            </div> : ''}
                        </div>
                        
                        
                    </div>
                    { this.state.items.length > 0 ?
            <div className="file-table">
                <table>
                    <thead>
                        <tr>
                            <td>名称</td>
                            <td>创建者</td>
                            {this.props.id !== "0" && <td>修改时间</td>}
                            <td>操作</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.items && this.state.items.map(item => [
                            <tr key={item.id}>
                                <td>
                                    <a onClick={this.handlePreviewFile.bind(this, item)}>
                                        {<img alt="" src={this.extTypes[item.ext_name]} />}
                                        {item.name}
                                    </a>
                                </td>
                                <td>{item.uploader_name}</td>
                                {this.props.id !== "0" &&
                                    <td>{formatDate('yyyy-MM-dd hh:mm:ss', item.created_at)}</td>}
                                <td>
                                    <a className="download" onClick={this.handleDownload.bind(this, item)}>下载</a>
                                    { !this.props.personalStatus ? <a className="delete" onClick={this.handleDel.bind(this, item)}>删除</a>
                                    : ''}
                                </td>
                            </tr>
                        ])}
                    </tbody>
                </table>
                <div className="page">
                    <Pagination size="small" showSizeChanger={false} defaultPageSize={10}
                        defaultCurrent={this.state.page} onChange={this.onPage}
                        total={this.state.total} />
                </div>
            </div> :
            <div id="no-file">
                <img alt="" src={file_icon} />
                <div>
                    <p>支持word、excel、ppt文档</p>
                    <p>单个word、excel文件最大支持20M，pdf最大支持50M</p>
                </div>
            </div> }
                </div>
                <Modal
                    title="确定删除该文件？"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="确定"
                    cancelText="取消"
                >
                    <p>确定要删除 <span style={{ color: "red" }}>{this.state.item && this.state.item.name}</span> 吗?</p>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(withRouter(AddFile))